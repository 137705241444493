import { PlusSquareFilled } from "@ant-design/icons";
import { Layout, MetaTag } from "src/components";
import { Button, Card, Checkbox, Col, Input, Modal, Row, Tag } from "antd";
import { Space } from "antd";
import { useRouter } from "src/helpers";
import { useState } from "react";

const { Search } = Input;

import { BLOCK_TYPES, getBlockTypes, renderBlock } from "./blocks";
import { convertS3UrlToGcp } from "../../edit_enhanced/helpers";

const Hit = ({ hit, onClick, blockIndex }: any) => {
    const { id, type, data, tags } = hit;
    const block = {
        id,
        type,
        data,
        children: [],
        tags: [],
    };
    return (
        <article
            style={{ cursor: "pointer", height: "100%" }}
            onClick={() => onClick(hit)}
        >
            <Card
                style={{
                    borderRadius: "8px",
                    marginBottom: "24px",
                    marginRight: "24px",
                    height: "100%",
                }}
            >
                <Row justify={"space-between"}>
                    <Col>
                        {getBlockTypes()
                            .filter((block) => block.value === type)
                            .map(
                                (
                                    { label, value, icon, desc }: any,
                                    idx: number,
                                ) => (
                                    <Row>
                                        <div
                                            style={{
                                                minHeight: "56px",
                                                display: "flex",
                                                alignItems: "start",
                                                paddingBottom: "20px",
                                            }}
                                        >
                                            <img
                                                src={convertS3UrlToGcp(icon)}
                                                alt="Proflie"
                                                width="16px"
                                                height="16px"
                                            />
                                            <div
                                                style={{
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: "18px",
                                                        lineHeight: "18px",
                                                        color: "#333333",
                                                        marginBottom: "8px",
                                                    }}
                                                >
                                                    {`${
                                                        blockIndex + 1
                                                    }. ${label}`}
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                ),
                            )}
                    </Col>
                </Row>
                <Row>
                    {tags?.map((value: any, index: number) => {
                        return (
                            <Tag
                                key={index}
                                style={{
                                    fontSize: "12px",
                                    color: "#333333",
                                    fontFamily: "Manrope",
                                }}
                            >
                                <img
                                    src={"/icons/questions/question_tag.svg"}
                                    alt="Early Access"
                                    width="15px"
                                    height="10px"
                                />
                                <span
                                    style={{
                                        marginLeft: "4px",
                                    }}
                                >
                                    {value}
                                </span>
                            </Tag>
                        );
                    })}
                </Row>
                {renderBlock({
                    ...block,
                    isBank: true,
                    heading: false,
                    questionCount: null,
                })}
            </Card>
        </article>
    );
};

export const Component = (props) => {
    const {
        blocks,
        loadMore,
        redirectToWorksheet,
        onSearch,
        error,
        showLoadMore,
        isWorksheetActive,
        handleWorksheetCheckbox,
    } = props;

    return (
        <Layout
            titles={[
                { name: "Home", link: "/" },
                { name: "Worksheet Question Search", link: "" },
            ]}
        >
            <MetaTag title="Worksheet Question Search" />
            <Space direction="vertical" style={{ width: "100%" }}>
                <div>
                    <Search
                        placeholder="Search Question Here"
                        onSearch={onSearch}
                        enterButton
                        size="large"
                        allowClear
                        status={error ? "error" : ""}
                    />
                    {error && <p>Pls enter atleast 10 characters</p>}

                    <Checkbox
                        checked={isWorksheetActive}
                        onChange={handleWorksheetCheckbox}
                        style={{ marginTop: "10px" }}
                    >
                        {`Worksheet Status`}
                    </Checkbox>
                </div>

                <Row gutter={[0, 20]} style={{ marginTop: "20px" }}>
                    {blocks.map((block, blockIndex) => (
                        <Col span={6}>
                            <Hit
                                hit={block}
                                onClick={redirectToWorksheet}
                                blockIndex={blockIndex}
                            />
                        </Col>
                    ))}
                </Row>
                {showLoadMore ? (
                    <Button
                        block
                        type={"dashed"}
                        size={"large"}
                        onClick={loadMore}
                        style={{ marginTop: "20px" }}
                    >
                        Load More
                    </Button>
                ) : null}
            </Space>
        </Layout>
    );
};
