import ReactPlayer from "react-player";
import { BLOCK_TYPES } from "../../common/index";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";
const { V2_AVATAR_BLOCK } = BLOCK_TYPES;

const renderBlock = (block: any) => {
    const {
        data: {
            [V2_AVATAR_BLOCK]: { value, type },
        },
    } = block;
    return (
        <>
            {type === "image" && (
                <div style={{ width: "40%" }}>
                    <img
                        src={convertS3UrlToGcp(value)}
                        style={{ width: "-webkit-fill-available" }}
                    />
                </div>
            )}
            {type === "video" && (
                <div>
                    <ReactPlayer
                        url={convertS3UrlToGcp(value)}
                        style={{ maxWidth: "100%" }}
                    />
                </div>
            )}
        </>
    );
};

export default renderBlock;
