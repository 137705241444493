import {
    CheckOutlined,
    CloseOutlined,
    EllipsisOutlined,
    MenuOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Typography,
    Menu,
    Dropdown,
    Divider,
    Select,
    Form,
    message,
    Input,
    Collapse,
    Switch,
    Checkbox,
    Col,
    Row,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import _ from "lodash";
import { SortableHandle } from "react-sortable-hoc";
import { v4 as uuid } from "uuid";
import {
    COMPONENT_SCHEMA,
    COMPONENT_TYPES,
    COMPONENTS,
    ROUND_SCHEMA,
} from "src/modules/worksheet/components/blocks/renderers/AllInOne/defaultValue";
import { Can } from "src/services/casl";
import { BLOCK_TYPES } from "../../../common";
import { SlateEditor } from "src/components";
import { getActionMenuItems } from "src/modules/worksheet/components/WorksheetEditor/helpers";
import { useMemo, useState } from "react";
import { AudioPoolModal } from "../../Figma/renderEditor/AudioPoolModal";
import { LogicModal } from "./ComponentsEditor/common/LogicModal";
// import { COMPUTE_FUNCTION_MULTIINPUT } from "./ComponentsEditor/MultiInput/mapping";
import { getFigmaData } from "./helpers";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import QuestionVariablesModal from "./ComponentsEditor/common/QuestionVariablesModal";
import CollapseBtn from "src/modules/worksheet/components/WorksheetEditor/helpers/CollapseBtn";
import { checkComponentValid, checkQuestionValid } from "../isValid";
import { useWorksheetState } from "src/modules/worksheet/components/contexts/WorksheetContext";
import { jumpLogicValid } from "../../ProgressLevel/isValid";
import StickerPool from "../../Figma/renderEditor/StickerPool";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";
import { deepOmit } from "../../ProgressLevel/renderEditor/helper";
import { FeedbackPool } from "./ComponentsEditor/common/FeedbackPool";

const { ALL_IN_ONE } = BLOCK_TYPES;

export const DragHandle = SortableHandle(() => <MenuOutlined />);

const { Title } = Typography;
const { Panel } = Collapse;

export const SortableItemCustom = SortableElement((props: any) => {
    const {
        block,
        idx,
        setBlock,
        onClick,
        disableWrite,
        addBlock,
        deleteBlock,
        type,
        currentComponentIndex,
        setLoad,
        componentOwners,
        currentRoundIndex,
        area,
        currentArea,
    } = props;

    const getBlockActionsMenu = () => {
        return (
            <Menu
                items={[
                    ...getActionMenuItems({
                        idx,
                        type,
                        disableDuplicateAction: disableWrite,
                        deleteBlock,
                        addBlockCustom: addBlock,
                        block,
                        // for progression stage
                        updateId: true,
                        ignoreId: false,
                    }),
                    {
                        key: 3,
                        label: <span>Copy JSON</span>,
                        onClick: async () => {
                            navigator.clipboard.writeText(
                                JSON.stringify(block),
                            );
                            message.info(`Copied Question to clipboard!`);
                        },
                    },
                    ...(block?.figma
                        ? [
                              {
                                  key: 4,
                                  label: <span>Refetch</span>,
                                  onClick: async () => {
                                      let retVal = await getFigmaData(
                                          block?.figma?.figma_data?.url,
                                          {
                                              variables:
                                                  block?.figma?.variables,
                                              compute_functions:
                                                  block?.figma
                                                      ?.compute_functions,
                                          },
                                      );
                                      if (!retVal) return;
                                      setLoad(true);
                                      const {
                                          name,
                                          compute_functions,
                                          variables,
                                      } = retVal;
                                      let tmpBlock = cloneDeep(block);
                                      tmpBlock = set(
                                          tmpBlock,
                                          ["figma", "name"],
                                          name,
                                      );
                                      tmpBlock = set(
                                          tmpBlock,
                                          ["figma", "compute_functions"],
                                          compute_functions,
                                      );
                                      tmpBlock = set(
                                          tmpBlock,
                                          ["figma", "variables"],
                                          variables,
                                      );
                                      setBlock(tmpBlock, idx);
                                  },
                              },
                          ]
                        : []),
                ]}
            />
        );
    };

    if (block.is_multiplayer) {
        return (
            <Card
                style={{
                    width: "100%",
                    alignItems: "center",
                }}
                onClick={onClick}
                hoverable
                bordered
                bodyStyle={{
                    background:
                        /* !isValid
                        ? "#FFD6D7"
                        : */ currentRoundIndex === idx ? "#E6F7FF" : "#ffffff",
                    borderRight:
                        currentRoundIndex === idx
                            ? /* !isValid
                                ? "4px solid red"
                                : */ "4px solid #1890FF"
                            : 0,
                }}
            >
                <div style={{ display: "flex", gap: "20px" }}>
                    <div style={{ flexShrink: 0 }}>
                        <DragHandle />
                    </div>
                    {/* <Title
                        level={5}
                        editable={
                            disableWrite
                                ? false
                                : {
                                      text: block.name,
                                      onChange: (val) => {
                                          let tmpBlock = cloneDeep(block);
                                          tmpBlock = set(
                                              tmpBlock,
                                              ["name"], // Update the correct path for the component's title
                                              val,
                                          );
                                          setBlock(tmpBlock); // Update the block state
                                      },
                                      triggerType: ["icon", "text"], // Allow editing by clicking text or icon
                                      enterIcon: null, // Remove the default enter icon
                                  }
                        }
                    >
                        {block.name}
                    </Title> */}
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        gap: "4px",
                    }}
                >
                    <span>
                        {block.label} {block?.show_in_preview && "(preview)"}
                    </span>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "4px",
                        }}
                    >
                        <Dropdown
                            overlay={() => getBlockActionsMenu()}
                            trigger={["click"]}
                            overlayStyle={{ width: "100px" }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Button
                                    style={{
                                        flexShrink: 0,
                                    }}
                                    type="default"
                                    icon={
                                        <EllipsisOutlined
                                            style={{ fontSize: "20px" }}
                                        />
                                    }
                                    disabled={disableWrite}
                                />
                            </a>
                        </Dropdown>
                    </div>
                </div>
                {currentComponentIndex === idx && (
                    <div style={{ marginTop: "10px" }}>
                        <h4>Owners:</h4>
                        {componentOwners?.map((owner: any, index: number) => (
                            <div key={index} style={{ marginBottom: "4px" }}>
                                <strong>{owner.name}</strong>
                                {owner.contributions?.length > 0 && (
                                    <span
                                        style={{
                                            marginLeft: "8px",
                                            color: "#666",
                                        }}
                                    >
                                        ({owner.contributions.join(", ")})
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </Card>
        );
    }

    const { isValid } = checkComponentValid(block);

    return (
        <Card
            style={{
                width: "100%",
                alignItems: "center",
            }}
            onClick={onClick}
            hoverable
            bordered
            bodyStyle={{
                background: !isValid
                    ? "#FFD6D7"
                    : currentComponentIndex === idx &&
                      (!currentArea || area === currentArea)
                    ? "#E6F7FF"
                    : "#ffffff",
                borderRight:
                    currentComponentIndex === idx &&
                    (!currentArea || area === currentArea)
                        ? !isValid
                            ? "4px solid red"
                            : "4px solid #1890FF"
                        : 0,
            }}
        >
            <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ flexShrink: 0 }}>
                    <DragHandle />
                </div>
                <Title
                    level={5}
                    editable={
                        disableWrite
                            ? false
                            : {
                                  text: block.name,
                                  onChange: (val) => {
                                      let tmpBlock = cloneDeep(block);
                                      tmpBlock = set(
                                          tmpBlock,
                                          ["name"], // Update the correct path for the component's title
                                          val,
                                      );
                                      setBlock(tmpBlock); // Update the block state
                                  },
                                  triggerType: ["icon", "text"], // Allow editing by clicking text or icon
                                  enterIcon: null, // Remove the default enter icon
                              }
                    }
                >
                    {block.name}
                </Title>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "4px",
                }}
            >
                <span>
                    {block.label} {block?.show_in_preview && "(preview)"}
                </span>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "4px",
                    }}
                >
                    <Dropdown
                        overlay={() => getBlockActionsMenu()}
                        trigger={["click"]}
                        overlayStyle={{ width: "100px" }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Button
                                style={{
                                    flexShrink: 0,
                                }}
                                type="default"
                                icon={
                                    <EllipsisOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                                disabled={disableWrite}
                            />
                        </a>
                    </Dropdown>
                </div>
            </div>
            {currentComponentIndex === idx && (
                <div style={{ marginTop: "10px" }}>
                    <h4>Owners:</h4>
                    {componentOwners?.map((owner: any, index: number) => (
                        <div key={index} style={{ marginBottom: "4px" }}>
                            <strong>{owner.name}</strong>
                            {owner.contributions?.length > 0 && (
                                <span
                                    style={{ marginLeft: "8px", color: "#666" }}
                                >
                                    ({owner.contributions.join(", ")})
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </Card>
    );
});

export const SortableContainerCustom = SortableContainer(
    ({ children }: any) => {
        return <div style={{ overflow: "auto" }}>{children}</div>;
    },
);

export const BlockColumn = (props: any) => {
    const {
        block,
        setBlock,
        setIsEditor,
        mentionsList = [],
        hasMentions,
        currentBlock,
        disableWrite,
        setCurrentComponentIndex,
        currentComponentIndex,
        renderContextVariables,
        worksheet,
        previewData,
        chunkSlug,
        currentRoundIndex,
        setCurrentRoundIndex,
        currentEditor,
        setCurrentEditor,
    } = props;
    const {
        id,
        tmpId,
        data: {
            slug: dataSlug,
            [ALL_IN_ONE]: {
                name = "",
                sub_name = "",
                logic = {},
                components = [],
                question_audio = [],
                question_stickers = [],
                question_variables: qVars = [],
                question_derived_variables: qDerVaes = [],
                feedback_pool = [],
                rounds = [],
            } = {},
            other: { description = [], stories = [] } = {},
        },
        backend = {},
        is_multiplayer = false,
    } = block;

    let slug =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.slug || []
            : dataSlug;
    let question_variables =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.question_variables || []
            : qVars;
    let question_derived_variables =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.question_derived_variables || []
            : qDerVaes;

    const {
        blockIndex,
        chunkIndex,
        publishedBlocksMap = {},
    }: any = useWorksheetState();

    const [openAudioModal, setOpenAudioModal] = useState("");
    const [tempChildren, setTempChildren] = useState("");

    const onSortEndComponent = ({ oldIndex, newIndex, idx }: any) => {
        let tmpBlock = cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            components,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = set(
            tmpBlock,
            ["data", ALL_IN_ONE, "components"],
            tmpChildren,
        );
        setBlock(tmpBlock);
        setCurrentComponentIndex(newIndex);
    };

    const onSortEndRound = ({ oldIndex, newIndex, idx }: any) => {
        let tmpBlock = cloneDeep(block);
        let tmpChildren = arrayMoveImmutable(rounds, oldIndex, newIndex);
        tmpChildren = tmpChildren.map((round: any, idx) => {
            return {
                ...round,
                value: `round_${idx}`,
                label: `Round ${idx + 1}`,
            };
        });
        tmpBlock = set(tmpBlock, ["data", ALL_IN_ONE, "rounds"], tmpChildren);
        setBlock(tmpBlock);
        setCurrentRoundIndex(newIndex);
    };

    const addComponent = async (
        component: {
            figma_url: string;
            value: any;
            label: any;
            component_type: any;
            data: any;
        },
        index: number,
    ) => {
        let retVal;
        let newComp: any = {
            ...COMPONENT_SCHEMA,
            tmpId: uuid(),
            id: uuid(),
            type: component.value,
            label: component.label,
            component_type: component.component_type,
            show_in_preview: false,
        };
        if (component?.figma_url) {
            retVal = await getFigmaData(component.figma_url, {});
            if (!retVal) return;
            const { name, compute_functions, variables, figma_data } = retVal;
            newComp.figma = {
                ...component.data,
                name,
                variables,
                compute_functions,
                figma_data,
            };
        } else {
            newComp.data = component.data;
        }
        switch (component.value) {
            case COMPONENT_TYPES.RICH_TEXT:
            case COMPONENT_TYPES.TIMER:
                newComp.skip_evaluation = true;
                newComp.disable_interaction = true;
                break;
            case COMPONENT_TYPES.CONDITION:
                newComp.disable_interaction = true;
                newComp.show_in_preview = true;
                break;
            default:
                break;
        }
        let tmpBlock = cloneDeep(block);
        tmpBlock = set(
            tmpBlock,
            ["data", ALL_IN_ONE, "components", index],
            newComp,
        );
        setBlock(tmpBlock);
        setCurrentComponentIndex(index);
    };

    const addRound = () => {
        const newRoundIdx = rounds.length;
        let tmpBlock = cloneDeep(block);
        const newRound = {
            ...ROUND_SCHEMA,
            id: uuid(),
            tmpId: uuid(),
            label: `Round ${newRoundIdx + 1}`,
            value: `round_${newRoundIdx}`,
        };

        tmpBlock = set(
            tmpBlock,
            ["data", ALL_IN_ONE, "rounds", newRoundIdx],
            newRound,
        );
        setBlock(tmpBlock);
        setCurrentRoundIndex(newRoundIdx);
    };

    const componentsMenu = (
        <Menu
            items={COMPONENTS.map((c: any, idx: number) => ({
                key: idx,
                label: <span>{c.label}</span>,
                onClick: () => addComponent(c, components.length),
            }))}
        />
    );
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const isBlockValid = useMemo(
        () => checkQuestionValid(block, worksheet),
        [description, stories],
    );

    const [showQuestionList, setShowQuestionList] = useState(false);
    const [isFeedbackPoolOpen, setIsFeedbackPoolOpen] = useState(false);

    if (is_multiplayer) {
        return (
            <Card
                style={{
                    borderRadius: "8px",
                    height: "86vh",
                    border: "1px solid  rgba(0, 0, 0, 0.06)",
                }}
                bordered={true}
                onClick={() => {
                    setIsEditor(false);
                }}
                bodyStyle={{
                    padding: "0px",
                    paddingTop: collapsed ? "10px" : "15px",
                }}
            >
                {openAudioModal && openAudioModal !== "" && (
                    <AudioPoolModal
                        isModalOpen={true}
                        setIsModalOpen={(value: boolean) => {
                            value === true
                                ? setOpenAudioModal(openAudioModal)
                                : setOpenAudioModal("");
                        }}
                        name={openAudioModal}
                        audioPool={question_audio}
                        disableWrite={disableWrite}
                        schema={{
                            x: "",
                            audio: "",
                        }}
                        onSave={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["data", ALL_IN_ONE, "question_audio"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                )}
                <div
                    style={{
                        maxHeight: "83vh",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                    }}
                >
                    <div style={{ display: "flex", gap: "20px" }}>
                        <CollapseBtn
                            collapsed={collapsed}
                            toggleCollapsed={toggleCollapsed}
                            style={{
                                marginBottom: 10,
                                marginLeft: collapsed ? 10 : 20,
                                marginRight: collapsed ? 10 : 0,
                            }}
                        />
                        {block?.id ? (
                            !collapsed && (
                                <div
                                    style={{
                                        display: collapsed ? "hidden" : "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Form.Item label="Show only audio list?">
                                        <Checkbox
                                            value={showQuestionList}
                                            onChange={(e) => {
                                                setShowQuestionList(
                                                    e.target.checked,
                                                );
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={"Open Preview"}
                                        style={{
                                            border: "1px solid black",
                                            padding: "0 5px",
                                            display: collapsed
                                                ? "none"
                                                : "inline",
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                const queryParams =
                                                    new URLSearchParams();
                                                const jsonData = {
                                                    context: "preview",
                                                    ...previewData,
                                                    progression_activity_id:
                                                        uuid(),
                                                    path_id: uuid(),
                                                    variant_id: block?.id,
                                                    block_id: block?.id,
                                                };

                                                // Add parameters from JSON object to the query string
                                                for (const key in jsonData) {
                                                    if (
                                                        jsonData.hasOwnProperty(
                                                            key,
                                                        )
                                                    ) {
                                                        queryParams.append(
                                                            key,
                                                            jsonData[key],
                                                        );
                                                    }
                                                }

                                                // Construct the URL with the parameters
                                                const url = `${
                                                    process.env
                                                        .REACT_APP_CLIENT_PREVIEW
                                                }/home/worksheet/${
                                                    worksheet?.id
                                                }/${
                                                    previewData[
                                                        "progression_id"
                                                    ]
                                                }?${queryParams.toString()}&logs=true&showQuestion=true&noCache=true${
                                                    showQuestionList
                                                        ? "&questionText=true"
                                                        : ""
                                                }`;

                                                // Open the URL in a new tab
                                                window.open(url, "_blank");
                                            }}
                                            disabled={
                                                !previewData["progression_id"]
                                            }
                                            style={{
                                                marginRight: "10px",
                                            }}
                                        >
                                            QA
                                        </Button>
                                        {/* <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    const queryParams = new URLSearchParams();
                                    const jsonData = {
                                        context: "preview",
                                        ...previewData,
                                        progression_activity_id: uuid(),
                                        path_id: uuid(),
                                        variant_id: block?.id,
                                        block_id: block?.id,
                                    };

                                    // Add parameters from JSON object to the query string
                                    for (const key in jsonData) {
                                        if (jsonData.hasOwnProperty(key)) {
                                            queryParams.append(
                                                key,
                                                jsonData[key],
                                            );
                                        }
                                    }

                                    // Construct the URL with the parameters
                                    const url = `https://mathai.ai/home/worksheet/${worksheet?.id
                                        }/${previewData["progression_id"]
                                        }?${queryParams.toString()}&logs=true&showQuestion=true`;

                                    // Open the URL in a new tab
                                    window.open(url, "_blank");
                                }}
                                disabled={!previewData["progression_id"]}
                            >
                                MathAi
                            </Button> */}
                                    </Form.Item>
                                </div>
                            )
                        ) : (
                            <Button
                                size="small"
                                style={{
                                    marginLeft: 16,
                                    display: collapsed ? "none" : "inline",
                                }}
                                danger
                                type="primary"
                                onClick={(val) => {
                                    if (
                                        worksheet?.type ===
                                        "personalized_learning_v4"
                                    )
                                        return;
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock.id = uuid();
                                    tmpBlock.tmpId = uuid();
                                    setBlock(tmpBlock);
                                }}
                            >
                                {worksheet?.type === "personalized_learning_v4"
                                    ? "Save Worksheet to preview"
                                    : "Set Block ID"}
                            </Button>
                        )}
                    </div>
                    <div
                        style={{
                            // maxHeight: "70vh",
                            // scrollbarWidth: "thin",
                            // overflow: "auto",
                            display: collapsed ? "none" : "block",
                            padding: 20,
                        }}
                    >
                        <Divider
                            style={{
                                margin: "12px 0px",
                                marginTop: "0px",
                            }}
                        />
                        <Title
                            level={3}
                            editable={
                                disableWrite
                                    ? false
                                    : {
                                          text: name,
                                          onChange: (val) => {
                                              let tmpBlock = cloneDeep(block);
                                              tmpBlock = set(
                                                  tmpBlock,
                                                  ["data", ALL_IN_ONE, "name"],
                                                  val,
                                              );
                                              setBlock(tmpBlock);
                                          },
                                          triggerType: ["icon", "text"],
                                          enterIcon: null,
                                      }
                            }
                        >
                            B{currentBlock} {name}
                        </Title>
                        <Title
                            level={4}
                            editable={
                                disableWrite
                                    ? false
                                    : {
                                          text: sub_name,
                                          onChange: (val) => {
                                              let tmpBlock = cloneDeep(block);
                                              tmpBlock = set(
                                                  tmpBlock,
                                                  [
                                                      "data",
                                                      ALL_IN_ONE,
                                                      "sub_name",
                                                  ],
                                                  val,
                                              );
                                              setBlock(tmpBlock);
                                          },
                                          triggerType: ["icon", "text"],
                                          enterIcon: null,
                                      }
                            }
                        >
                            Sub Name: {sub_name}
                        </Title>

                        <div
                            onClick={() => {
                                setCurrentComponentIndex(null);
                                setCurrentRoundIndex(null);
                            }}
                            style={{
                                padding:
                                    currentComponentIndex === null &&
                                    currentRoundIndex === null
                                        ? "10px 10px"
                                        : 0,
                                background: !isBlockValid
                                    ? "#FFD6D7"
                                    : currentComponentIndex === null &&
                                      currentRoundIndex === null
                                    ? "#E6F7FF"
                                    : "#ffffff",
                                borderRight:
                                    currentComponentIndex === null &&
                                    currentRoundIndex === null
                                        ? !isBlockValid
                                            ? "4px solid red"
                                            : "4px solid #1890FF"
                                        : 0,
                                marginBottom: "20px",
                            }}
                        >
                            <h3
                                style={{
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                }}
                            >
                                Main Question
                            </h3>
                        </div>
                        <KTWrapper feature="audio_pool">
                            <Form.Item label="Audio Pool">
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        setOpenAudioModal("question_audio")
                                    }
                                    size="small"
                                >
                                    Question Audio
                                </Button>
                            </Form.Item>
                        </KTWrapper>
                        <KTWrapper feature="sticker_pool">
                            <StickerPool
                                sticker_pool={question_stickers}
                                disableWrite={disableWrite}
                                schema={{
                                    x: "",
                                    json: "",
                                    alignment: "",
                                }}
                                onSave={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "data",
                                            ALL_IN_ONE,
                                            "question_stickers",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </KTWrapper>
                        <Form.Item label="Feedback Pool">
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => setIsFeedbackPoolOpen(true)}
                            >
                                {" "}
                                Feedback pool{" "}
                            </Button>
                            <FeedbackPool
                                isModalOpen={isFeedbackPoolOpen}
                                setIsModalOpen={setIsFeedbackPoolOpen}
                                feedbackPool={feedback_pool}
                                disableWrite={disableWrite}
                                name="Feedback Pool"
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                currentEditor={currentEditor}
                                setCurrentEditor={setCurrentEditor}
                                onSave={(value: any) => {
                                    console.log("value", value);
                                    const tmpBlock = _.cloneDeep(block);
                                    _.set(
                                        tmpBlock,
                                        ["data", ALL_IN_ONE, "feedback_pool"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                        <Can I="edit" a={"worksheet_block"} passThrough>
                            {(allowed: boolean) => (
                                <>
                                    <h3
                                        style={{
                                            fontWeight: "bold",
                                            margin: "10px 0",
                                        }}
                                    >
                                        Rounds
                                    </h3>
                                    <SortableContainerCustom
                                        onSortEnd={onSortEndRound}
                                        useDragHandle
                                    >
                                        {rounds.map(
                                            (round: any, idx: number) => {
                                                return (
                                                    <SortableItemCustom
                                                        {...props}
                                                        idx={idx}
                                                        componentOwners={[]}
                                                        disabled={disableWrite}
                                                        type={"round"}
                                                        key={`round_${idx}`}
                                                        index={idx}
                                                        block={{
                                                            ...round,
                                                            is_multiplayer:
                                                                true,
                                                        }}
                                                        onClick={() => {
                                                            setCurrentRoundIndex(
                                                                idx,
                                                            );
                                                        }}
                                                        setBlock={(v: any) => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            tmpBlock = set(
                                                                tmpBlock,
                                                                [
                                                                    "data",
                                                                    ALL_IN_ONE,
                                                                    "rounds",
                                                                    idx,
                                                                ],
                                                                v,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }}
                                                        addBlock={(
                                                            type: string,
                                                            index: number,
                                                            newBlock: any,
                                                        ) => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    block,
                                                                );
                                                            delete newBlock.round_id;
                                                            let tmpChildren = [
                                                                ...rounds,
                                                            ];

                                                            tmpChildren.splice(
                                                                index,
                                                                0,
                                                                newBlock,
                                                            );
                                                            tmpChildren =
                                                                tmpChildren.map(
                                                                    (
                                                                        block,
                                                                        idx,
                                                                    ) => {
                                                                        return {
                                                                            ...block,
                                                                            label: `Round ${
                                                                                idx +
                                                                                1
                                                                            }`,
                                                                            value: `round_${idx}`,
                                                                        };
                                                                    },
                                                                );
                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [
                                                                    "data",
                                                                    ALL_IN_ONE,
                                                                    "rounds",
                                                                ],
                                                                tmpChildren,
                                                            );
                                                            setBlock(tmpBlock);
                                                            setCurrentRoundIndex(
                                                                index,
                                                            );
                                                        }}
                                                        deleteBlock={(
                                                            index: number,
                                                        ) => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    block,
                                                                );
                                                            let tmpChildren = [
                                                                ...rounds,
                                                            ];
                                                            _.pullAt(
                                                                tmpChildren,
                                                                index,
                                                            );

                                                            tmpChildren =
                                                                tmpChildren.map(
                                                                    (
                                                                        block,
                                                                        idx,
                                                                    ) => {
                                                                        return {
                                                                            ...block,
                                                                            label: `Round ${
                                                                                idx +
                                                                                1
                                                                            }`,
                                                                            value: `round_${idx}`,
                                                                        };
                                                                    },
                                                                );

                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [
                                                                    "data",
                                                                    ALL_IN_ONE,
                                                                    "rounds",
                                                                ],
                                                                tmpChildren,
                                                            );
                                                            setBlock(tmpBlock);
                                                            setCurrentRoundIndex(
                                                                index - 1,
                                                            );
                                                        }}
                                                    />
                                                );
                                            },
                                        )}
                                    </SortableContainerCustom>
                                    {!rounds?.length && (
                                        <div
                                            style={{
                                                fontSize: "16px",
                                                color: "red",
                                            }}
                                        >
                                            No rounds added
                                        </div>
                                    )}
                                    {!disableWrite && (
                                        <div
                                            style={{
                                                minWidth: "208px",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "10px",
                                            }}
                                        >
                                            <div>
                                                <Button
                                                    // type="link"
                                                    disabled={!allowed}
                                                    onClick={() => addRound()}
                                                >
                                                    Add new round
                                                    <PlusOutlined
                                                        style={{
                                                            fontSize: "15px",
                                                        }}
                                                    />
                                                </Button>
                                            </div>
                                            {/* <Dropdown
                                                overlay={componentsMenu}
                                                trigger={["click"]}
                                                // overlayStyle={{ width: "100px" }}
                                                disabled={!allowed}
                                            >
                                                

                                            </Dropdown> */}
                                            <Form.Item>
                                                <Input.TextArea
                                                    disabled={disableWrite}
                                                    value={tempChildren}
                                                    placeholder="Paste round here"
                                                    onChange={(e) =>
                                                        setTempChildren(
                                                            e.target.value,
                                                        )
                                                    }
                                                    rows={2}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                />
                                                <Button
                                                    disabled={
                                                        disableWrite ||
                                                        !tempChildren
                                                    }
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                    type="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        try {
                                                            const parsedJson =
                                                                JSON.parse(
                                                                    tempChildren,
                                                                );
                                                            delete parsedJson.round_id;
                                                            parsedJson.label = `Round ${
                                                                rounds?.length +
                                                                1
                                                            }`;
                                                            parsedJson.value = `round_${
                                                                rounds?.length +
                                                                1
                                                            }`;

                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    block,
                                                                );
                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [
                                                                    "data",
                                                                    ALL_IN_ONE,
                                                                    "rounds",
                                                                    rounds?.length,
                                                                ],
                                                                updateKeysAndCopy(
                                                                    parsedJson,
                                                                    false,
                                                                ),
                                                            );
                                                            setBlock(tmpBlock);
                                                            setCurrentRoundIndex(
                                                                rounds?.length,
                                                            );
                                                            setTempChildren("");
                                                            message.info(
                                                                "Updated!",
                                                            );
                                                        } catch (e) {
                                                            // captureException(e)
                                                            message.error(
                                                                "Error in JSON!",
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Add New round
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    )}
                                </>
                            )}
                        </Can>
                        <Collapse>
                            <Panel header="Variables" key="2">
                                {renderContextVariables &&
                                    renderContextVariables()}
                                <QuestionVariablesModal
                                    disableWrite={disableWrite}
                                    hasQuestionVars={true}
                                    question_derived_variables={
                                        question_derived_variables
                                    }
                                    question_variables={question_variables}
                                    setQuestionVariables={(val, val2) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            worksheet?.type ===
                                                "personalized_learning_v4"
                                                ? [
                                                      "backend",
                                                      "question_variables",
                                                  ]
                                                : [
                                                      "data",
                                                      ALL_IN_ONE,
                                                      "question_variables",
                                                  ],
                                            val,
                                        );
                                        tmpBlock = set(
                                            tmpBlock,
                                            worksheet?.type ===
                                                "personalized_learning_v4"
                                                ? [
                                                      "backend",
                                                      "question_derived_variables",
                                                  ]
                                                : [
                                                      "data",
                                                      ALL_IN_ONE,
                                                      "question_derived_variables",
                                                  ],
                                            val2,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Panel>
                            <Panel
                                header="Settings"
                                key="1"
                                style={
                                    !jumpLogicValid({
                                        functions:
                                            (worksheet?.type ===
                                            "personalized_learning_v4"
                                                ? backend?.compute_functions
                                                : logic.compute_functions) ||
                                            [],
                                        chunkIndex,
                                        blockIndex,
                                        chunkSlug,
                                        blockSlug: slug,
                                    })
                                        ? {
                                              background: "#FFD6D7",
                                          }
                                        : {}
                                }
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        gap: "4px",
                                    }}
                                >
                                    <LogicModal
                                        blockIndex={blockIndex}
                                        chunkIndex={chunkIndex}
                                        blockSlug={slug}
                                        chunkSlug={chunkSlug}
                                        isAllGlobal={true}
                                        disableWrite={disableWrite}
                                        computeFUnctions={
                                            (worksheet?.type ===
                                            "personalized_learning_v4"
                                                ? backend?.compute_functions
                                                : logic.compute_functions) || []
                                        }
                                        onSave={(val: any) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                worksheet?.type ===
                                                    "personalized_learning_v4"
                                                    ? [
                                                          "backend",
                                                          "compute_functions",
                                                      ]
                                                    : [
                                                          "data",
                                                          ALL_IN_ONE,
                                                          "logic",
                                                          "compute_functions",
                                                      ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                    <Select
                                        value={logic.check_type}
                                        placeholder="Select type"
                                        options={[
                                            {
                                                label: "One by one",
                                                value: "ONE_BY_ONE",
                                            },
                                            {
                                                label: "One by one till first incorrect",
                                                value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                            },
                                            {
                                                label: "All in one go",
                                                value: "ALL_IN_ONE_GO",
                                            },
                                        ]}
                                        style={{
                                            width: "150px",
                                            flexGrow: 0,
                                        }}
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    ALL_IN_ONE,
                                                    "logic",
                                                    "check_type",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        disabled={disableWrite}
                                    />
                                </div>
                                <Form.Item
                                    label="Feedback Type"
                                    style={{
                                        marginTop: "20px",
                                    }}
                                >
                                    <Select
                                        value={logic.feedback_type}
                                        placeholder="Select type"
                                        options={[
                                            {
                                                label: "No Feedback",
                                                value: "NO_FEEDBACK",
                                            },
                                            {
                                                label: "Manual Audio",
                                                value: "MANUAL_AUDIO",
                                            },
                                            {
                                                label: "Manual Story",
                                                value: "MANUAL_STORY",
                                            },
                                            {
                                                label: "Generated Audio",
                                                value: "GENERATED_AUDIO",
                                            },
                                            {
                                                label: "Generated Story",
                                                value: "GENERATED_STORY",
                                            },
                                        ]}
                                        style={{
                                            flexGrow: 0,
                                        }}
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    ALL_IN_ONE,
                                                    "logic",
                                                    "feedback_type",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        disabled={disableWrite}
                                    />
                                </Form.Item>
                                <Form.Item label={"show_answer"}>
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={logic.show_answer}
                                        onChange={(val: any) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    ALL_IN_ONE,
                                                    "logic",
                                                    "show_answer",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label={"disable_submit_button"}>
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={logic.disable_submit_button}
                                        onChange={(val: any) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    ALL_IN_ONE,
                                                    "logic",
                                                    "disable_submit_button",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Show answer when"
                                    style={{
                                        marginTop: "20px",
                                    }}
                                >
                                    <Select
                                        value={logic.show_answer_when}
                                        placeholder="Select type"
                                        options={[
                                            {
                                                label: "ALL_LIVES_LOST",
                                                value: "ALL_LIVES_LOST",
                                            },
                                            {
                                                label: "QUESTION_END",
                                                value: "QUESTION_END",
                                            },
                                        ]}
                                        style={{
                                            flexGrow: 0,
                                        }}
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    ALL_IN_ONE,
                                                    "logic",
                                                    "show_answer_when",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        disabled={disableWrite}
                                    />
                                </Form.Item>
                                <Form.Item label={"Answer Display text"}>
                                    <Input.TextArea
                                        disabled={disableWrite}
                                        style={{
                                            flexGrow: 0,
                                        }}
                                        value={
                                            logic.answer_display_text ??
                                            "Correct Answers!"
                                        }
                                        onChange={(e) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    ALL_IN_ONE,
                                                    "logic",
                                                    "answer_display_text",
                                                ],
                                                e.target.value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </Card>
        );
    }

    return (
        <Card
            style={{
                borderRadius: "8px",
                height: "86vh",
                border: "1px solid  rgba(0, 0, 0, 0.06)",
            }}
            bordered={true}
            onClick={() => {
                setIsEditor(false);
            }}
            bodyStyle={{
                padding: "0px",
                paddingTop: collapsed ? "10px" : "15px",
            }}
        >
            {openAudioModal && openAudioModal !== "" && (
                <AudioPoolModal
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        value === true
                            ? setOpenAudioModal(openAudioModal)
                            : setOpenAudioModal("");
                    }}
                    name={openAudioModal}
                    audioPool={question_audio}
                    disableWrite={disableWrite}
                    schema={{
                        x: "",
                        audio: "",
                    }}
                    onSave={(value: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", ALL_IN_ONE, "question_audio"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            )}
            <div>
                <div style={{ display: "flex", gap: "20px" }}>
                    <CollapseBtn
                        collapsed={collapsed}
                        toggleCollapsed={toggleCollapsed}
                        style={{
                            marginBottom: 10,
                            marginLeft: collapsed ? 10 : 20,
                            marginRight: collapsed ? 10 : 0,
                        }}
                    />
                    {block?.id ? (
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <Form.Item label="Show only audio list?">
                                <Checkbox
                                    value={showQuestionList}
                                    onChange={(e) => {
                                        setShowQuestionList(e.target.checked);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Open Preview"}
                                style={{
                                    border: "1px solid black",
                                    padding: "0 5px",
                                    display: collapsed ? "none" : "inline",
                                }}
                            >
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        const queryParams =
                                            new URLSearchParams();
                                        const jsonData = {
                                            context: "preview",
                                            ...previewData,
                                            progression_activity_id: uuid(),
                                            path_id: uuid(),
                                            variant_id: block?.id,
                                            block_id: block?.id,
                                        };

                                        // Add parameters from JSON object to the query string
                                        for (const key in jsonData) {
                                            if (jsonData.hasOwnProperty(key)) {
                                                queryParams.append(
                                                    key,
                                                    jsonData[key],
                                                );
                                            }
                                        }

                                        // Construct the URL with the parameters
                                        const url = `${
                                            process.env.REACT_APP_CLIENT_PREVIEW
                                        }/home/worksheet/${worksheet?.id}/${
                                            previewData["progression_id"]
                                        }?${queryParams.toString()}&logs=true&showQuestion=true&noCache=true${
                                            showQuestionList
                                                ? "&questionText=true"
                                                : ""
                                        }`;

                                        // Open the URL in a new tab
                                        window.open(url, "_blank");
                                    }}
                                    disabled={!previewData["progression_id"]}
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    QA
                                </Button>
                                {/* <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    const queryParams = new URLSearchParams();
                                    const jsonData = {
                                        context: "preview",
                                        ...previewData,
                                        progression_activity_id: uuid(),
                                        path_id: uuid(),
                                        variant_id: block?.id,
                                        block_id: block?.id,
                                    };

                                    // Add parameters from JSON object to the query string
                                    for (const key in jsonData) {
                                        if (jsonData.hasOwnProperty(key)) {
                                            queryParams.append(
                                                key,
                                                jsonData[key],
                                            );
                                        }
                                    }

                                    // Construct the URL with the parameters
                                    const url = `https://mathai.ai/home/worksheet/${worksheet?.id
                                        }/${previewData["progression_id"]
                                        }?${queryParams.toString()}&logs=true&showQuestion=true`;

                                    // Open the URL in a new tab
                                    window.open(url, "_blank");
                                }}
                                disabled={!previewData["progression_id"]}
                            >
                                MathAi
                            </Button> */}
                            </Form.Item>
                        </div>
                    ) : (
                        <Button
                            size="small"
                            style={{
                                marginLeft: 16,
                                display: collapsed ? "none" : "inline",
                            }}
                            danger
                            type="primary"
                            onClick={(val) => {
                                if (
                                    worksheet?.type ===
                                    "personalized_learning_v4"
                                )
                                    return;
                                let tmpBlock = cloneDeep(block);
                                tmpBlock.id = uuid();
                                tmpBlock.tmpId = uuid();
                                setBlock(tmpBlock);
                            }}
                        >
                            {worksheet?.type === "personalized_learning_v4"
                                ? "Save Worksheet to preview"
                                : "Set Block ID"}
                        </Button>
                    )}
                </div>
                <div
                    style={{
                        maxHeight: "70vh",
                        scrollbarWidth: "thin",
                        overflow: "auto",
                        display: collapsed ? "none" : "block",
                        padding: 20,
                    }}
                >
                    <Divider style={{ margin: "12px 0px", marginTop: "0px" }} />
                    <Title
                        level={3}
                        editable={
                            disableWrite
                                ? false
                                : {
                                      text: name,
                                      onChange: (val) => {
                                          let tmpBlock = cloneDeep(block);
                                          tmpBlock = set(
                                              tmpBlock,
                                              ["data", ALL_IN_ONE, "name"],
                                              val,
                                          );
                                          setBlock(tmpBlock);
                                      },
                                      triggerType: ["icon", "text"],
                                      enterIcon: null,
                                  }
                        }
                    >
                        B{currentBlock} {name}
                    </Title>
                    <Title
                        level={4}
                        editable={
                            disableWrite
                                ? false
                                : {
                                      text: sub_name,
                                      onChange: (val) => {
                                          let tmpBlock = cloneDeep(block);
                                          tmpBlock = set(
                                              tmpBlock,
                                              ["data", ALL_IN_ONE, "sub_name"],
                                              val,
                                          );
                                          setBlock(tmpBlock);
                                      },
                                      triggerType: ["icon", "text"],
                                      enterIcon: null,
                                  }
                        }
                    >
                        Sub Name: {sub_name}
                    </Title>

                    <div
                        onClick={() => setCurrentComponentIndex(null)}
                        style={{
                            padding:
                                !isBlockValid || currentComponentIndex === null
                                    ? "10px 10px"
                                    : 0,
                            background: !isBlockValid
                                ? "#FFD6D7"
                                : currentComponentIndex === null
                                ? "#E6F7FF"
                                : "#ffffff",
                            borderRight:
                                currentComponentIndex === null
                                    ? !isBlockValid
                                        ? "4px solid red"
                                        : "4px solid #1890FF"
                                    : 0,
                            marginBottom: "20px",
                        }}
                    >
                        <h3
                            style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                            }}
                        >
                            Main Question
                        </h3>
                        <SlateEditor
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`${id || tmpId}_text`}
                            value={description}
                            isFocused={false}
                            setEditor={() => setCurrentComponentIndex(null)}
                        />
                    </div>
                    <KTWrapper feature="audio_pool">
                        <Form.Item label="Audio Pool">
                            <Button
                                type="primary"
                                onClick={() =>
                                    setOpenAudioModal("question_audio")
                                }
                                size="small"
                            >
                                Question Audio
                            </Button>
                        </Form.Item>
                    </KTWrapper>
                    <KTWrapper feature="sticker_pool">
                        <StickerPool
                            sticker_pool={question_stickers}
                            disableWrite={disableWrite}
                            schema={{
                                x: "",
                                json: "",
                                alignment: "",
                            }}
                            onSave={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", ALL_IN_ONE, "question_stickers"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </KTWrapper>

                    <Can I="edit" a={"worksheet_block"} passThrough>
                        {(allowed: boolean) => (
                            <>
                                <h3
                                    style={{
                                        fontWeight: "bold",
                                        margin: "10px 0",
                                    }}
                                >
                                    Components
                                </h3>
                                <SortableContainerCustom
                                    onSortEnd={onSortEndComponent}
                                    useDragHandle
                                >
                                    {components.map(
                                        (component: any, idx: number) => {
                                            return (
                                                <SortableItemCustom
                                                    {...props}
                                                    idx={idx}
                                                    componentOwners={
                                                        COMPONENTS.find(
                                                            (c) =>
                                                                c.value ===
                                                                component.type,
                                                        )?.owners
                                                    }
                                                    disabled={disableWrite}
                                                    type={component.type}
                                                    key={`item-${
                                                        component.id ||
                                                        component.tmpId
                                                    }`}
                                                    index={idx}
                                                    block={component}
                                                    onClick={() => {
                                                        setCurrentComponentIndex(
                                                            idx,
                                                        );
                                                    }}
                                                    setBlock={(v: any) => {
                                                        let tmpBlock =
                                                            cloneDeep(block);
                                                        tmpBlock = set(
                                                            tmpBlock,
                                                            [
                                                                "data",
                                                                ALL_IN_ONE,
                                                                "components",
                                                                idx,
                                                            ],
                                                            v,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                    addBlock={(
                                                        type: string,
                                                        index: number,
                                                        newBlock: any,
                                                    ) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(block);
                                                        const tmpChildren = [
                                                            ...components,
                                                        ];
                                                        tmpChildren.splice(
                                                            index,
                                                            0,
                                                            newBlock,
                                                        );
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [
                                                                "data",
                                                                ALL_IN_ONE,
                                                                "components",
                                                            ],
                                                            tmpChildren,
                                                        );
                                                        setBlock(tmpBlock);
                                                        setCurrentComponentIndex(
                                                            index,
                                                        );
                                                    }}
                                                    deleteBlock={(
                                                        index: number,
                                                    ) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(block);
                                                        const tmpChildren = [
                                                            ...components,
                                                        ];
                                                        _.pullAt(
                                                            tmpChildren,
                                                            idx,
                                                        );
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [
                                                                "data",
                                                                ALL_IN_ONE,
                                                                "components",
                                                            ],
                                                            tmpChildren,
                                                        );
                                                        setBlock(tmpBlock);
                                                        setCurrentComponentIndex(
                                                            idx - 1,
                                                        );
                                                    }}
                                                />
                                            );
                                        },
                                    )}
                                </SortableContainerCustom>
                                {!components?.length && (
                                    <div
                                        style={{
                                            fontSize: "16px",
                                            color: "red",
                                        }}
                                    >
                                        No components added
                                    </div>
                                )}
                                {!disableWrite && (
                                    <div
                                        style={{
                                            minWidth: "208px",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: "10px",
                                        }}
                                    >
                                        <Dropdown
                                            overlay={componentsMenu}
                                            trigger={["click"]}
                                            // overlayStyle={{ width: "100px" }}
                                            disabled={!allowed}
                                        >
                                            <Button
                                                type="link"
                                                disabled={!allowed}
                                            >
                                                Add new component
                                                <PlusOutlined
                                                    style={{
                                                        fontSize: "15px",
                                                    }}
                                                />
                                            </Button>
                                        </Dropdown>
                                        <Form.Item>
                                            <Input.TextArea
                                                disabled={disableWrite}
                                                value={tempChildren}
                                                placeholder="Paste Master Component here"
                                                onChange={(e) =>
                                                    setTempChildren(
                                                        e.target.value,
                                                    )
                                                }
                                                rows={2}
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                            <Button
                                                disabled={
                                                    disableWrite ||
                                                    !tempChildren
                                                }
                                                style={{ marginTop: "10px" }}
                                                type="primary"
                                                size="small"
                                                onClick={() => {
                                                    try {
                                                        const parsedJson =
                                                            JSON.parse(
                                                                tempChildren,
                                                            );
                                                        if (
                                                            !Object.values(
                                                                COMPONENT_TYPES,
                                                            ).includes(
                                                                parsedJson?.type,
                                                            )
                                                        ) {
                                                            message.warn(
                                                                "Invalid JSON",
                                                            );
                                                            return;
                                                        }
                                                        let tmpBlock =
                                                            _.cloneDeep(block);
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [
                                                                "data",
                                                                ALL_IN_ONE,
                                                                "components",
                                                                components?.length,
                                                            ],
                                                            updateKeysAndCopy(
                                                                parsedJson,
                                                                false,
                                                            ),
                                                        );
                                                        setBlock(tmpBlock);
                                                        setCurrentComponentIndex(
                                                            components?.length,
                                                        );
                                                        setTempChildren("");
                                                        message.info(
                                                            "Updated!",
                                                        );
                                                    } catch (e) {
                                                        // captureException(e)
                                                        message.error(
                                                            "Error in JSON!",
                                                        );
                                                    }
                                                }}
                                            >
                                                Add New Component
                                            </Button>
                                        </Form.Item>
                                    </div>
                                )}
                            </>
                        )}
                    </Can>
                    <Collapse>
                        <Panel header="Variables" key="2">
                            {renderContextVariables && renderContextVariables()}
                            <QuestionVariablesModal
                                disableWrite={disableWrite}
                                hasQuestionVars={true}
                                question_derived_variables={
                                    question_derived_variables
                                }
                                question_variables={question_variables}
                                setQuestionVariables={(val, val2) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        worksheet?.type ===
                                            "personalized_learning_v4"
                                            ? ["backend", "question_variables"]
                                            : [
                                                  "data",
                                                  ALL_IN_ONE,
                                                  "question_variables",
                                              ],
                                        val,
                                    );
                                    tmpBlock = set(
                                        tmpBlock,
                                        worksheet?.type ===
                                            "personalized_learning_v4"
                                            ? [
                                                  "backend",
                                                  "question_derived_variables",
                                              ]
                                            : [
                                                  "data",
                                                  ALL_IN_ONE,
                                                  "question_derived_variables",
                                              ],
                                        val2,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Panel>
                        <Panel
                            header="Settings"
                            key="1"
                            style={
                                !jumpLogicValid({
                                    functions:
                                        (worksheet?.type ===
                                        "personalized_learning_v4"
                                            ? backend?.compute_functions
                                            : logic.compute_functions) || [],
                                    chunkIndex,
                                    blockIndex,
                                    chunkSlug,
                                    blockSlug: slug,
                                })
                                    ? {
                                          background: "#FFD6D7",
                                      }
                                    : {}
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    gap: "4px",
                                }}
                            >
                                <LogicModal
                                    blockIndex={blockIndex}
                                    chunkIndex={chunkIndex}
                                    blockSlug={slug}
                                    chunkSlug={chunkSlug}
                                    isAllGlobal={true}
                                    disableWrite={disableWrite}
                                    computeFUnctions={
                                        (worksheet?.type ===
                                        "personalized_learning_v4"
                                            ? backend?.compute_functions
                                            : logic.compute_functions) || []
                                    }
                                    onSave={(val: any) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            worksheet?.type ===
                                                "personalized_learning_v4"
                                                ? [
                                                      "backend",
                                                      "compute_functions",
                                                  ]
                                                : [
                                                      "data",
                                                      ALL_IN_ONE,
                                                      "logic",
                                                      "compute_functions",
                                                  ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                                <Select
                                    value={logic.check_type}
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "One by one",
                                            value: "ONE_BY_ONE",
                                        },
                                        {
                                            label: "One by one till first incorrect",
                                            value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                        },
                                        {
                                            label: "All in one go",
                                            value: "ALL_IN_ONE_GO",
                                        },
                                    ]}
                                    style={{
                                        width: "150px",
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "data",
                                                ALL_IN_ONE,
                                                "logic",
                                                "check_type",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            </div>
                            <Form.Item
                                label="Feedback Type"
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <Select
                                    value={logic.feedback_type}
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "No Feedback",
                                            value: "NO_FEEDBACK",
                                        },
                                        {
                                            label: "Manual Audio",
                                            value: "MANUAL_AUDIO",
                                        },
                                        {
                                            label: "Manual Story",
                                            value: "MANUAL_STORY",
                                        },
                                        {
                                            label: "Generated Audio",
                                            value: "GENERATED_AUDIO",
                                        },
                                        {
                                            label: "Generated Story",
                                            value: "GENERATED_STORY",
                                        },
                                    ]}
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "data",
                                                ALL_IN_ONE,
                                                "logic",
                                                "feedback_type",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            </Form.Item>
                            <Form.Item label={"show_answer"}>
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={logic.show_answer}
                                    onChange={(val: any) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "data",
                                                ALL_IN_ONE,
                                                "logic",
                                                "show_answer",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label={"disable_submit_button"}>
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={logic.disable_submit_button}
                                    onChange={(val: any) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "data",
                                                ALL_IN_ONE,
                                                "logic",
                                                "disable_submit_button",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Show answer when"
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <Select
                                    value={logic.show_answer_when}
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "ALL_LIVES_LOST",
                                            value: "ALL_LIVES_LOST",
                                        },
                                        {
                                            label: "QUESTION_END",
                                            value: "QUESTION_END",
                                        },
                                    ]}
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "data",
                                                ALL_IN_ONE,
                                                "logic",
                                                "show_answer_when",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            </Form.Item>
                            <Form.Item label={"Answer Display text"}>
                                <Input.TextArea
                                    disabled={disableWrite}
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    value={
                                        logic.answer_display_text ??
                                        "Correct Answers!"
                                    }
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "data",
                                                ALL_IN_ONE,
                                                "logic",
                                                "answer_display_text",
                                            ],
                                            e.target.value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </Card>
    );
};
