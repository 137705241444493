import { Button, Card, Col, Form, Input, Row, Select, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import ComponentDetails from "../common/ComponentDetails";
import { LogicModal } from "../common/LogicModal";
import { DeleteOutlined } from "@ant-design/icons";
import _, { cloneDeep } from "lodash";
import { SlateEditor } from "src/components";

const GenericRendererEditor = (props: any) => {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
        renderLinkSelect,
    } = props;
    const [tempClues, setTempClues] = useState(block?.data?.clues || []);
    const handleAddClue = () => {
        const updatedTempClues = [...tempClues, {}];
        setTempClues(updatedTempClues);
    };

    const handleSaveClue = (index: number) => {
        const updatedBlock = _.cloneDeep(block);
        _.set(updatedBlock, `data.clues[${index}]`, tempClues[index]);
        setBlock(updatedBlock);
    };

    const handleDeleteClue = (index: number) => {
        const updatedTempClues = tempClues.filter((_, idx) => idx !== index);
        setTempClues(updatedTempClues);

        const updatedBlock = _.cloneDeep(block);
        _.set(updatedBlock, "data.clues", updatedTempClues);
        setBlock(updatedBlock);
    };

    const handleClueChange = (index: number, value: any) => {
        const updatedTempClues = [...tempClues];
        updatedTempClues[index] = value;
        setTempClues(updatedTempClues);
    };

    return (
        <div
            style={{
                paddingBottom: "10px",
                display: "flex",
            }}
        >
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
                title={<h3>Configurations</h3>}
            >
                <ComponentDetails
                    block={block}
                    setBlock={setBlock}
                    disableWrite={disableWrite}
                />

                <div
                    style={{
                        marginTop: "20px",
                    }}
                >
                    {renderLinkSelect &&
                        renderLinkSelect({
                            value: block?.linked_global_context_variable?.name,
                            onChange: (value: any) => {
                                let tmpCell = cloneDeep(block);
                                tmpCell = _.set(
                                    tmpCell,
                                    ["linked_global_context_variable", "name"],
                                    value,
                                );
                                setBlock(tmpCell);
                            },
                        })}
                </div>

                <Form.Item
                    label="Default Value"
                    style={{
                        marginTop: "20px",
                    }}
                >
                    <Input.TextArea
                        disabled={disableWrite}
                        // style={{ width: 78 }}
                        value={block?.data?.default_value}
                        onChange={(e) => {
                            const tmpBlock = _.cloneDeep(block);
                            tmpBlock.data.default_value = e.target.value;
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={"Type"}
                    style={{
                        padding: "0rem 1rem",
                    }}
                >
                    <Select
                        value={block?.data?.type}
                        placeholder="Select type"
                        options={[
                            {
                                label: "string",
                                value: "STRING",
                            },
                            {
                                label: "html",
                                value: "HTML",
                            },
                            {
                                label: "score board",
                                value: "SCORE_BOARD",
                            },
                            {
                                label: "clues",
                                value: "CLUES",
                            },
                        ]}
                        style={{
                            flexGrow: 0,
                        }}
                        onChange={(val: any) => {
                            const tmpBlock = _.cloneDeep(block);
                            tmpBlock.data.type = val;
                            setBlock(tmpBlock);
                        }}
                        disabled={disableWrite}
                    />
                    {block?.data?.type === "CLUES" && (
                        <>
                            <div
                                style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                }}
                            >
                                <Button
                                    onClick={handleAddClue}
                                    disabled={disableWrite}
                                    style={{
                                        background: "DodgerBlue",
                                        color: "white",
                                        cursor: disableWrite
                                            ? "not-allowed"
                                            : "pointer",
                                    }}
                                >
                                    Add Clue
                                </Button>
                                <Form.Item
                                    label={"Show Clues Interaction buttons"}
                                >
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={
                                            block?.data?.cluesInteractionBtn ??
                                            true
                                        }
                                        onChange={(val: any) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["data", "cluesInteractionBtn"],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            {tempClues.map((clue: any, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "1rem",
                                            border: "1px solid #f0f0f0",
                                        }}
                                    >
                                        <h4 style={{ margin: "0 1rem" }}>
                                            {index + 1}.
                                        </h4>
                                        <SlateEditor
                                            disabled={disableWrite}
                                            hasMentions={hasMentions}
                                            mentionsList={[]}
                                            id={`clue_${index}`}
                                            onChange={(value: any) =>
                                                handleClueChange(index, value)
                                            }
                                            isFocused={
                                                currentEditor ===
                                                `clue_${index}`
                                            }
                                            setEditor={(id: string) =>
                                                setCurrentEditor(id)
                                            }
                                            placeholder={"Type here..."}
                                            value={clue}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "0.2rem",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleSaveClue(index)
                                                }
                                                disabled={disableWrite}
                                                style={{
                                                    background: "DodgerBlue",
                                                    color: "white",
                                                    cursor: disableWrite
                                                        ? "not-allowed"
                                                        : "pointer",
                                                }}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    handleDeleteClue(index)
                                                }
                                                disabled={disableWrite}
                                                style={{
                                                    background: "red",
                                                    color: "white",

                                                    cursor: disableWrite
                                                        ? "not-allowed"
                                                        : "pointer",
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </Form.Item>
                <Row>
                    <Col span={24}>
                        <Form.Item label={"Effects"}>
                            <Button
                                ghost
                                type="primary"
                                size="small"
                                style={{
                                    marginBottom: "10px",
                                }}
                                disabled={disableWrite}
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(block);
                                    if (tmpBlock.data.effects === undefined) {
                                        tmpBlock.data.effects = [
                                            {
                                                event: "",
                                                computeFunction: { output: "" },
                                            },
                                        ];
                                    } else {
                                        tmpBlock.data.effects.push({
                                            event: "",
                                            computeFunction: { output: "" },
                                        });
                                    }
                                    setBlock(tmpBlock);
                                }}
                            >
                                Add New Table Effects
                            </Button>
                            {block?.data?.effects?.map(
                                (v: any, key: number) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "1rem",
                                        }}
                                    >
                                        <Input
                                            key={key}
                                            disabled={disableWrite}
                                            value={v.event}
                                            placeholder="event"
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.effects[
                                                    key
                                                ].event = e.target.value;
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                        <Input.TextArea
                                            key={key}
                                            disabled={disableWrite}
                                            value={v.computeFunction.output}
                                            placeholder="computeFunction"
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.effects[
                                                    key
                                                ].computeFunction.output =
                                                    e.target.value;
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                        <Button
                                            disabled={disableWrite}
                                            icon={<DeleteOutlined />}
                                            type="primary"
                                            shape="circle"
                                            danger
                                            size="small"
                                            onClick={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.effects =
                                                    tmpBlock.data.effects.filter(
                                                        (
                                                            v: any,
                                                            index: number,
                                                        ) => index !== key,
                                                    );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </div>
                                ),
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={"Mount functions"}>
                            <Button
                                ghost
                                type="primary"
                                size="small"
                                style={{
                                    marginBottom: "10px",
                                }}
                                disabled={disableWrite}
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(block);
                                    if (
                                        tmpBlock.data.mountFunctions ===
                                        undefined
                                    ) {
                                        tmpBlock.data.mountFunctions = [
                                            {
                                                event: "",
                                                computeFunction: { output: "" },
                                            },
                                        ];
                                    } else {
                                        tmpBlock.data.mountFunctions.push({
                                            event: "",
                                            computeFunction: { output: "" },
                                        });
                                    }
                                    setBlock(tmpBlock);
                                }}
                            >
                                Add New Table Effects
                            </Button>
                            {block?.data?.mountFunctions?.map(
                                (v: any, key: number) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "1rem",
                                        }}
                                    >
                                        <Input.TextArea
                                            key={key}
                                            disabled={disableWrite}
                                            value={v.computeFunction.output}
                                            placeholder="computeFunction"
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.mountFunctions[
                                                    key
                                                ].computeFunction.output =
                                                    e.target.value;
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                        <Button
                                            disabled={disableWrite}
                                            icon={<DeleteOutlined />}
                                            type="primary"
                                            shape="circle"
                                            danger
                                            size="small"
                                            onClick={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.mountFunctions =
                                                    tmpBlock.data.mountFunctions.filter(
                                                        (
                                                            v: any,
                                                            index: number,
                                                        ) => index !== key,
                                                    );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </div>
                                ),
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default GenericRendererEditor;
