import { CheckOutlined } from "@ant-design/icons";
import EditIcon from "src/components/EditIcon";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import ReactPlayer from "react-player";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";
const { TextArea } = Input;

const renderEditorAudio = ({
    block,
    setBlock,
    worksheet,
    disabled,
    isReadOnlyMode,
}: any) => {
    const audioUrl = convertS3UrlToGcp(block?.data?.audio);

    const [isEditState, setEditState] = useState(!!!audioUrl);

    const isValid = Boolean(audioUrl.trim());

    return (
        <div style={{ width: "100%" }}>
            {isEditState ? (
                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    validateStatus={!isValid ? "error" : ""}
                    help={!isValid ? "Field cannot be empty" : ""}
                >
                    <div style={{ display: "flex" }}>
                        <TextArea
                            rows={10}
                            disabled={disabled || isReadOnlyMode}
                            value={block?.data?.audio || ""}
                            onChange={(e) =>
                                setBlock({
                                    ...block,
                                    data: { audio: e.target.value },
                                })
                            }
                        />
                        {audioUrl && (
                            <Button
                                icon={
                                    <CheckOutlined
                                        onClick={() => setEditState(false)}
                                    />
                                }
                                disabled={disabled || isReadOnlyMode}
                                shape="circle"
                                type="primary"
                                style={{ marginLeft: "10px" }}
                            />
                        )}
                    </div>
                </Form.Item>
            ) : (
                <div style={{ display: "flex" }}>
                    <div>
                        <ReactPlayer
                            url={convertS3UrlToGcp(audioUrl)}
                            controls
                            file={{ forceAudio: true }}
                            height={"60px"}
                            maxWidth="100%"
                        />
                    </div>
                    {audioUrl && (
                        <EditIcon
                            disabled={disabled || isReadOnlyMode}
                            isButton={true}
                            onClick={() => setEditState(true)}
                            style={{ marginLeft: "10px" }}
                        />
                    )}
                </div>
            )}

            {/* {["timed", "personalized_learning"].includes(worksheet?.type) && (
                <div
                    style={{
                        border: "1px dashed #808080",
                        margin: "10px auto",
                        padding: "10px 10px",
                    }}
                >
                    <h3>Duration</h3>
                    <DurationPicker
                        onChange={onDurationChange}
                        initialValue={block?.data?.other?.duration || 0}
                    />
                </div>
            )}

            {["personalized_learning"].includes(worksheet?.type) && (
                <div
                    style={{
                        border: "1px dashed #808080",
                        margin: "10px auto",
                        padding: "10px 10px",
                    }}
                >
                    <h3>BackgroundAudio</h3>
                    <AudioPicker block={block} 
                                    disabled={isReadOnlyMode} setBlock={setBlock} />
                </div>
            )} */}
        </div>
    );
};

export default renderEditorAudio;
