import React from "react";
import { Input, Form, Row, Col, Switch, Divider } from "antd";
import { BlockPicker } from "react-color";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { RenderUpload } from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

interface CellFieldsProps {
    type: "common" | "non-common" | "all";
    cell: any;
    rowIndex: number;
    colIndex: number;
    handleCellChange: (
        rowIndex: number,
        colIndex: number,
        value: string,
    ) => void;
    handleFontSizeChange: (
        rowIndex: number,
        colIndex: number,
        size: number,
    ) => void;
    handleFontColorChange: (
        rowIndex: number,
        colIndex: number,
        color: string,
    ) => void;
    handleCellFillColorEnabled: (
        rowIndex: number,
        colIndex: number,
        enabled: boolean,
    ) => void;
    handleCellFillColorChange: (
        rowIndex: number,
        colIndex: number,
        color: string,
    ) => void;
    handleImageBackgroundChange: (
        rowIndex: number,
        colIndex: number,
        value: string,
    ) => void;
}

const CellFields: React.FC<CellFieldsProps> = ({
    type,
    cell,
    rowIndex,
    colIndex,
    handleCellChange,
    handleFontSizeChange,
    handleFontColorChange,
    handleCellFillColorEnabled,
    handleCellFillColorChange,
    handleImageBackgroundChange,
}) => {
    return (
        <div>
            {(type === "non-common" || type === "all") && (
                <>
                    <h5>Text: </h5>
                    <Row>
                        <Col span={12}>
                            <Input
                                value={
                                    cell?.content?.text?.enabled
                                        ? cell?.content?.text?.value?.default ||
                                          ""
                                        : ""
                                }
                                onChange={(e: any) =>
                                    handleCellChange(
                                        rowIndex,
                                        colIndex,
                                        e.target.value,
                                    )
                                }
                                style={{ width: "60px" }}
                            />
                        </Col>
                    </Row>
                    <Form.Item label="Image Background">
                        <RenderUpload
                            singleUpload={true}
                            disabled={false}
                            addExtension={true}
                            path="home-explore/document/"
                            onChangeCustom={(e) => {
                                const value = replaceCDNUrl(
                                    e.target.value,
                                    e.target.bucket,
                                );
                                handleImageBackgroundChange(
                                    rowIndex,
                                    colIndex,
                                    value,
                                );
                            }}
                            label="Upload the file"
                            value={convertS3UrlToGcp(
                                cell?.content?.imageBackground?.src?.default ||
                                    "",
                            )}
                        />
                        <Input
                            placeholder="Add image URL here"
                            value={
                                cell?.content?.imageBackground?.src?.default ||
                                ""
                            }
                            onChange={(e) =>
                                handleImageBackgroundChange(
                                    rowIndex,
                                    colIndex,
                                    e.target.value,
                                )
                            }
                        />
                    </Form.Item>
                </>
            )}

            {(type === "common" || type === "all") && (
                <>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Font Size">
                                <Input
                                    type="number"
                                    value={
                                        cell?.content?.text?.size?.default || 16
                                    }
                                    onChange={(e) =>
                                        handleFontSizeChange(
                                            rowIndex,
                                            colIndex,
                                            parseInt(e.target.value),
                                        )
                                    }
                                    style={{ width: "60px" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Font Color">
                                <BlockPicker
                                    colors={[]}
                                    color={
                                        cell?.content?.text?.color?.default ||
                                        "#000"
                                    }
                                    onChangeComplete={(color: any) =>
                                        handleFontColorChange(
                                            rowIndex,
                                            colIndex,
                                            color.hex,
                                        )
                                    }
                                    triangle="hide"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Form.Item
                        label="Fill Color Enabled"
                        style={{ marginBottom: "8px" }}
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={cell?.content?.fillColor?.enabled}
                            onChange={(value) =>
                                handleCellFillColorEnabled(
                                    rowIndex,
                                    colIndex,
                                    value,
                                )
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Fill Color" style={{ marginBottom: "0" }}>
                        <BlockPicker
                            colors={[]}
                            color={cell?.content?.fillColor?.default}
                            onChangeComplete={(color: any) =>
                                handleCellFillColorChange(
                                    rowIndex,
                                    colIndex,
                                    color.hex,
                                )
                            }
                            triangle="hide"
                        />
                    </Form.Item>
                </>
            )}
        </div>
    );
};

export default CellFields;
