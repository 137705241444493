import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
    Form,
    Space,
    Button,
    message,
    Input,
    Divider,
    Switch,
    Select,
} from "antd";
import _, { cloneDeep, isString } from "lodash";
import React, { useEffect, useState } from "react";
import { RenderUpload, SlateEditor } from "src/components";
import { renderer, replaceCDNUrl } from "../../../../helpers";
import TableBorderEditor from "../TableBorder";
import TablePaddingEditor from "../TablePadding";
import { TableDragDropCellForm } from "../TableDragDrop";
import TableInputEditor from "../TableInput";
import TableDropdownOptionsEditor from "../OptionsField";
import CorrectInputValuesEditor from "../CorrectInputValues";
import { BlockPicker } from "react-color";
import AlignmentComponent from "../Alignment";
import { captureException } from "@sentry/react";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const CellForm = (props: any) => {
    const {
        closeForm,
        curCellRow,
        curCellCol,
        curCell,
        block,
        setBlock,
        disableWrite,
        categoryLists,
        tableVariableName,
        currentEditor,
        setCurrentEditor,
        hasMentions,
        mentionsList = [],
        tagsList,
        renderLinkSelect,
    } = props;

    const variableNamePrefix = `tableVariable_${tableVariableName}_${curCellRow}${curCellCol}`;

    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [currentCell, setCurrentCell] = useState(curCell);

    useEffect(() => {
        setDisabled(false);
    }, [currentCell]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            let tmpBlock = cloneDeep(block);
            let newVariables = [];
            const tempData = tmpBlock?.cells;
            let tempCurrentCell = cloneDeep(currentCell);
            if (currentCell.input?.keyboard) {
                const customKeyboardVariableName = `${variableNamePrefix}_customKeyboard`;
                const newVariableObject = {
                    name: customKeyboardVariableName,
                    type: "LOCAL",
                    default: "",
                    objectType: "CUSTOM_KEYBOARD",
                    isDerived: false,
                    value: currentCell.input?.keyboard,
                    artificialVariable: true,
                };
                newVariables.push(newVariableObject);
                tempCurrentCell.input.keyboard_variable =
                    customKeyboardVariableName;
            }
            if (currentCell.dropdown?.options?.length) {
                const dropdownOptionsVariableName = `${variableNamePrefix}_dropdownOptions`;
                const newDropdownOptionsVariableObject = {
                    name: dropdownOptionsVariableName,
                    type: "LOCAL",
                    default: "",
                    objectType: "DROPDOWN",
                    isDerived: false,
                    value: currentCell.dropdown?.options,
                    artificialVariable: true,
                };
                newVariables.push(newDropdownOptionsVariableObject);
                tempCurrentCell.dropdown.optionsVariable =
                    dropdownOptionsVariableName;
            }
            tempData[curCellRow][curCellCol] = tempCurrentCell;
            tmpBlock.cells = tempData;

            setBlock(tmpBlock, newVariables);
            message.success("Successfully Updated!");
            closeForm();
        } catch (e) {
            captureException(e);
            message.success("Could not complete the request!");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Form
                style={{
                    maxHeight: "75vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                }}
            >
                {renderLinkSelect({
                    value: currentCell?.linked_global_context_variable?.name,
                    onChange: (v) => {
                        let tmpBlock = _.cloneDeep(currentCell);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["linked_global_context_variable", "name"],
                            v,
                        );
                        // tmpBlock.value = v !== null ? `@@${v}@@` : v;
                        setCurrentCell(tmpBlock);
                    },
                })}
                <Form.Item label="Value from variable" labelCol={{ span: 24 }}>
                    <Select
                        value={currentCell?.value}
                        placeholder="Select type"
                        options={[
                            {
                                label: "none",
                                value: null,
                            },
                            ...mentionsList?.map((v) => ({
                                label: v.text,
                                value: `@@${v.text}@@`,
                            })),
                        ]}
                        style={{
                            width: "150px",
                            flexGrow: 0,
                        }}
                        onChange={(val) => {
                            let tmpBlock = _.cloneDeep(currentCell);
                            tmpBlock.value = val;
                            setCurrentCell(tmpBlock);
                        }}
                        disabled={disableWrite}
                    />
                </Form.Item>
                <Divider />
                <TableDragDropCellForm
                    blockData={currentCell}
                    setBlock={setCurrentCell}
                    categoryLists={categoryLists}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    tagsList={tagsList}
                />
                <Divider />
                <h2>Text</h2>
                <Form.Item label="Enabled?">
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={currentCell?.text?.enabled}
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(
                                tmpCell,
                                ["text", "enabled"],
                                value,
                            );
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Form.Item>
                {currentCell?.text?.enabled && (
                    <>
                        <Form.Item label="Font Size">
                            <Input
                                disabled={disableWrite}
                                value={currentCell?.text?.size?.default ?? 16}
                                placeholder="Font Size"
                                style={{ width: "100%" }}
                                type="number"
                                onChange={(e: any) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    if (!tmpCell.text.size)
                                        tmpCell.text.size = { default: 0 };
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["text", "size", "default"],
                                        e.target.value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Font Color">
                            <BlockPicker
                                colors={[]}
                                color={
                                    currentCell?.text?.color?.default ?? "#000"
                                }
                                onChangeComplete={(color: any) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["text", "color", "default"],
                                        color.hex,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                                triangle="hide"
                            />
                        </Form.Item>
                        {/* Select Field for text type - normal, latex */}
                        <Form.Item label="Type">
                            <Select
                                disabled={disableWrite}
                                value={currentCell?.text?.type}
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["text", "type"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            >
                                <Select.Option value="normal">
                                    Normal
                                </Select.Option>
                                <Select.Option value="latex">
                                    Latex
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        {(currentCell?.text?.type === "normal" ||
                            !currentCell?.text?.type) && (
                            <Form.Item label="Value">
                                <Input
                                    disabled={disableWrite}
                                    value={currentCell?.text?.value?.default}
                                    placeholder="Value"
                                    style={{ width: "100%" }}
                                    onChange={(e: any) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["text", "value", "default"],
                                            e.target.value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                />
                            </Form.Item>
                        )}
                        {currentCell?.text?.type === "latex" && (
                            <SlateEditor
                                disabled={disableWrite}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`${variableNamePrefix}-cell-text-value-latex`}
                                onChange={(value: any) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["text", "value", "default"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                                isFocused={
                                    currentEditor ===
                                    `${variableNamePrefix}-cell-text-value-latex`
                                }
                                setEditor={(id: string) => setCurrentEditor(id)}
                                value={
                                    isString(
                                        currentCell?.text?.value?.default || "",
                                    )
                                        ? []
                                        : currentCell?.text?.value?.default
                                }
                                placeholder={"Type here..."}
                                // v2={true}
                            />
                        )}
                    </>
                )}
                <Divider />
                <h2>Hidden cell</h2>
                <Form.Item label="Is Cell Hidden?">
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={currentCell?.hidden}
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(tmpCell, ["hidden"], value);
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Form.Item>
                <AlignmentComponent
                    block={currentCell}
                    setBlock={setCurrentCell}
                />
                <h2>Tappable</h2>
                <Form.Item label="Enabled?">
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={currentCell?.tappable?.enabled}
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(
                                tmpCell,
                                ["tappable", "enabled"],
                                value,
                            );
                            tmpCell = _.set(
                                tmpCell,
                                ["tappable", "tap_count", "default"],
                                0,
                            );
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Form.Item>
                {currentCell?.tappable?.enabled && (
                    <>
                        <Form.Item label="Default Selected?">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={
                                    currentCell?.tappable?.default?.selected ===
                                    "0"
                                        ? false
                                        : currentCell?.tappable?.default
                                              ?.selected
                                }
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["tappable", "default", "selected"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label={"Max Tap"}>
                            <Input
                                placeholder="Max Tap"
                                type="number"
                                min={0}
                                value={currentCell?.tappable?.max_tap?.default}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["tappable", "max_tap", "default"],
                                        Number(value),
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </>
                )}
                <Divider />
                <h2>Clickable</h2>
                <Form.Item label="Enabled?">
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={currentCell?.clickable?.enabled}
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(
                                tmpCell,
                                ["clickable", "enabled"],
                                value,
                            );
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Form.Item>
                <Divider />
                <TableInputEditor
                    blockData={currentCell}
                    setBlock={setCurrentCell}
                    variableNamePrefix={variableNamePrefix}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                    disableWrite={disableWrite}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                />
                <Divider />
                <h2>Image</h2>
                <Form.Item label="Enabled?">
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={currentCell?.image?.enabled}
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(
                                tmpCell,
                                ["image", "enabled"],
                                value,
                            );
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Form.Item>
                {currentCell?.image?.enabled && (
                    <>
                        <Form.Item label="Asset">
                            <RenderUpload
                                singleUpload={true}
                                disabled={disableWrite}
                                addExtension={true}
                                path="home-explore/document/"
                                onChangeCustom={(e) => {
                                    const value = replaceCDNUrl(
                                        e.target.value,
                                        e.target.bucket,
                                    );
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["image", "src", "default"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                                label="upload the file"
                                value={convertS3UrlToGcp(
                                    currentCell?.image?.src?.default,
                                )}
                            />
                            <Input
                                placeholder="Add value here"
                                value={currentCell?.image?.src?.default}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["image", "src", "default"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </>
                )}
                <h3>Image Background</h3>
                <Form.Item label="Enabled?">
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={currentCell?.imageBackground?.enabled}
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            if (!tmpCell?.imageBackground)
                                tmpCell.imageBackground = {};
                            tmpCell = _.set(
                                tmpCell,
                                ["imageBackground", "enabled"],
                                value,
                            );
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Form.Item>
                {/*Image Background Size, default contain, options - contain and cover*/}
                <Form.Item label="Size">
                    <Select
                        disabled={disableWrite}
                        value={currentCell?.imageBackground?.size}
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(
                                tmpCell,
                                ["imageBackground", "size"],
                                value,
                            );
                            setCurrentCell(tmpCell);
                        }}
                    >
                        <Select.Option value="contain">Contain</Select.Option>
                        <Select.Option value="cover">Cover</Select.Option>
                    </Select>
                </Form.Item>
                {currentCell?.imageBackground?.enabled && (
                    <>
                        <Form.Item label="Asset">
                            <RenderUpload
                                singleUpload={true}
                                disabled={disableWrite}
                                addExtension={true}
                                path="home-explore/document/"
                                onChangeCustom={(e) => {
                                    const value = replaceCDNUrl(
                                        e.target.value,
                                        e.target.bucket,
                                    );
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["imageBackground", "src", "default"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                                label="upload the file"
                                value={convertS3UrlToGcp(
                                    currentCell?.imageBackground?.src?.default,
                                )}
                            />
                            <Input
                                placeholder="Add value here"
                                value={
                                    currentCell?.imageBackground?.src?.default
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["imageBackground", "src", "default"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </>
                )}
                <Divider />
                <h3>Fill Color</h3>
                <Form.Item label="Enabled?">
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={currentCell?.fillColor?.enabled}
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(
                                tmpCell,
                                ["fillColor", "enabled"],
                                value,
                            );
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Default Color">
                    <BlockPicker
                        colors={[]}
                        color={currentCell?.fillColor?.default}
                        onChangeComplete={(color: any) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(
                                tmpCell,
                                ["fillColor", "default"],
                                color.hex,
                            );
                            setCurrentCell(tmpCell);
                        }}
                        triangle="hide"
                    />
                </Form.Item>
                <Form.Item>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={
                            currentCell?.fillColor?.default === "transparent"
                        }
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(
                                tmpCell,
                                ["fillColor", "default"],
                                value ? "transparent" : "#ffffff",
                            );
                            setCurrentCell(tmpCell);
                        }}
                    />{" "}
                    Transparent
                </Form.Item>
                <Divider />
                <h3>Dropdown</h3>
                <Form.Item label="Enabled?">
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={currentCell?.dropdown?.enabled}
                        onChange={(value) => {
                            let tmpCell = cloneDeep(currentCell);
                            tmpCell = _.set(
                                tmpCell,
                                ["dropdown", "enabled"],
                                value,
                            );
                            tmpCell = _.set(
                                tmpCell,
                                ["dropdown", "type"],
                                "latex",
                            );
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Form.Item>
                {currentCell?.dropdown?.enabled && (
                    <>
                        <Form.Item label="Dimension Control">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={
                                    currentCell?.dropdown?.dimensionControl
                                        ?.enabled
                                }
                                onChange={(value) => {
                                    let tmpCell = _.cloneDeep(currentCell);
                                    if (!tmpCell?.dropdown?.dimensionControl) {
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["dropdown", "dimensionControl"],
                                            {},
                                        );
                                    }
                                    tmpCell = _.set(
                                        tmpCell,
                                        [
                                            "dropdown",
                                            "dimensionControl",
                                            "enabled",
                                        ],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            />
                        </Form.Item>

                        {currentCell?.dropdown?.dimensionControl?.enabled && (
                            <>
                                <Form.Item label="Min Width">
                                    <Input
                                        value={
                                            currentCell?.dropdown
                                                ?.dimensionControl?.minWidth
                                        }
                                        type="number"
                                        placeholder="Min Width"
                                        onChange={(e: any) => {
                                            let tmpCell =
                                                _.cloneDeep(currentCell);
                                            if (
                                                !tmpCell?.dropdown
                                                    ?.dimensionControl
                                            ) {
                                                tmpCell = _.set(
                                                    tmpCell,
                                                    [
                                                        "dropdown",
                                                        "dimensionControl",
                                                    ],
                                                    {},
                                                );
                                            }
                                            tmpCell = _.set(
                                                tmpCell,
                                                [
                                                    "dropdown",
                                                    "dimensionControl",
                                                    "minWidth",
                                                ],
                                                e.target.value,
                                            );
                                            setCurrentCell(tmpCell);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Min Height">
                                    <Input
                                        value={
                                            currentCell?.dropdown
                                                ?.dimensionControl?.minHeight
                                        }
                                        type="number"
                                        placeholder="Min Height"
                                        onChange={(e: any) => {
                                            let tmpCell =
                                                _.cloneDeep(currentCell);
                                            if (
                                                !tmpCell?.dropdown
                                                    ?.dimensionControl
                                            ) {
                                                tmpCell = _.set(
                                                    tmpCell,
                                                    [
                                                        "dropdown",
                                                        "dimensionControl",
                                                    ],
                                                    {},
                                                );
                                            }
                                            tmpCell = _.set(
                                                tmpCell,
                                                [
                                                    "dropdown",
                                                    "dimensionControl",
                                                    "minHeight",
                                                ],
                                                e.target.value,
                                            );
                                            setCurrentCell(tmpCell);
                                        }}
                                    />
                                </Form.Item>
                            </>
                        )}
                        <TableDropdownOptionsEditor
                            blockData={currentCell}
                            setBlock={setCurrentCell}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            disableWrite={disableWrite}
                            currentEditor={currentEditor}
                            setCurrentEditor={setCurrentEditor}
                        />
                        <br />
                        {/*Default Value, dropdown of the options defined above*/}
                        <Form.Item label="Default Value">
                            <Select
                                disabled={disableWrite}
                                value={currentCell?.dropdown?.default}
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["dropdown", "default"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            >
                                {(currentCell?.dropdown?.options || [])?.map(
                                    (option: any, idx: number) => (
                                        <Select.Option
                                            key={idx}
                                            value={option.value}
                                        >
                                            {renderer(option.label)}
                                        </Select.Option>
                                    ),
                                )}
                            </Select>
                        </Form.Item>
                        {/*Placeholder field*/}
                        <Form.Item label="Placeholder">
                            <Input
                                disabled={disableWrite}
                                value={currentCell?.dropdown?.placeholder}
                                placeholder="Placeholder"
                                onChange={(e: any) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["dropdown", "placeholder"],
                                        e.target.value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </>
                )}
                <Divider />
                <TableBorderEditor
                    blockData={currentCell}
                    setBlock={setCurrentCell}
                />
                <Divider />
                <TablePaddingEditor
                    blockData={currentCell}
                    setBlock={setCurrentCell}
                />
                <Divider />
                <h3>Correct Input Values</h3>
                <CorrectInputValuesEditor
                    cellData={currentCell}
                    setCell={setCurrentCell}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                />
                <Divider />
            </Form>
            <Space
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: "20px",
                }}
            >
                <Button type="default" onClick={closeForm}>
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={isDisabled}
                >
                    Submit
                </Button>
            </Space>
        </>
    );
};

export default CellForm;
