import {
    QuestionCircleOutlined,
    DeleteOutlined,
    PlusOutlined,
    EditOutlined,
} from "@ant-design/icons";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Space, Popconfirm, Button, Col, Select, Row, message } from "antd";
import { cloneDeep, set } from "lodash";
import { Key, useState } from "react";
import CellButtonInput from "./CellButtonInput";
import { BLOCK_TYPES } from "..";
import { GRID_INPUT_OPTION } from "../../renderers/Crossword/defaultValue";
import FeedbackField from "../FeedBackField";
import { DragHandle, feedbackUpdate } from "../../renderers/V2MCQ/renderEditor";
import { arrayMoveImmutable } from "array-move";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const CROSSWORD = { grid: [], options: [] };

const {
    CROSSWORD_BLOCK,
    DRAG_ARRANGE_LIST_BLOCK,
    COMPARE_ARRANGE_LIST_BLOCK,
    COMPARE_DIGITS_BLOCK,
    SCRATCH_ARRANGE_LIST_BLOCK,
    INPUT_AND_FORM_BLOCK,
    DRAG_EXPAND_NUMBER_BLOCK,
    INPUT_EXPAND_NUMBER_BLOCK,
    V2_INPUT_AND_FORM_BLOCK,
    DRAG_AND_TAG_BLOCK,
} = BLOCK_TYPES;

const GridOptions = (props: any) => {
    const {
        crossword = CROSSWORD,
        setCrossword,
        blockType = CROSSWORD_BLOCK,
        showFeedback,
        feedbacks = [],
        isEditor,
        setIsEditor,
        setFeedback,
        hasMentions,
        mentionsList = [],
        disableWrite,
        isReadOnlyMode,
    } = props;

    const { options } = crossword;

    const [isInputModalOpen, setIsInputModalOpen] = useState(false);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [curCellRow, setCurCellRow] = useState(-1);
    const [curCellCol, setCurCellCol] = useState(-1);
    const [curCell, setCurCell] = useState({});
    const [currentMention, setCurMention] = useState(mentionsList[0]?.text);

    const showInputModal = (row: number, column: number, cell: any) => {
        setCurCellRow(row);
        setCurCellCol(column);
        setCurCell(cell);
        setIsInputModalOpen(true);
    };

    const showFeedbackModal = (index: number) => {
        const findCellIndex = feedbacks.findIndex(
            (item: any) => item?.index === index,
        );

        setCurCellRow(
            findCellIndex !== -1 ? findCellIndex : feedbacks?.length || 0,
        );
        setCurCell(
            findCellIndex !== -1
                ? feedbacks[findCellIndex]
                : {
                      index,
                      values: [],
                  },
        );
        setIsFeedbackModalOpen(true);
    };

    const closeForm = () => {
        setCurCellRow(-1);
        setCurCellCol(-1);
        setCurCell({});
        setIsInputModalOpen(false);
        setIsFeedbackModalOpen(false);
    };

    const cellButtonInputProps = {
        blockType,
        isModalOpen: isInputModalOpen,
        closeForm,
        curCellRow,
        curCellCol,
        curCell,
        crossword,
        setCrossword: (v: any) => {
            if (setCrossword) setCrossword(v);
        },
    };

    const feedbackFieldProps = {
        isModalOpen: isFeedbackModalOpen,
        closeForm,
        feedback: curCell,
        onSave: (data: any) => {
            const tempFB = [...feedbacks];
            tempFB[curCellRow] = data;
            setFeedback(tempFB);
        },
        curIndex: curCellRow,
        isEditor,
        setIsEditor,
        disableWrite,
        isReadOnlyMode,
    };

    const insertInputCell = () => {
        const input: any = {
            type: [
                INPUT_EXPAND_NUMBER_BLOCK,
                INPUT_AND_FORM_BLOCK,
                V2_INPUT_AND_FORM_BLOCK,
            ].includes(blockType)
                ? "input"
                : "string",
        };

        if (blockType === DRAG_EXPAND_NUMBER_BLOCK)
            input.otherFields = {
                is_fixed: false,
                correct_answer: [],
            };

        if ([INPUT_AND_FORM_BLOCK, V2_INPUT_AND_FORM_BLOCK].includes(blockType))
            input.otherFields = {
                correct_answer: [],
                no_border: false,
                width: 1,
            };

        if ([INPUT_EXPAND_NUMBER_BLOCK].includes(blockType))
            input.otherFields = {
                correct_answer: [],
                width: 1,
            };

        return GRID_INPUT_OPTION(input);
    };

    const isMultiRow = [
        CROSSWORD_BLOCK,
        DRAG_ARRANGE_LIST_BLOCK,
        COMPARE_ARRANGE_LIST_BLOCK,
        COMPARE_DIGITS_BLOCK,
        SCRATCH_ARRANGE_LIST_BLOCK,
        DRAG_EXPAND_NUMBER_BLOCK,
        INPUT_EXPAND_NUMBER_BLOCK,
    ].includes(blockType);

    const SortableItemCustom = SortableElement((props: any) => {
        const { option, idx } = props;

        return (
            <Row align={"middle"}>
                {isMultiRow && !disableWrite && (
                    <Col span={1}>
                        <DragHandle />
                    </Col>
                )}
                <Col span={isMultiRow && !disableWrite ? 23 : 24}>
                    {renderOption(option, idx)}
                </Col>
            </Row>
        );
    });

    const SortableContainerCustom = SortableContainer(({ children }: any) => (
        <div>{children}</div>
    ));

    const renderOption = (row: any, i: number) => (
        <div
            style={{
                display: "flex",
                gap: "20px",
                overflowX: "auto",
                overflowY: "hidden",
                marginBottom: "20px",
            }}
        >
            {[
                COMPARE_ARRANGE_LIST_BLOCK,
                COMPARE_DIGITS_BLOCK,
                SCRATCH_ARRANGE_LIST_BLOCK,
                DRAG_ARRANGE_LIST_BLOCK,
            ].includes(blockType) && (
                <Space>
                    <div
                        style={{
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            justifyContent: "center",
                            alignItems: "center",
                            background:
                                crossword?.correct_answer?.length &&
                                crossword?.correct_answer.includes(i + 1)
                                    ? "#4EDEB2"
                                    : "lightgray",
                            color: "#333333",
                            display: "inline-flex",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        {i + 1}.
                    </div>
                </Space>
            )}
            {Array.isArray(row) && (
                <>
                    {row?.map((cell, j) => (
                        <div>
                            {!disableWrite && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <Popconfirm
                                        title={"Delete this cell?"}
                                        disabled={disableWrite}
                                        icon={
                                            <QuestionCircleOutlined
                                                style={{
                                                    color: "red",
                                                }}
                                            />
                                        }
                                        onConfirm={() => {
                                            if (
                                                row?.length === 1 &&
                                                [DRAG_AND_TAG_BLOCK].includes(
                                                    blockType,
                                                )
                                            ) {
                                                message.warn(
                                                    "Need atleast one tag",
                                                );
                                                return;
                                            }
                                            let tmpCW = cloneDeep(crossword);
                                            const tempData = tmpCW?.options;
                                            tempData[i].splice(j, 1);
                                            tmpCW = set(
                                                tmpCW,
                                                ["options"],
                                                tempData,
                                            );

                                            if (setCrossword)
                                                setCrossword(tmpCW);
                                        }}
                                        okText={"Yes"}
                                        cancelText={"Cancel"}
                                    >
                                        <Button
                                            size="small"
                                            shape="circle"
                                            danger
                                            icon={
                                                <DeleteOutlined
                                                    style={{
                                                        color: "red",
                                                    }}
                                                />
                                            }
                                        />
                                    </Popconfirm>

                                    <Button
                                        shape="circle"
                                        type="default"
                                        icon={<EditOutlined />}
                                        size="small"
                                        onClick={() =>
                                            showInputModal(i, j, cell)
                                        }
                                    />
                                </div>
                            )}
                            <div
                                style={{
                                    border:
                                        cell?.no_border &&
                                        cell?.type === "string"
                                            ? ""
                                            : "1px dashed #000000",
                                    minHeight: "56px",
                                    minWidth: "56px",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0 10px",
                                }}
                            >
                                {cell?.type === "asset" ? (
                                    <div
                                        style={{
                                            background: `url(${convertS3UrlToGcp(
                                                cell?.asset,
                                            )})`,
                                            height: "56px",
                                            width: "56px",
                                            backgroundSize: "cover",
                                        }}
                                    />
                                ) : ["options", "signs", "input"].includes(
                                      cell?.type,
                                  ) ? (
                                    <span
                                        style={{
                                            color: "#999",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {cell?.type}
                                    </span>
                                ) : (
                                    cell?.value
                                )}
                            </div>
                            {!cell?.is_fixed &&
                                cell?.correct_answer?.length > 0 && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <strong>Correct Answers:</strong>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: "5px",
                                            }}
                                        >
                                            {cell.correct_answer.map(
                                                (v: string, i: Key) => (
                                                    <span key={i}>{v}</span>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    ))}
                    {!disableWrite &&
                        ![V2_INPUT_AND_FORM_BLOCK].includes(blockType) && (
                            <div
                                style={{
                                    marginTop: "34px",
                                    border: "1px dashed #000000",
                                    height: "56px",
                                    width: "56px",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "#f4f4f4",
                                    flexShrink: 0,
                                    cursor: "pointer",
                                    fontSize: "40px",
                                    color: "#aaa",
                                }}
                                onClick={() => {
                                    let tmpCW = cloneDeep(crossword);
                                    const tempData = tmpCW?.options;
                                    tempData[i].push(insertInputCell());
                                    tmpCW = set(tmpCW, ["options"], tempData);

                                    if (setCrossword) setCrossword(tmpCW);
                                }}
                            >
                                +
                            </div>
                        )}
                    {[
                        CROSSWORD_BLOCK,
                        DRAG_ARRANGE_LIST_BLOCK,
                        COMPARE_ARRANGE_LIST_BLOCK,
                        COMPARE_DIGITS_BLOCK,
                        SCRATCH_ARRANGE_LIST_BLOCK,
                        DRAG_EXPAND_NUMBER_BLOCK,
                    ].includes(blockType) &&
                        !disableWrite && (
                            <Space>
                                <Popconfirm
                                    title={"Delete this row?"}
                                    disabled={disableWrite}
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{
                                                color: "red",
                                            }}
                                        />
                                    }
                                    onConfirm={() => {
                                        let tmpCW = cloneDeep(crossword);
                                        const tempData = tmpCW?.options;

                                        tempData.splice(i, 1);
                                        tmpCW = set(
                                            tmpCW,
                                            ["options"],
                                            tempData,
                                        );

                                        const tempFB = [...feedbacks]
                                            .filter((item) => item.index !== i)
                                            .map((item) => ({
                                                ...item,
                                                index:
                                                    item.index > i
                                                        ? item.index - 1
                                                        : item.index,
                                            }));

                                        if (setCrossword)
                                            setCrossword(tmpCW, tempFB);
                                    }}
                                    okText={"Yes"}
                                    cancelText={"Cancel"}
                                >
                                    <Button
                                        size="small"
                                        shape="circle"
                                        danger
                                        icon={
                                            <DeleteOutlined
                                                style={{
                                                    color: "red",
                                                }}
                                            />
                                        }
                                    />
                                </Popconfirm>

                                <Popconfirm
                                    title={"Add a row below?"}
                                    disabled={disableWrite}
                                    onConfirm={() => {
                                        let tmpCW = cloneDeep(crossword);
                                        const tempData = tmpCW?.options;
                                        tempData.splice(i + 1, 0, [
                                            insertInputCell(),
                                        ]);

                                        tmpCW = set(
                                            tmpCW,
                                            ["options"],
                                            tempData,
                                        );

                                        const tempFB = [...feedbacks].map(
                                            (item) => ({
                                                ...item,
                                                index:
                                                    item.index > i
                                                        ? item.index + 1
                                                        : item.index,
                                            }),
                                        );

                                        if (setCrossword)
                                            setCrossword(tmpCW, tempFB);
                                    }}
                                    okText={"Yes"}
                                    cancelText={"Cancel"}
                                >
                                    <Button
                                        size="small"
                                        shape="circle"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                    />
                                </Popconfirm>
                            </Space>
                        )}
                </>
            )}

            {row?.type === "input_variable" && isMultiRow && (
                <>
                    <div
                        style={{
                            border: "1px dashed #000000",
                            minWidth: "56px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexShrink: 0,
                            padding: "10px",
                        }}
                    >
                        <div>
                            <h4>Input Variable</h4>
                            <Select
                                disabled={disableWrite}
                                defaultValue={row?.value}
                                style={{
                                    minWidth: 200,
                                }}
                                dropdownStyle={{
                                    minWidth: 200,
                                }}
                                placeholder="Input Variable"
                                onChange={(value: any) => {
                                    let tmpBlock = cloneDeep(crossword);

                                    const tempData = tmpBlock?.options;

                                    tempData[i].value = value;

                                    tmpBlock = set(
                                        tmpBlock,
                                        ["options"],
                                        tempData,
                                    );

                                    setCrossword(tmpBlock);
                                    setCurMention(value);
                                }}
                                options={mentionsList.map(
                                    (item: { text: any }) => ({
                                        value: item.text,
                                        label: item.text,
                                    }),
                                )}
                            />
                        </div>
                    </div>
                    {!disableWrite && (
                        <Space>
                            <Popconfirm
                                title={"Delete this row?"}
                                disabled={disableWrite}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: "red",
                                        }}
                                    />
                                }
                                onConfirm={() => {
                                    let tmpCW = cloneDeep(crossword);
                                    const tempData = tmpCW?.options;

                                    tempData.splice(i, 1);
                                    tmpCW = set(tmpCW, ["options"], tempData);

                                    const tempFB = [...feedbacks]
                                        .filter((item) => item.index !== i)
                                        .map((item) => ({
                                            ...item,
                                            index:
                                                item.index > i
                                                    ? item.index - 1
                                                    : item.index,
                                        }));

                                    if (setCrossword)
                                        setCrossword(tmpCW, tempFB);
                                }}
                                okText={"Yes"}
                                cancelText={"Cancel"}
                            >
                                <Button
                                    size="small"
                                    shape="circle"
                                    danger
                                    icon={
                                        <DeleteOutlined
                                            style={{
                                                color: "red",
                                            }}
                                        />
                                    }
                                />
                            </Popconfirm>
                        </Space>
                    )}
                </>
            )}

            <Space>
                {showFeedback && (
                    <Button onClick={() => showFeedbackModal(i)}>
                        {feedbacks.some(
                            (fb: { index: number; values: any[] }) =>
                                fb.index === i && fb.values?.length > 0,
                        )
                            ? "Edit"
                            : "Add"}{" "}
                        Feedback
                    </Button>
                )}
            </Space>
        </div>
    );

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        const newOptions = arrayMoveImmutable(
            options,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        let tmpCW = cloneDeep(crossword);

        tmpCW = _.set(tmpCW, ["options"], newOptions);

        let tempFB = null;
        if (oldIndex !== newIndex) {
            tempFB = feedbackUpdate(oldIndex, newIndex, feedbacks);
        }
        if (setCrossword) setCrossword(tmpCW, tempFB);
    };

    return (
        <>
            <CellButtonInput {...cellButtonInputProps} />
            <FeedbackField {...feedbackFieldProps} />
            <h3
                style={{
                    fontWeight: "bold",
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
            >
                Inputs
            </h3>
            <Col span={24}>
                <SortableContainerCustom onSortEnd={onSortEnd} useDragHandle>
                    {options.map((option: any, idx: number) => (
                        <SortableItemCustom
                            {...{ option }}
                            idx={idx}
                            key={`item-${idx}`}
                            index={idx}
                        />
                    ))}
                </SortableContainerCustom>

                {isMultiRow && !disableWrite && (
                    <Space
                        size="large"
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => {
                                let tmpCW = cloneDeep(crossword);
                                if (tmpCW.options) {
                                    tmpCW.options.push([insertInputCell()]);
                                } else {
                                    tmpCW.options = [[insertInputCell()]];
                                }

                                if (setCrossword) setCrossword(tmpCW);
                            }}
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            Add Row
                        </Button>
                        {hasMentions &&
                            mentionsList?.length > 0 &&
                            [
                                DRAG_ARRANGE_LIST_BLOCK,
                                COMPARE_ARRANGE_LIST_BLOCK,
                                COMPARE_DIGITS_BLOCK,
                                SCRATCH_ARRANGE_LIST_BLOCK,
                            ].includes(blockType) && (
                                <div
                                    style={{
                                        border: "1px dashed #a1a1a1",
                                        padding: "5px 10px",
                                    }}
                                >
                                    <h4>Add Input Variable as Row</h4>
                                    <Space>
                                        <Select
                                            defaultValue={currentMention}
                                            style={{
                                                minWidth: 200,
                                            }}
                                            dropdownStyle={{
                                                minWidth: 200,
                                            }}
                                            placeholder="Input Variable"
                                            onChange={(value: any) => {
                                                setCurMention(value);
                                            }}
                                            options={mentionsList.map(
                                                (item: { text: any }) => ({
                                                    value: item.text,
                                                    label: item.text,
                                                }),
                                            )}
                                        />
                                        <Button
                                            type="primary"
                                            ghost
                                            onClick={() => {
                                                let tmpCW =
                                                    cloneDeep(crossword);

                                                const option = {
                                                    type: "input_variable",
                                                    value: currentMention,
                                                };
                                                if (tmpCW.options) {
                                                    tmpCW.options.push(option);
                                                } else {
                                                    tmpCW.options = [option];
                                                }

                                                if (setCrossword)
                                                    setCrossword(tmpCW);
                                            }}
                                        >
                                            Add
                                        </Button>
                                    </Space>
                                </div>
                            )}
                    </Space>
                )}
            </Col>
        </>
    );
};

export default GridOptions;
