import {
    AppstoreAddOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    MenuOutlined,
} from "@ant-design/icons";
import EditIcon from "src/components/EditIcon";
import ViewIcon from "src/components/ViewIcon";
import DeleteView from "src/modules/book/components/DeleteView";
import { Can } from "src/services/casl";
import { Button, Col, Divider, Image, Row, Space, Spin, Table } from "antd";
import { arrayMoveImmutable } from "array-move";
import { Link } from "src/components";
import { useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { CopyBookToTab } from "../../../components/CopyBookToTab";
import { ExistingBook, getBookItem } from "src/modules/tab/components/";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

export const ListView = ({
    loading,
    tabs,
    tabId,
    updateGroupTabMap,
    redirect_to,
    books,
    refetch,
}) => {
    const [dataSource, setDataSource] = useState([]);
    const [tabTitle, setTabTitle] = useState("");

    const tab = tabs.filter(({ id }: any) => id == tabId)[0];

    useEffect(() => {
        // if (books.length) {
        setTabTitle(tabs && tabs.length && tab?.title);
        // console.log(books);
        setDataSource(
            books
                .map((c, index) => {
                    return {
                        ...c,
                        index,
                        order: c.order,
                    };
                })
                .sort((a, b) => {
                    if (a.status === b.status) return a.order - b.order;
                    else return a.status > b.status ? 1 : -1;
                }),
        );
        // }
    }, [books, tabId]);

    const columns = [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => <DragHandle />,
        },
        {
            title: "Cover",
            key: "cover",
            render: (text, record) => (
                <Image src={convertS3UrlToGcp(record?.cover)} height={150} />
            ),
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
        },
        {
            title: "Class",
            key: "class",
            render: (text, record) => record?.other.class,
        },
        {
            title: "Subject",
            key: "subject",
            render: (text, record) => record?.other.subject,
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) =>
                record?.status != "inactive" ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                ),
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => (
                <Space size="middle">
                    {["document_content", "worksheet_content"].includes(
                        tab.type,
                    ) && (
                        <Link
                            href={{
                                pathname: `/tab/chapter/${record?.id}`,
                                query: {
                                    tabType: tab.type,
                                },
                            }}
                        >
                            <ViewIcon isButton={true} />
                        </Link>
                    )}

                    {["set_content"].includes(tab.type) && (
                        <Link
                            href={{
                                pathname: `/book/chapter/${record?.id}`,
                            }}
                        >
                            <ViewIcon isButton={true} />
                        </Link>
                    )}
                    {["cta"].includes(tab.type) &&
                        record?.other?.view_link_base && (
                            <a
                                href={`${record?.other?.view_link_base}${record?.other?.remote_id}`}
                                target={"_blank"}
                            >
                                <ViewIcon isButton={true} />
                            </a>
                        )}
                    <Can I="edit" a={"tab_book"} passThrough>
                        {(allowed) => (
                            <Link
                                href={{
                                    pathname: `/tab/book/update/${record?.id}`,
                                    query: {
                                        redirect_to: encodeURI(redirect_to),
                                        tabType: tab.type,
                                    },
                                }}
                            >
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    <Can I="edit" a={"tab_book"} passThrough>
                        {(allowed) =>
                            allowed && tab.type == "worksheet_content" ? (
                                <CopyBookToTab
                                    tab_id={tabId}
                                    book_id={record?.id}
                                />
                            ) : null
                        }
                    </Can>

                    <Can I="delete" a={"tab_book"} passThrough>
                        {(allowed) =>
                            allowed && (
                                <DeleteView
                                    id={record?.id}
                                    isButton={true}
                                    disabled={!allowed}
                                    afterDelete={refetch}
                                />
                            )
                        }
                    </Can>
                </Space>
            ),
        },
    ];

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
    ));

    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(
                [].concat(dataSource),
                oldIndex,
                newIndex,
            ).filter((el) => !!el);

            newData.map((d, index) => {
                if (d.order !== index + 1) {
                    console.log(
                        "Updating order: ",
                        d.group_tab_map_id,
                        index + 1,
                    );
                    updateGroupTabMap({
                        id: d.group_tab_map_id,
                        _set: { order: index + 1 },
                    });

                    // newData[index].order = index;
                }
            });

            setDataSource(newData);
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helper="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"],
        );
        return <SortableItem index={index} {...restProps} />;
    };
    return (
        <>
            {tabId && (
                <Row justify="space-between" align="middle">
                    <Col>
                        <h1>
                            Books for {tabTitle} ({dataSource.length})
                        </h1>
                    </Col>

                    <Col>
                        {!loading && (
                            <>
                                <Can I="create" a={"tab_book"}>
                                    {(allowed) => (
                                        <Can
                                            I="delete"
                                            a={"tab_book"}
                                            passThrough
                                        >
                                            {(deleteAllowed) => (
                                                <ExistingBook
                                                    disabled={!allowed}
                                                    initialData={books.map(
                                                        getBookItem,
                                                    )}
                                                    oneWay={!deleteAllowed}
                                                    callback={refetch}
                                                />
                                            )}
                                        </Can>
                                    )}
                                </Can>
                                <Divider type="vertical" />
                            </>
                        )}

                        <Can I="create" a={"tab_book"}>
                            {(allowed) => (
                                <Link
                                    href={{
                                        pathname: `/tab/book/create/${tabId}`,
                                        query: {
                                            redirect_to,
                                            order: books.length + 1,
                                            tabType: tab.type,
                                        },
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        icon={<AppstoreAddOutlined />}
                                        disabled={!allowed}
                                    >
                                        Add Book
                                    </Button>
                                </Link>
                            )}
                        </Can>
                    </Col>
                </Row>
            )}
            <hr />
            <Spin spinning={loading}>
                {Boolean(tabs?.length) && (
                    <Table
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                    />
                )}
            </Spin>
        </>
    );
};
