import { Button, Card, Col, Form, Input, message, Row } from "antd";
import React from "react";
import { deepOmit } from "../../ProgressLevel/renderEditor/helper";
import { useWorksheetState } from "src/modules/worksheet/components/contexts/WorksheetContext";
import _ from "lodash";
import FigmaStoryEditor from "../../Figma/renderEditor/FigmaStoryEditor";
import isValidCallback from "../isValid";

type Props = {};

function MultiplayerQuestionColumn(props) {
    const { worksheet, block, disableWrite, setBlock, previewData } = props;
    const { publishedBlocksMap = {} }: any = useWorksheetState();

    const {
        id,
        data: {
            other: { stories = [] },
        },
    } = block;

    const figmaStoryEditorProps = {
        ...props,
        disableWrite:
            disableWrite ||
            (!block?.id && worksheet?.type !== "personalized_learning_v4"),
        stories,
        storiesPath: [id || block.data?.storiesId, "data", "other", "stories"],
        setStories: (data: any, storiesId: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(tmpBlock, ["data", "other", "stories"], data);
            if (storiesId)
                tmpBlock = _.set(tmpBlock, ["data", "storiesId"], storiesId);
            setBlock(tmpBlock);
        },
        previewData: block?.id
            ? {
                  ...previewData,
                  variant_id: block?.id,
                  block_id: block?.id,
              }
            : null,
    };

    return (
        <Card>
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    {worksheet?.type === "personalized_learning_v4" &&
                        block?.id && (
                            <Form.Item label="Block Id">
                                <Input.Search
                                    value={block.id}
                                    onChange={() => {}}
                                    enterButton="Copy"
                                    size="small"
                                    onSearch={() => {
                                        message.info(`Copied Id to clipboard!`);
                                        navigator.clipboard.writeText(block.id);
                                    }}
                                />
                            </Form.Item>
                        )}
                </Col>
                <Col span={12}>
                    {worksheet?.type === "personalized_learning_v4" &&
                        block?.id && (
                            <Form.Item label="Published Block Id">
                                {publishedBlocksMap[block.id] ? (
                                    <Input.Search
                                        value={publishedBlocksMap[block.id]}
                                        onChange={() => {}}
                                        enterButton="Copy"
                                        size="small"
                                        onSearch={() => {
                                            message.info(
                                                `Copied Id to clipboard!`,
                                            );
                                            navigator.clipboard.writeText(
                                                block.id,
                                            );
                                        }}
                                    />
                                ) : (
                                    <span>Not published Yet</span>
                                )}
                            </Form.Item>
                        )}
                </Col>
                <Col span={12}>
                    <Form.Item label="Slug">
                        <Input
                            value={block?.backend?.slug}
                            disabled={disableWrite}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    worksheet?.type ===
                                        "personalized_learning_v4"
                                        ? ["backend", "slug"]
                                        : ["data", "slug"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item>
                        <Button
                            disabled={disableWrite}
                            type="primary"
                            size="small"
                            onClick={() => {
                                let tmpBlock = deepOmit(block, [
                                    "is_modified",
                                    "changes_list",
                                    "master_block",
                                ]);

                                navigator.clipboard.writeText(
                                    JSON.stringify(tmpBlock),
                                );
                                message.info(`Copied Question to clipboard!`);
                            }}
                        >
                            Copy the Question to Clipboard
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item>
                        <Button
                            disabled={disableWrite}
                            type="primary"
                            size="small"
                            onClick={() => {
                                navigator.clipboard
                                    .readText()
                                    .then((text) => {
                                        const parsedData = JSON.parse(text);
                                        if (
                                            isValidCallback({
                                                block: parsedData,
                                                worksheet,
                                            }).isValid
                                        ) {
                                            setBlock(parsedData);
                                        }
                                    })
                                    .catch((err) => {
                                        console.error(
                                            "Failed to read clipboard contents: ",
                                            err,
                                        );
                                    });
                            }}
                        >
                            Paste the Question
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={<h2>Stories</h2>}
                        labelCol={{
                            span: 24,
                        }}
                    >
                        <FigmaStoryEditor {...figmaStoryEditorProps} />
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
}

export default MultiplayerQuestionColumn;
