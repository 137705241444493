import React, { useState } from "react";
import { setNodes, unsetNodes } from "@udecode/plate-core";
import { Transforms } from "slate";
import { getRootProps } from "@udecode/plate-styled-components";

import { ReactEditor, useFocused, useSelected } from "slate-react";
import { AudioInsertComponent } from "../AudioInsertComponent";
import MathRender from "src/components/MathRender";
import ReactPlayer from "react-player";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

export const AudioElement = (props: any) => {
    const { attributes, children, element, nodeProps, editor } = props;

    const rootProps = getRootProps(props);

    const { audio: audiosrc, config } = element;
    const audio = convertS3UrlToGcp(audiosrc);

    const focused = useFocused();
    const selected = useSelected();

    const onChangeAudio = (val: string) => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        // console.log(path);
        setNodes(editor, val, { at: path });
        // Transforms.insertText(editor, "some words", {
        //   at: { path: path, offset: 3 },
        // });
    };

    const onDeleteAudio = () => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        // console.log(path);
        Transforms.delete(editor, { at: path });
    };

    return (
        <div {...attributes} {...rootProps} {...nodeProps}>
            <div
                contentEditable={false}
                // style={{
                //   userSelect: "none",
                //   border: selected ? "1px solid #ACCEF7" : "none",
                // }}
            >
                {selected || !audio ? (
                    <AudioInsertComponent
                        onChange={onChangeAudio}
                        onDelete={onDeleteAudio}
                        value={audio}
                        config={config}
                    />
                ) : (
                    <div>
                        <ReactPlayer
                            url={convertS3UrlToGcp(audio)}
                            controls
                            file={{ forceAudio: true }}
                            height={"60px"}
                        />
                    </div>
                )}
            </div>
            {children}
        </div>
    );
};
