import { PlusOutlined } from "@ant-design/icons";
import CancelIcon from "src/components/CancelIcon";
import { getPresignedURL } from "src/components/form/RenderUpload";
import { Form, message, Modal, Upload } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { captureException } from "@sentry/react";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const FormItem = Form.Item;

export class ImageUploadComponent extends React.Component {
    constructor(props: any) {
        super(props);

        // To Do Fix this thing
        this.state = {
            previewVisible: false,
            previewImage: "",
            fileList: props.value
                ? [
                      {
                          uid: "-1",
                          name: "image.png",
                          status: "done",
                          url: props.value,
                      },
                  ]
                : [],
        };
    }

    onChangeHandler = ({ file, fileList }: any) => {
        // console.log(file.status);
        // console.log(fileList);
        // console.log(file.response.secure_url);
        // const arrayHelpers = this.props.arrayHelpers;

        if (file.status === "uploading") {
            // this.props.setload(true);
        }

        if (file.status == "done") {
            // this.props.setload(false);
        } else if (file.status == "removed") {
            // this.props.setload(false);
            // console.log(file);
            //remove value in form
            this.props?.input?.onChange("");
        }
        this.setState({ fileList });
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    };

    handleChange = ({ fileList }: any) => this.setState({ fileList });

    handleUpload = async ({ onSuccess, onError, file }: any) => {
        try {
            const xhr = new XMLHttpRequest();
            const { url, bucket_url } = await getPresignedURL(
                this.props.path || "editor/",
                "",
                file?.type || "application/octet-stream",
            );

            // S3 requires PUT method!
            const contentType = file?.type || "application/octet-stream";
            xhr.open("PUT", url);
            xhr.setRequestHeader("Content-Type", contentType);

            xhr.onreadystatechange = async () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Calls the update prop
                        if (this.props.onChange)
                            this.props.onChange({
                                target: {
                                    value: bucket_url,
                                    name: this.props.name,
                                },
                            });

                        onSuccess(null, file);
                    } else {
                        onError(xhr.responseText, xhr.response, file);
                    }
                }
            };
            xhr.send(file);
        } catch (e) {
            captureException(e);
            console.log("Error:", e);
            message.error("Something went wrong!");
            onError("Something went wrong!", e, file);
        }
    };

    render() {
        const children = this.props.children;
        const label = this.props.label;

        const {
            previewVisible,
            previewImage,
            icon = "UploadOutlined",
            fileList,
        } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <Form layout="inline">
                <FormItem
                    // label={
                    //   <Space align="center">
                    //     {icon && <UploadOutlined />}
                    //     {label}
                    //   </Space>
                    // }
                    // validateStatus={validateStatus}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <div>
                        <Upload
                            customRequest={this.handleUpload}
                            listType={!children && "picture-card"}
                            fileList={!children && fileList}
                            onPreview={this.handlePreview}
                            onChange={this.onChangeHandler}
                            accept="image/png, image/gif, image/jpeg, image/webp"
                        >
                            {children
                                ? children
                                : fileList.length >= 1
                                ? null
                                : uploadButton}
                        </Upload>
                        {!children && (
                            <Modal
                                visible={previewVisible}
                                footer={null}
                                onCancel={this.handleCancel}
                            >
                                <img
                                    alt="image"
                                    style={{ width: "100%" }}
                                    src={convertS3UrlToGcp(previewImage)}
                                />
                            </Modal>
                        )}
                    </div>
                </FormItem>
                <FormItem className="latex-insert-form-item">
                    <CancelIcon
                        isButton={true}
                        onClick={() => {
                            this.props.onDelete();
                        }}
                    />
                </FormItem>
            </Form>
        );
    }
}
ImageUploadComponent.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    //   setload: PropTypes.func,
    onChange: PropTypes.func,
    name: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.node,
};
