import React from "react";

const ImageComponent = ({ imageObj }) => {
    const { src } = imageObj;
    return (
        <div className="renderer-table-image-wrapper">
            <div className="renderer-table-image-item">
                <img
                    width="50px"
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                    }}
                    src={convertS3UrlToGcp(src?.default ?? src?.value)}
                    alt=""
                />
            </div>
        </div>
    );
};

export default ImageComponent;
