import React, { useMemo } from "react";
import { renderer } from "src/modules/worksheet/components/blocks/helpers";
import DrawingComponent from "./DrawingComponent";
import { Grid } from "../Puzzle/PuzzleTagPreview";
const textStyle = {
    color: "#000",
    textAlign: "center",
    fontFamily: "Epilogue",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%", // This will compute to 24px if the font-size is 16px
};
const DroppedItem = ({
    droppedSource,
    customStyles,
    shapeStyles,
    isDrawing,
    tagSelector,
}) => {
    const textValue = droppedSource.props?.text.value;
    const isPuzzlePiece = droppedSource.isPuzzlePiece;

    const dragStyle = {
        // transform: CSS.Translate.toString(transform),
        // zIndex: isDragging ? 3 : 2,
    };

    const background = useMemo(() => {
        return droppedSource.props?.input_background?.value?.color;
    }, [droppedSource]);
    const imageUrl = useMemo(() => {
        if (droppedSource.props?.input_background?.value) {
            return droppedSource.props?.input_background?.value?.asset;
        }
        return null;
    }, [droppedSource]);

    const drawingSpecificStyles = useMemo(() => {
        if (isDrawing) {
            return {
                border: "none",
                backgroundColor: "transparent",
                boxShadow: "none",
            };
        } else {
            return {};
        }
    }, [isDrawing]);

    if (isPuzzlePiece) {
        const gridProps = {
            id: droppedSource.genericId,
        };

        const droppedSourceCellsFirstCell =
            droppedSource.cells && droppedSource.cells[0][0];
        const droppedSourceCellsFirstCellDrawing =
            droppedSourceCellsFirstCell?.content?.drawing;
        const droppedSourceCellsFirstCellDrawingPosition =
            droppedSourceCellsFirstCellDrawing?.position;
        const drawingStyles = isDrawing
            ? {
                  height: "fit-content",
                  width: "fit-content",
                  top: -droppedSourceCellsFirstCellDrawingPosition?.y,
                  left: -droppedSourceCellsFirstCellDrawingPosition?.x,
                  zIndex: 10,
              }
            : {
                  left: -0,
              };

        return (
            <div
                style={{
                    ...(tagSelector
                        ? {}
                        : {
                              position: "absolute",
                              left: 0,
                              top: 0,
                          }),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    ...customStyles,
                    ...drawingStyles,
                    pointerEvents: "none",
                    // Include any other necessary styles
                }}
            >
                {isDrawing ? (
                    <DrawingComponent
                        tableChildProps={{
                            tableData: droppedSource,
                            clusterIndex: 0,
                            componentIndex: 0,
                        }}
                        path={droppedSource.genericId}
                        isPuzzlePiece={isPuzzlePiece}
                        puzzlePieceProps={gridProps}
                        coordinatesList={[]}
                        tableBorderFeedback={false}
                    />
                ) : (
                    <Grid
                        gridData={droppedSource.cells}
                        gridProps={gridProps}
                        coordinatesList={[]}
                        clusterIndex={0}
                        componentIndex={0}
                        tableBorderFeedback={false}
                    />
                )}
            </div>
        );
    }

    return (
        <div
            id={droppedSource.genericId}
            className="renderer-table-dropped-item-wrapper"
            style={{
                backgroundColor: background,
                backgroundImage: imageUrl
                    ? `url(${convertS3UrlToGcp(imageUrl)})`
                    : "none",
                backgroundSize: "56px",
                position: "relative",
                zIndex: 2,
                ...dragStyle,
                ...customStyles,
                // ...(shapeStyles || {}),
                border: isDrawing ? "none" : "1px solid #000",
                ...drawingSpecificStyles,
            }}
        >
            {textValue !== "" && textValue && textValue?.length > 0 && (
                <p
                    style={{
                        marginBlockEnd: 0,
                    }}
                >
                    {renderer(textValue, {
                        textStyle: textStyle,
                    })}
                </p>
            )}
        </div>
    );
};

export default DroppedItem;
