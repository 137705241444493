export const BLOCK_TYPES = {
    TEXT_BLOCK: "text",
    IMAGE_BLOCK: "image",
    MCQ_BLOCK: "mcq",
    CASE_STUDY_BLOCK: "case_study",
    SUBJECTIVE_BLOCK: "subjective",
    VIDEO_BLOCK: "video",
    RE_ORDER: "re_order",
};

const { SUBJECTIVE_BLOCK, MCQ_BLOCK, CASE_STUDY_BLOCK, RE_ORDER } = BLOCK_TYPES;
export const WORKSHEET_QUESTION_TYPES = [
    MCQ_BLOCK,
    SUBJECTIVE_BLOCK,
    CASE_STUDY_BLOCK,
    RE_ORDER,
];

export const parseCMSJson = (obj: any) => {
    if (!obj) return ``;

    const convertDecorationToLatex = ({ text }: any) => {
        return text;
    };

    if (typeof obj === "string") return obj;
    const returnObj = obj
        .map(function (o: any) {
            const { type, children, latex, url } = o;
            if (type === "p") {
                const parsedText = children
                    .map(function (p: any) {
                        return convertDecorationToLatex(p);
                    })
                    .join(" ");

                return parsedText;
            } else if (type === "img") {
                return ``;
            } else if (type === "latex") {
                return latex;
            }
        })
        .join(" ");
    return returnObj.trim();
};

const getDataAsText = (block: any) => {
    const { id, data, type } = block;
    let parsedData = data;

    if (type == SUBJECTIVE_BLOCK) {
        const { text, solution } = data[SUBJECTIVE_BLOCK];
        parsedData = `${parseCMSJson(text)} ${parseCMSJson(solution)}`;
    } else if (type == MCQ_BLOCK) {
        const { text, options, correct_options, solution } = data[MCQ_BLOCK];
        parsedData = `${parseCMSJson(text)} ${options.reduce(
            (acc: any, option: any) => `${acc} ${parseCMSJson(option)}`,
            ``,
        )} ${parseCMSJson(solution)}`;
    } else if (type == CASE_STUDY_BLOCK) {
        const { text } = data[CASE_STUDY_BLOCK];
        parsedData = `${parseCMSJson(text)}`;
    } else if (type == RE_ORDER) {
        const { text, options, correct_options, solution } = data[MCQ_BLOCK];
        parsedData = `${parseCMSJson(text)} ${options.reduce(
            (acc: any, option: any) => `${acc} ${parseCMSJson(option)}`,
            ``,
        )} ${parseCMSJson(solution)}`;
    }

    return parsedData || ``;
};

export const findBlockByFuzzySearch = (blocks: any, text: string) => {
    return blocks
        .map((block: any) => {
            const { type } = block;
            if (WORKSHEET_QUESTION_TYPES.includes(type)) {
                const data_as_text = getDataAsText(block);
                return {
                    ...block,
                    data_as_text,
                };
            }
            return {
                ...block,
                data_as_text: ``,
            };
        })
        .findIndex(({ data_as_text }: any) => data_as_text == text);
};

export function convertS3UrlToGcp(url: string): string {
    if (!url) return url;
    return url?.replace(
        /^https:\/\/([^.]+)\.s3\.[^.]+\.amazonaws\.com\//,
        // "https://cdn.homeworkapp.ai/$1/",
        "https://storage.googleapis.com/$1/",
    );
}
