import { v4 as uuid } from "uuid";
import { BLOCK_TYPES } from "../../../common/index";
const { V2_STORIES_BLOCK, ALL_IN_ONE, PROGRESS_LEVEL_BLOCK } = BLOCK_TYPES;

export function deepOmit<T>(obj: T, keysToOmit: string[]): T {
    if (Array.isArray(obj)) {
        return obj.map((item) => deepOmit(item, keysToOmit)) as unknown as T;
    } else if (typeof obj === "object" && obj !== null) {
        return Object.keys(obj).reduce((acc, key) => {
            if (!keysToOmit.includes(key)) {
                (acc as any)[key] = deepOmit((obj as any)[key], keysToOmit);
            }
            return acc;
        }, {} as T);
    }
    return obj;
}

export const convertStoriesToV4 = (block: {
    data: {
        [x: string]: {
            logic: any;
            name: string;
            sub_title: string;
            banner: string;
            dev_remarks: any;
        };
        other: any;
        children: any;
        slug: any;
    };
    backend: any;
}) => {
    const { data, backend, ...otherProps } = block;
    return {
        ...otherProps,
        type: V2_STORIES_BLOCK,
        data: {
            [V2_STORIES_BLOCK]: {
                name: data?.[V2_STORIES_BLOCK]?.name,
                sub_title: data?.[V2_STORIES_BLOCK]?.sub_title,
                banner: data?.[V2_STORIES_BLOCK]?.banner,
                dev_remarks: data?.[V2_STORIES_BLOCK]?.dev_remarks,
            },
            other: data?.other || {
                duration: 0,
                audio: "",
                job: { id: "", last_updated: null, name: "" },
            },
            children: block?.data?.children || [],
        },
        backend: {
            slug: data?.slug,
            ...(data?.[V2_STORIES_BLOCK]?.logic || {}),
            ...(backend || {}),
        },
    };
};

export const convertStoriesToV3 = (block: {
    data: { [x: string]: any; slug: any; other: any; children: any };
    backend: { slug: any; compute_functions: any };
}) => {
    const { data, backend, ...otherProps } = block;
    return {
        ...otherProps,
        type: V2_STORIES_BLOCK,
        data: {
            slug: backend?.slug || data?.slug || "",
            [V2_STORIES_BLOCK]: {
                logic: { compute_functions: backend?.compute_functions },
                ...(data?.[V2_STORIES_BLOCK] || {}),
            },
            other: data?.other || {
                duration: 0,
                audio: "",
                difficulty_level: "medium",
                job: { id: "", last_updated: null, name: "" },
            },
            children: block?.data?.children || [],
        },
    };
};

export const convertAllInOneBlockToV4 = (block: {
    [x: string]: any;
    data: any;
    backend: any;
}) => {
    const { data, backend, ...otherProps } = block;
    if (!data[ALL_IN_ONE]) data[ALL_IN_ONE] = { logic: {} };

    const {
        [ALL_IN_ONE]: {
            badge_count,
            question_variables,
            question_derived_variables,
            logic: { compute_functions, ...otherLogic },
            ...otherdata
        },
    } = data;
    return {
        ...otherProps,
        type: ALL_IN_ONE,
        data: {
            [ALL_IN_ONE]: {
                ...otherdata,
                logic: otherLogic,
            },
            other: data?.other,
        },
        backend: {
            slug: data?.slug,
            badge_count,
            question_derived_variables,
            question_variables,
            compute_functions,
            ...(backend || {}),
        },
    };
};

export const convertAllInOneBlockToV3 = (block: {
    data: { [x: string]: any; slug: any; other: any; children: any };
    backend: { slug: any; compute_functions: any };
}) => {
    const { data, backend = {}, ...otherProps }: any = block;
    const { slug, compute_functions, ...otherBackendProps } = backend;
    return {
        ...otherProps,
        type: ALL_IN_ONE,
        data: {
            slug: data?.slug || slug || "",
            [ALL_IN_ONE]: {
                ...(data?.[ALL_IN_ONE] || {}),
                logic: {
                    compute_functions,
                    ...(data?.[ALL_IN_ONE]?.logic || {}),
                },
                ...(otherBackendProps || {}),
            },
            other: data?.other || {
                duration: 0,
                audio: "",
                difficulty_level: "medium",
                job: { id: "", last_updated: null, name: "" },
            },
        },
    };
};

export const convertChunkToV4 = (block: {
    data: {
        [x: string]: {
            logic: any;
            name: string;
            sub_title: string;
            banner: string;
            dev_remarks: any;
        };
        other: any;
        children: any;
        slug: any;
    };
    backend: any;
    children: any;
}) => {
    const { data, backend, children = [], ...otherProps }: any = block;
    const {
        chunk: { is_variant, compute_functions, blocks = [], ...otherData },
    } = data;
    return {
        children: children?.length
            ? children
            : blocks?.map((v: any) => {
                  let newBlock = v;
                  if (v?.type === V2_STORIES_BLOCK) {
                      newBlock = convertStoriesToV4(newBlock);
                  } else if (v?.type === ALL_IN_ONE) {
                      newBlock = convertAllInOneBlockToV4(newBlock);
                  }
                  delete newBlock.id;
                  return newBlock;
              }),
        ...otherProps,
        type: "chunk",
        data: {
            chunk: otherData,
        },
        backend: {
            slug: data?.slug,
            is_variant,
            compute_functions,
            ...(backend || {}),
        },
    };
};

export const convertChunkToV3 = (block: {
    data: { [x: string]: any; slug: any; other: any; children: any };
    backend: { slug: any; compute_functions: any; is_variant: boolean };
    children: any;
}) => {
    const { data, backend, children = [], ...otherProps } = block;
    return {
        ...otherProps,
        data: {
            slug: data?.slug || backend?.slug || "",
            chunk: {
                is_variant: backend?.is_variant,
                compute_functions: backend?.compute_functions,
                blocks: children?.map((v: any) => {
                    let newBlock = v;
                    if (v?.type === V2_STORIES_BLOCK) {
                        newBlock = convertStoriesToV3(newBlock);
                    } else if (v?.type === ALL_IN_ONE) {
                        newBlock = convertAllInOneBlockToV3(newBlock);
                    }
                    newBlock.id = uuid();
                    return newBlock;
                }),
                ...(data?.chunk || {}),
            },
        },
    };
};

export const convertLevelToV4 = (block: {
    data: {
        [x: string]: {
            logic: any;
            name: string;
            sub_title: string;
            banner: string;
            dev_remarks: any;
        };
        other: any;
        children: any;
        slug: any;
    };
    backend: any;
    children: any;
}) => {
    const { data, backend = {}, children = [], ...otherProps }: any = block;
    const {
        [PROGRESS_LEVEL_BLOCK]: {
            global_context_variables,
            compute_functions,
            passing_badge_count,
            ...otherData
        },
    } = data;
    return {
        ...otherProps,
        children: children?.map((v: any) => convertChunkToV4(v)),
        data: {
            [PROGRESS_LEVEL_BLOCK]: otherData,
        },
        backend: {
            slug: data?.slug,
            global_context_variables,
            compute_functions,
            passing_badge_count,
            ...(backend || {}),
        },
    };
};

export const convertLevelToV3 = (block: {
    data: { [x: string]: any; slug: any; other: any; children: any };
    backend: { slug: any; compute_functions: any; is_variant: boolean };
    children: any;
}) => {
    const {
        data = {},
        backend = {},
        children = [],
        ...otherProps
    }: any = block;
    const { slug, ...otherBackendProps } = backend;
    return {
        ...otherProps,
        children: children?.map((v: any) => convertChunkToV3(v)),
        data: {
            slug: data?.slug || slug || "",
            [PROGRESS_LEVEL_BLOCK]: {
                ...(otherBackendProps || {}),
                ...(data?.[PROGRESS_LEVEL_BLOCK] || {}),
            },
        },
    };
};
