import {
    QuestionCircleOutlined,
    DeleteOutlined,
    PlusOutlined,
    EditOutlined,
} from "@ant-design/icons";
import { Space, Popconfirm, Button, Col, Table } from "antd";
import { cloneDeep, set } from "lodash";
import { useState } from "react";
import CellInput from "./CellInput";
import { BLOCK_TYPES } from "..";
import { DEFAULT_DRAG_AND_SWIPE_CELL } from "../../renderers/DragAndSwipe/defaultValue";
import { DEFAULT_CROSSWORD_CELL } from "../../renderers/Crossword/defaultValue";
import { DEFAULT_SWIPE_AND_FORM_CELL } from "../../renderers/SwipeAndForm/defaultValue";
import FeedbackField from "../FeedBackField";
import { DEFAULT_V2_DRAG_AND_SWIPE_CELL } from "../../renderers/V2DragAndSwipe/defaultValue";
import { DEFAULT_DRAG_AND_TAG_CELL } from "../../renderers/DragAndTag/defaultValue";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const CROSSWORD = { grid: [], options: [] };

const {
    CROSSWORD_BLOCK,
    DRAG_AND_SWIPE_BLOCK,
    SWIPE_AND_FORM_BLOCK,
    DRAG_EXPAND_NUMBER_BLOCK,
    INPUT_EXPAND_NUMBER_BLOCK,
    V2_DRAG_AND_SWIPE_BLOCK,
    DRAG_AND_TAG_BLOCK,
} = BLOCK_TYPES;

const GridTable = (props: any) => {
    const {
        crossword = CROSSWORD,
        setCrossword,
        blockType = CROSSWORD_BLOCK,
        disableWrite,
        grid_feedbacks: feedbacks = [],
        isEditor,
        setIsEditor,
        setFeedback,
        isReadOnlyMode,
    } = props;

    const { grid = [], options = [] } = crossword;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [curCellRow, setCurCellRow] = useState(-1);
    const [curCellCol, setCurCellCol] = useState(-1);
    const [curCell, setCurCell] = useState({});

    const showFeedbackModal = (index: number) => {
        const findCellIndex = feedbacks.findIndex(
            (item: any) => item?.index === index,
        );

        setCurCellRow(
            findCellIndex !== -1 ? findCellIndex : feedbacks?.length || 0,
        );
        setCurCell(
            findCellIndex !== -1
                ? feedbacks[findCellIndex]
                : {
                      index,
                      values: [],
                  },
        );
        setIsFeedbackModalOpen(true);
    };

    const showModal = (row: number, column: number, cell: any) => {
        setCurCellRow(row);
        setCurCellCol(column);
        setCurCell(cell);
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setCurCellRow(-1);
        setCurCellCol(-1);
        setCurCell({});
        setIsModalOpen(false);
        setIsFeedbackModalOpen(false);
    };

    const actionButtons = () => {
        const cell: any = { key: grid[0]?.length + 1 };

        grid[0]?.forEach((__: any, i: number) => {
            cell[i] = (
                <Space>
                    {grid[0]?.length > 1 ? (
                        <Popconfirm
                            title={"Delete this column?"}
                            icon={
                                <QuestionCircleOutlined
                                    style={{ color: "red" }}
                                />
                            }
                            disabled={disableWrite}
                            onConfirm={() => {
                                let tmpCW = cloneDeep(crossword);
                                let tempData = tmpCW?.grid?.map((val: any) =>
                                    val.filter(
                                        (_: any, idx: number) => idx !== i,
                                    ),
                                );
                                tmpCW = set(tmpCW, ["grid"], tempData);

                                if (setCrossword) setCrossword(tmpCW);
                            }}
                            okText={"Yes"}
                            cancelText={"Cancel"}
                        >
                            <Button
                                disabled={disableWrite}
                                shape="circle"
                                danger
                                size="small"
                                icon={
                                    <DeleteOutlined style={{ color: "red" }} />
                                }
                            />
                        </Popconfirm>
                    ) : (
                        <div />
                    )}

                    <Popconfirm
                        title={"Add a new column?"}
                        disabled={disableWrite}
                        onConfirm={() => {
                            let tmpCW = cloneDeep(crossword);

                            let newCell = { type: "empty" };
                            switch (blockType) {
                                case DRAG_AND_SWIPE_BLOCK:
                                    newCell = DEFAULT_DRAG_AND_SWIPE_CELL;
                                    break;
                                case CROSSWORD_BLOCK:
                                    newCell = DEFAULT_CROSSWORD_CELL;
                                    break;
                                case SWIPE_AND_FORM_BLOCK:
                                case DRAG_EXPAND_NUMBER_BLOCK:
                                case INPUT_EXPAND_NUMBER_BLOCK:
                                    newCell = DEFAULT_SWIPE_AND_FORM_CELL;
                                    break;
                                case V2_DRAG_AND_SWIPE_BLOCK:
                                    newCell = DEFAULT_V2_DRAG_AND_SWIPE_CELL;
                                    break;
                                case DRAG_AND_TAG_BLOCK:
                                    newCell = DEFAULT_DRAG_AND_TAG_CELL;
                                    break;
                                default:
                                    break;
                            }
                            let tempData = tmpCW?.grid?.map((val: any) => {
                                val.splice(i + 1, 0, newCell);
                                return val;
                            });
                            tmpCW = set(tmpCW, ["grid"], tempData);

                            if (setCrossword) setCrossword(tmpCW);
                        }}
                        okText={"Yes"}
                        cancelText={"Cancel"}
                    >
                        <Button
                            disabled={disableWrite}
                            size="small"
                            shape="circle"
                            type="primary"
                            icon={<PlusOutlined />}
                        />
                    </Popconfirm>
                </Space>
            );
        });
        return cell;
    };

    const columns = !grid[0]
        ? []
        : [
              ...grid[0]?.map((_: any, i: number) => ({
                  title: i,
                  key: i,
                  dataIndex: i,
                  render: (item: any, __v: any, index: number) => {
                      const cellData =
                          index === grid?.length ? null : grid[index][i];

                      const invalidStyle =
                          (cellData?.type === "variable" ||
                              (cellData?.type === "fillable" &&
                                  cellData?.content)) &&
                          (!cellData?.content?.value?.trim() ||
                              (cellData?.content?.type === "asset" &&
                                  !cellData?.content?.asset))
                              ? { background: "rgba(255,0,0,0.1)" }
                              : {};

                      return {
                          props: {
                              style:
                                  index === grid.length
                                      ? {
                                            minHeight: "56px",
                                            width: "56px",
                                        }
                                      : {
                                            padding: "5px",
                                            ...invalidStyle,
                                        },
                          },
                          children: (
                              <div>
                                  {index !== grid.length && !disableWrite && (
                                      <div
                                          style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              marginBottom: "10px",
                                          }}
                                      >
                                          <div />
                                          {/* <div style={{ color: "blue" }}>
                                              ({index}, {i})
                                          </div> */}
                                          <Button
                                              shape="circle"
                                              type="default"
                                              icon={<EditOutlined />}
                                              size="small"
                                              onClick={() =>
                                                  showModal(index, i, cellData)
                                              }
                                          />
                                      </div>
                                  )}
                                  <div
                                      style={
                                          index === grid.length
                                              ? {}
                                              : {
                                                    border: [
                                                        "empty",
                                                        "variable",
                                                    ].includes(cellData?.type)
                                                        ? ""
                                                        : "1px dashed #000000",
                                                    minHeight: "56px",
                                                    width: "56px",
                                                    textAlign: "center",
                                                    position: "relative",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }
                                      }
                                  >
                                      {index === grid.length ? (
                                          item
                                      ) : cellData?.type === "variable" ? (
                                          <div
                                              style={{
                                                  position: "absolute",
                                                  [cellData?.other?.variable
                                                      ?.position[0] ||
                                                  "bottom"]: 0,
                                                  [cellData?.other?.variable
                                                      ?.position[1] ||
                                                  "left"]: 0,
                                                  fontWeight: "bold",
                                                  fontSize: "16px",
                                              }}
                                          >
                                              {cellData?.content?.value}
                                          </div>
                                      ) : cellData?.type === "fillable" &&
                                        cellData?.content ? (
                                          cellData?.content?.type ===
                                          "string" ? (
                                              cellData?.content?.value
                                          ) : cellData?.content?.type ===
                                            "asset" ? (
                                              <div
                                                  style={{
                                                      background: `url(${convertS3UrlToGcp(
                                                          cellData?.content
                                                              ?.asset,
                                                      )})`,
                                                      height: "56px",
                                                      width: "56px",
                                                      backgroundSize: "cover",
                                                  }}
                                              />
                                          ) : (
                                              ""
                                          )
                                      ) : (
                                          <></>
                                      )}
                                  </div>

                                  {cellData?.type === "fillable" &&
                                      cellData?.correct_answer?.length > 0 && (
                                          <ol
                                              style={{
                                                  marginTop: "8px",
                                                  paddingLeft: 20,
                                              }}
                                              type="i"
                                          >
                                              {cellData?.correct_answer?.map(
                                                  (v, i) => (
                                                      <li>{v}</li>
                                                  ),
                                              )}
                                          </ol>
                                      )}

                                  {cellData?.type === "variable" &&
                                      cellData?.other?.variable?.coordinates
                                          ?.length > 0 && (
                                          <span>
                                              (
                                              {cellData?.other?.variable?.coordinates[0].join(
                                                  ",",
                                              )}
                                              ) - (
                                              {cellData?.other?.variable?.coordinates[1].join(
                                                  ",",
                                              )}
                                              )
                                          </span>
                                      )}
                              </div>
                          ),
                      };
                  },
              })),
              {
                  dataIndex: "index",
                  key: "index",
                  render: (val: number) => {
                      return {
                          props: {},
                          children:
                              val >= 0 && !disableWrite ? (
                                  <Space>
                                      {blockType === CROSSWORD_BLOCK && (
                                          <>
                                              {grid?.length > 1 && (
                                                  <Popconfirm
                                                      disabled={disableWrite}
                                                      title={"Delete this row?"}
                                                      icon={
                                                          <QuestionCircleOutlined
                                                              style={{
                                                                  color: "red",
                                                              }}
                                                          />
                                                      }
                                                      onConfirm={() => {
                                                          let tmpCW =
                                                              cloneDeep(
                                                                  crossword,
                                                              );
                                                          const tempData =
                                                              tmpCW?.grid;
                                                          tempData.splice(
                                                              val,
                                                              1,
                                                          );
                                                          tmpCW = set(
                                                              tmpCW,
                                                              ["grid"],
                                                              tempData,
                                                          );

                                                          const tempFB = [
                                                              ...feedbacks,
                                                          ]
                                                              .filter(
                                                                  (item) =>
                                                                      item.index !==
                                                                      i,
                                                              )
                                                              .map((item) => ({
                                                                  ...item,
                                                                  index:
                                                                      item.index >
                                                                      i
                                                                          ? item.index -
                                                                            1
                                                                          : item.index,
                                                              }));

                                                          if (setCrossword)
                                                              setCrossword(
                                                                  tmpCW,
                                                                  null,
                                                                  {
                                                                      grid_feedbacks:
                                                                          tempFB,
                                                                  },
                                                              );
                                                      }}
                                                      okText={"Yes"}
                                                      cancelText={"Cancel"}
                                                  >
                                                      <Button
                                                          size="small"
                                                          shape="circle"
                                                          danger
                                                          icon={
                                                              <DeleteOutlined
                                                                  style={{
                                                                      color: "red",
                                                                  }}
                                                              />
                                                          }
                                                      />
                                                  </Popconfirm>
                                              )}
                                              <Popconfirm
                                                  title={"Add a row below?"}
                                                  disabled={disableWrite}
                                                  onConfirm={() => {
                                                      let tmpCW =
                                                          cloneDeep(crossword);
                                                      const tempData =
                                                          tmpCW?.grid;
                                                      tempData.splice(
                                                          val + 1,
                                                          0,
                                                          grid[0].map(
                                                              () =>
                                                                  DEFAULT_CROSSWORD_CELL,
                                                          ),
                                                      );
                                                      tmpCW = set(
                                                          tmpCW,
                                                          ["grid"],
                                                          tempData,
                                                      );

                                                      const tempFB = [
                                                          ...feedbacks,
                                                      ].map((item) => ({
                                                          ...item,
                                                          index:
                                                              item.index > i
                                                                  ? item.index +
                                                                    1
                                                                  : item.index,
                                                      }));

                                                      if (setCrossword)
                                                          setCrossword(
                                                              tmpCW,
                                                              null,
                                                              {
                                                                  grid_feedbacks:
                                                                      tempFB,
                                                              },
                                                          );
                                                  }}
                                                  okText={"Yes"}
                                                  cancelText={"Cancel"}
                                              >
                                                  <Button
                                                      size="small"
                                                      shape="circle"
                                                      type="primary"
                                                      icon={<PlusOutlined />}
                                                  />
                                              </Popconfirm>
                                          </>
                                      )}

                                      <Space>
                                          {blockType ===
                                              SWIPE_AND_FORM_BLOCK && (
                                              <Button
                                                  onClick={() =>
                                                      showFeedbackModal(val)
                                                  }
                                              >
                                                  {feedbacks.some(
                                                      (fb: {
                                                          index: number;
                                                          values: any[];
                                                      }) =>
                                                          fb.index === val &&
                                                          fb.values?.length > 0,
                                                  )
                                                      ? "Edit"
                                                      : "Add"}{" "}
                                                  Feedback
                                              </Button>
                                          )}
                                      </Space>
                                  </Space>
                              ) : null,
                      };
                  },
              },
          ];

    const tableData = !grid[0]
        ? []
        : [
              ...grid.map((item: any, i: number) => {
                  const cell: any = { key: i + 1, index: i };
                  item?.forEach((_: any, j: number) => {
                      cell[j] = "";
                  });
                  return cell;
              }),
              actionButtons(),
          ];

    const cellInputProps = {
        blockType,
        isModalOpen,
        closeForm,
        curCellRow,
        curCellCol,
        curCell,
        grid,
        crossword,
        setCrossword: (v: any) => {
            if (setCrossword) setCrossword(v);
        },
        optionsForCorrectDropdown: options?.length
            ? options[0].map((item: { value: any }) => ({
                  label: item.value,
                  value: item.value,
              }))
            : [],
    };

    const feedbackFieldProps = {
        isModalOpen: isFeedbackModalOpen,
        closeForm,
        feedback: curCell,
        onSave: (data: any) => {
            const tempFB = [...feedbacks];
            tempFB[curCellRow] = data;
            setFeedback(tempFB, "grid_feedbacks");
        },
        curIndex: curCellRow,
        isEditor,
        setIsEditor,
        disableWrite,
        isReadOnlyMode,
    };

    return (
        <>
            <CellInput {...cellInputProps} />
            <FeedbackField {...feedbackFieldProps} />
            <h3
                style={{
                    fontWeight: "bold",
                    marginBottom: "20px",
                }}
            >
                Grid
            </h3>
            <Col span={24}>
                <Table
                    bordered
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    showHeader={false}
                    scroll={{ x: "max-content" }}
                    footer={() =>
                        blockType === CROSSWORD_BLOCK &&
                        grid.length === 0 &&
                        !disableWrite ? (
                            <Button
                                type="primary"
                                onClick={() => {
                                    let tmpCW = cloneDeep(crossword);
                                    tmpCW = set(
                                        tmpCW,
                                        ["grid"],
                                        [
                                            [...Array(3)].map(
                                                () => DEFAULT_CROSSWORD_CELL,
                                            ),
                                        ],
                                    );

                                    if (setCrossword) setCrossword(tmpCW);
                                }}
                            >
                                Add Row
                            </Button>
                        ) : null
                    }
                />
            </Col>
        </>
    );
};

export default GridTable;
