import * as React from "react";
import { Button, Modal } from "antd";
import { useState } from "react";
import DropItemModal from "../../../../../Figma/renderEditor/DropItemModal";
import { handleDropItemSubmit } from "../../../../../Figma/renderEditor/CellInnerContentPreview";

const PopulateDropArea = ({
    tagsList,
    selectedCells,
    table,
    tagsClusterList,
    setBlock,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const currentCell =
        table.cells?.[selectedCells?.[0]?.[0]]?.[selectedCells?.[0]?.[1]];

    console.log("populate-drop-area", currentCell);
    if (!currentCell || !currentCell.dragDrop?.enabled) {
        return null;
    }

    const setCurrentCell = (cell, data) => {
        // get row and col from selectedCells[0]
        const rowIndex = selectedCells[0][0];
        const colIndex = selectedCells[0][1];
        // update cell at rowIndex and colIndex
        table.cells = table.cells.map((row, i) => {
            return row.map((col, j) => {
                if (i == rowIndex && j == colIndex) {
                    return cell;
                }
                return col;
            });
        });
        setBlock(table, data);
    };

    const dropItemModalProps = {
        tagsList,
        isModalOpen,
        setModalOpen: setIsModalOpen,
        droppedSource: currentCell.dragDrop.droppedSource,
        onSubmit: (v, data) => {
            handleDropItemSubmit(
                v,
                data,
                currentCell,
                table?.puzzle?.slider?.enabled,
                table?.puzzle?.enabled,
                selectedCells[0][0],
                selectedCells[0][1],
                setCurrentCell,
            );
        },
        disableWrite: false,
        currentEditor: "",
        setCurrentEditor: () => {},
        hasMentions: false,
        mentionsList: [],
        disableCreate: true,
        tagsClusterList,
        optionsSchema: {},
    };

    return (
        <>
            <Button
                type={
                    currentCell.dragDrop.droppedSource ? "primary" : "default"
                }
                onClick={() => setIsModalOpen(true)}
            >
                Populate with tag
            </Button>

            <DropItemModal {...dropItemModalProps} />
        </>
    );
};

export default PopulateDropArea;
