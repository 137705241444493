import {
    Alert,
    Button,
    Form,
    Modal,
    Switch,
    Typography,
    notification,
    Progress,
    Row,
    Col,
    message,
} from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { clone } from "lodash";
import { compose } from "src/helpers";
import { withHookForWorksheetPublishJob } from "src/modules/worksheet/operations";
import { gql, GraphQLClient } from "graphql-request";
import axios from "axios";
import emitter from "src/helpers/emitter";
const { Text } = Typography;

export const graphQLClient = new GraphQLClient(
    process.env.REACT_APP_API_ENDPOINT,
    {
        headers: {
            "x-hasura-admin-secret": process.env.REACT_APP_HASURA_ADMIN_SECRET,
        },
    },
);
export function updateWorksheetPublishJob(object: any) {
    const INSERT_JOB = gql`
        mutation update_other_job_by_pk(
            $object: other_job_set_input!
            $id: Int!
        ) {
            update_other_job_by_pk(pk_columns: { id: $id }, _set: $object) {
                id
            }
        }
    `;
    return graphQLClient.request(INSERT_JOB, {
        id: object.id,
        object,
    });
}

const PublishModal = (props: any) => {
    const {
        blocks,
        disableWrite,
        publishWorksheet,
        isRead,
        publishJob,
        worksheetStats,
        worksheet,
    } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [levelReset, setLevelReset] = useState(blocks?.map(() => false));
    const [showProgress, setShowProgress] = useState(false);

    const [
        shouldPublishToSecondaryHasuraEndpoints,
        setShouldPublishToSecondaryHasuraEndpoints,
    ] = useState(true);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setShowProgress(true);
        publishWorksheet({
            levelReset,
            shouldPublishToSecondaryHasuraEndpoints,
        });
        setShouldPublishToSecondaryHasuraEndpoints(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setLevelReset(blocks?.map(() => false));
    }, [isModalOpen]);

    useEffect(() => {
        // if (publishJob?.progress == 100) {
        //     message.loading("Verifying Publish to US.");
        //     axios
        //         .post(
        //             `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/checkPublishSanity`,
        //             {
        //                 data: {
        //                     worksheet_id: Number(worksheet.id),
        //                 },
        //             },
        //             { headers: { "Content-Type": "application/json" } },
        //         )
        //         .then(function (response) {
        //             if (!response.data.errors?.length) {
        //                 message.success("Successfully Published to US.");
        //                 emitter.emit("show_publish_to_us_error", false);
        //             } else {
        //                 emitter.emit("show_publish_to_us_error", true);
        //             }
        //             return response?.data;
        //         });
        // }
        if (
            publishJob &&
            publishJob?.progress !== 0 &&
            publishJob?.progress !== 100
        ) {
            setShowProgress(true);
        } else if (
            publishJob &&
            (publishJob?.progress == 0 || publishJob?.progress == 100) &&
            !disableWrite
        ) {
            setShowProgress(false);
        }
    }, [publishJob]);

    return (
        <Row gutter={12} align={"middle"}>
            <Col span={12}>
                <Button
                    style={{}}
                    type="primary"
                    block
                    disabled={disableWrite || worksheet?.other?.disable_publish}
                    onClick={showModal}
                >
                    Publish
                </Button>
            </Col>
            <Modal
                title="Publish Worksheet"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Publish Worksheet"
                destroyOnClose
                width={500}
            >
                <Text>Reset user submissions</Text>
                {isRead && (
                    <Alert
                        type="warning"
                        description="You don't have edit access currently!"
                    />
                )}
                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
                {levelReset.map((block: any, idx: any) => (
                    <Form.Item label={`Reset for Level ${idx + 1} `} key={idx}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={block}
                            onChange={(value) => {
                                let tmpBlock = clone(levelReset);
                                tmpBlock[idx] = value;
                                setLevelReset(tmpBlock);
                            }}
                        />
                    </Form.Item>
                ))}

                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
                <Form.Item
                    label={`Publish to other servers`}
                    key={`publish_other_servers`}
                >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={shouldPublishToSecondaryHasuraEndpoints}
                        onChange={(value) => {
                            setShouldPublishToSecondaryHasuraEndpoints(value);
                        }}
                    />
                </Form.Item>
            </Modal>
            {showProgress ? (
                <Col span={12}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                            }}
                        >
                            <Text>{publishJob?.data?.text}</Text>
                        </div>
                        <Progress
                            percent={publishJob?.progress}
                            status={
                                publishJob?.progress == 100
                                    ? "success"
                                    : "active"
                            }
                        />
                    </div>
                </Col>
            ) : (
                <Col span={12}>
                    {worksheetStats?.other?.save_count >
                    worksheetStats?.other?.publish_count
                        ? "Latest changes are not sync"
                        : "Latest changes are sync"}
                </Col>
            )}
        </Row>
    );
};

export default compose(withHookForWorksheetPublishJob)(PublishModal);
