import {
    Button,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Tag,
    Switch,
} from "antd";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { connectHits } from "react-instantsearch-dom";
// import algoliasearch from "algoliasearch/lite";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import EditIcon from "src/components/EditIcon";
import { RenderRecord } from "src/components/form";
import omit from "lodash/omit";
import _ from "lodash";
import TagsAssignComponent from "./TagsAssignComponent";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

// const searchClient = algoliasearch(
//     process.env.REACT_APP_ALGOLIA_APP_ID || "",
//     process.env.REACT_APP_ALGOLIA_API_KEY || "",
// );

export const isEditorEmpty = (value: any) => {
    if (!value.length) return true;
    return value.every((i: any) => {
        if (
            ["p"].includes(i.type) &&
            i.children.every(
                ({ text, type }: any) => !text && type !== "mention",
            )
        )
            return true;

        if (i.type == "latex" && !i.latex) return true;

        if (i.type == "img" && !i.url) return true;

        return false;
    });
};

export const renderTags = (tags: any) => {
    if (tags && tags.length && "value" in tags[0]) {
        return (
            <div>
                {tags?.map(({ value: tag }: any) => {
                    const { name, value } = JSON.parse(tag);
                    return (
                        <Tag>
                            {value}({name})
                        </Tag>
                    );
                })}
            </div>
        );
    }

    return (
        <div>
            {tags?.map(({ tag: { name, value } }: any) => (
                <Tag>
                    {value}({name})
                </Tag>
            ))}
        </div>
    );
};

export const Hit = ({ hit, onClick }: any) => {
    const { blockId, type, data } = hit;
    const block = {
        id: uuid(),
        type,
        data,
        children: [],
        tags: [],
        blockId: blockId,
    };
    return (
        <article>
            <Card
                hoverable
                style={{
                    borderRadius: "8px",
                    height: "75px",
                    marginBottom: "4px",
                }}
                onClick={() => {
                    onClick(block);
                }}
            >
                {type} block
            </Card>
        </article>
    );
};

export const CustomHits = connectHits(({ hits, onClick }: any) => {
    return hits.map((hit: any) => <Hit hit={hit} onClick={onClick} />);
});

export const addCallback = ({ setSaveCounter, currentBlocks }: any) => {
    // setSaveCounter({ currentBlocks });
};

export const DurationPicker = ({
    initialValue = 0,
    onChange,
    disabled,
    is_story,
}: any) => {
    const [minutes, setMinutes] = useState(parseInt(initialValue / 60));
    const [seconds, setSeconds] = useState(initialValue % 60);
    useEffect(() => {
        setMinutes(parseInt(initialValue / 60));
        setSeconds(initialValue % 60);
    }, [initialValue]);

    return (
        <Input.Group size="large">
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item label="Is timed?">
                        <Switch
                            disabled={disabled}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={initialValue >= 0}
                            onChange={(value) => {
                                onChange(value ? (is_story ? 0 : 15) : -1);
                            }}
                        />
                    </Form.Item>
                </Col>
                {initialValue >= 0 && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                gap: "5px",
                                width: "100%",
                                minWidth: "300px",
                            }}
                        >
                            <Col span={10}>
                                <InputNumber
                                    disabled={disabled}
                                    addonBefore="Minutes"
                                    min={0}
                                    value={minutes}
                                    onChange={(value: any) => {
                                        setMinutes(value);
                                        onChange(value * 60 + seconds);
                                    }}
                                    style={{ width: "100%", minWidth: "150px" }}
                                />
                            </Col>
                            <Col span={10}>
                                <InputNumber
                                    addonBefore="Seconds"
                                    min={0}
                                    disabled={disabled}
                                    value={seconds}
                                    onChange={(value: any) => {
                                        setSeconds(value);
                                        onChange(minutes * 60 + value);
                                    }}
                                    style={{ width: "100%", minWidth: "150px" }}
                                />
                            </Col>
                        </div>
                    </>
                )}
            </Row>
        </Input.Group>
    );
};

export const AudioPicker = ({
    block,
    setBlock,
    setBlockCustom,
    audio,
    disabled,
    checkValid = false,
}: any) => {
    const audioUrl = convertS3UrlToGcp(
        audio || block?.data?.other?.audio || "",
    );
    const [isEditState, setEditState] = useState(!!!audioUrl);

    const isValid = !checkValid || Boolean(audioUrl.trim());

    useEffect(() => {
        setEditState(!!!audioUrl);
    }, [audioUrl]);

    return (
        <div style={{ width: "100%" }}>
            {isEditState ? (
                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    validateStatus={!isValid ? "error" : ""}
                    help={!isValid ? "Field cannot be empty" : ""}
                >
                    <Row>
                        <Col>
                            <RenderRecord
                                path="worksheet/audio/"
                                onChangeCustom={(e: {
                                    target: { value: any };
                                }) =>
                                    setBlockCustom
                                        ? setBlockCustom(e.target.value)
                                        : setBlock({
                                              ...block,
                                              data: {
                                                  ...block.data,
                                                  other: {
                                                      ...block.data.other,
                                                      audio: e.target.value,
                                                  },
                                              },
                                          })
                                }
                                disabled={disabled}
                                label="record an mp3"
                                value={convertS3UrlToGcp(
                                    block?.data?.other?.audio || "",
                                )}
                                required={false}
                                uploadType={`custom`}
                                extension={`.mp3`}
                                accept={"audio/*"}
                                onRemove={() => {
                                    setBlockCustom
                                        ? setBlockCustom("")
                                        : setBlock({
                                              ...block,
                                              data: {
                                                  ...block.data,
                                                  other: {
                                                      ...omit(
                                                          block.data.other,
                                                          "audio",
                                                      ),
                                                  },
                                              },
                                          });
                                    return true;
                                }}
                                setEditState={setEditState}
                            />
                        </Col>
                        {audioUrl && (
                            <Col span={2}>
                                <Button
                                    icon={
                                        <CheckOutlined
                                            onClick={() => setEditState(false)}
                                        />
                                    }
                                    shape="circle"
                                    type="primary"
                                    style={{ marginLeft: "10px" }}
                                />
                            </Col>
                        )}
                    </Row>
                </Form.Item>
            ) : (
                <div
                    style={{
                        display: "flex",
                        overflowX: "auto",
                        overflowY: "hidden",
                        width: "100%",
                    }}
                >
                    <ReactPlayer
                        url={convertS3UrlToGcp(audioUrl)}
                        controls
                        file={{ forceAudio: true }}
                        height={"60px"}
                        width="100%"
                    />
                    {audioUrl && (
                        <EditIcon
                            isButton={true}
                            disabled={disabled}
                            onClick={() => setEditState(true)}
                            style={{ marginLeft: "10px" }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export const BLOCK_TYPES = {
    // common
    TEXT_BLOCK: "text",
    IMAGE_BLOCK: "image",
    MCQ_BLOCK: "mcq",
    CASE_STUDY_BLOCK: "case_study",
    SUBJECTIVE_BLOCK: "subjective",
    VIDEO_BLOCK: "video",
    RE_ORDER_BLOCK: "re_order",

    // daily quiz
    LONG_ANSWER_BLOCK: "long_answer",
    SHORT_ANSWER_BLOCK: "short_answer",
    EMAIL_BLOCK: "email",
    PHONE_BLOCK: "phone",
    CONTENT_BLOCK: "content",

    // v2 personalization
    TABLE_BLOCK: "table",
    BUTTON_BLOCK: "button",
    AUDIO_BLOCK: "audio",
    V2_CONTENT_BLOCK: "v2_content",
    V2_MCQ_BLOCK: "v2_mcq",
    // V2_STEP_BY_STEP_BLOCK: "v2_step_by_step",
    V2_FILL_BLANK_BLOCK: "v2_fill_blank",
    V2_MIXED_BLOCK: "v2_mixed",
    V2_RAPID_QA_BLOCK: "v2_rapid_qa",
    V2_THREE_LIVES_BLOCK: "v2_three_lives",
    V2_STORIES_BLOCK: "v2_stories",
    V2_STORY_BLOCK: "v2_story",
    V2_AVATAR_BLOCK: "v2_avatar",
    V2_TEXT_BLOCK: "v2_rich_text",
    V2_VOICE_BLOCK: "v2_voice",
    V2_TABLE_BLOCK: "v2_table",
    V2_COUNTDOWN_BLOCK: "v2_countdown",

    // activity
    PROGRESSION_STAGE_BLOCK: "progression_stage",
    PROGRESS_LEVEL_BLOCK: "progress_level",
    CROSSWORD_BLOCK: "crossword",
    DRAG_AND_SWIPE_BLOCK: "drag_and_swipe",
    DRAG_ARRANGE_LIST_BLOCK: "drag_arrange_list",
    COMPARE_ARRANGE_LIST_BLOCK: "compare_arrange_list",
    SCRATCH_ARRANGE_LIST_BLOCK: "scratch_arrange_list",
    INPUT_ARRANGE_LIST_BLOCK: "input_arrange_list",
    COMPARE_DIGITS_BLOCK: "compare_digits",
    SWIPE_AND_FORM_BLOCK: "swipe_and_form",
    PAINT_ACTIVITY_BLOCK: "paint_activity",
    COLOR_COMBINE_BLOCK: "color_combine",
    INPUT_AND_FORM_BLOCK: "input_and_form",
    INPUT_EXPAND_NUMBER_BLOCK: "input_expand_number",
    DRAG_EXPAND_NUMBER_BLOCK: "drag_expand_number",
    DRAG_AND_TAG_BLOCK: "drag_and_tag",
    V2_DRAG_AND_SWIPE_BLOCK: "v2_drag_and_swipe",
    V2_INPUT_AND_FORM_BLOCK: "v2_input_and_form",
    DRAG_AND_INPUT_BLOCK: "drag_and_input",

    FIGMA_BLOCK: "figma",
    ALL_IN_ONE: "all_in_one",
    MULTI_PLAYER: "multi_player_block",
};

const {
    // common
    // TEXT_BLOCK,
    IMAGE_BLOCK,
    MCQ_BLOCK,
    CASE_STUDY_BLOCK,
    SUBJECTIVE_BLOCK,
    // VIDEO_BLOCK,
    RE_ORDER_BLOCK,

    // daily quiz
    // LONG_ANSWER_BLOCK,
    // SHORT_ANSWER_BLOCK,
    // EMAIL_BLOCK,
    // PHONE_BLOCK,
    // CONTENT_BLOCK,

    // v2 personalization
    // TABLE_BLOCK,
    // BUTTON_BLOCK,
    // AUDIO_BLOCK,
    // V2_CONTENT_BLOCK,
    // V2_FILL_BLANK_BLOCK,
    // V2_MIXED_BLOCK,
    // V2_RAPID_QA_BLOCK,
    // V2_THREE_LIVES_BLOCK,
    V2_STORIES_BLOCK,
    // V2_STORY_BLOCK,
    // V2_AVATAR_BLOCK,
    // V2_TEXT_BLOCK,
    // V2_VOICE_BLOCK,

    // activity
    V2_MCQ_BLOCK,
    PROGRESSION_STAGE_BLOCK,
    PROGRESS_LEVEL_BLOCK,
    CROSSWORD_BLOCK,
    // DRAG_AND_SWIPE_BLOCK,
    // DRAG_ARRANGE_LIST_BLOCK,
    // COMPARE_ARRANGE_LIST_BLOCK,
    SCRATCH_ARRANGE_LIST_BLOCK,
    // INPUT_ARRANGE_LIST_BLOCK,
    // COMPARE_DIGITS_BLOCK,
    // SWIPE_AND_FORM_BLOCK,
    // COLOR_COMBINE_BLOCK,
    // V2_TABLE_BLOCK,
    // INPUT_AND_FORM_BLOCK,
    // INPUT_EXPAND_NUMBER_BLOCK,
    // DRAG_EXPAND_NUMBER_BLOCK,
    V2_DRAG_AND_SWIPE_BLOCK,
    // V2_INPUT_AND_FORM_BLOCK,
    // DRAG_AND_TAG_BLOCK,
    // DRAG_AND_INPUT_BLOCK,

    FIGMA_BLOCK,
} = BLOCK_TYPES;

export const QUIZ_BLOCK_TYPES = {
    // TEXT_BLOCK,
    // IMAGE_BLOCK,
    // LONG_ANSWER_BLOCK,
    // SHORT_ANSWER_BLOCK,
    // EMAIL_BLOCK,
    // PHONE_BLOCK,
    // CONTENT_BLOCK,
    // MCQ_BLOCK,
    // VIDEO_BLOCK,
};

export const BLOCK_TYPES_PERSONALIZED_LEARNING = {
    // MCQ_BLOCK,
    // VIDEO_BLOCK,
    // TABLE_BLOCK,
    // AUDIO_BLOCK,
    // // v2 personalization
    // V2_CONTENT_BLOCK,
    // V2_FILL_BLANK_BLOCK,
    // V2_RAPID_QA_BLOCK,
    // V2_THREE_LIVES_BLOCK,
};

export const BLOCK_TYPES_NORMAL = {
    IMAGE_BLOCK,
    MCQ_BLOCK,
    CASE_STUDY_BLOCK,
    SUBJECTIVE_BLOCK,
    RE_ORDER_BLOCK,
};

export const BLOCK_TYPES_TIMED = {
    IMAGE_BLOCK,
    MCQ_BLOCK,
    CASE_STUDY_BLOCK,
    SUBJECTIVE_BLOCK,
    RE_ORDER_BLOCK,
};

export const BLOCK_TYPES_PERSONALIZED_LEARNING_V2 = {
    // activity
    PROGRESSION_STAGE_BLOCK,
};

export const BLOCK_TYPES_PERSONALIZED_LEARNING_V3 = {
    // activity
    PROGRESS_LEVEL_BLOCK,
};

export const BLOCK_TYPES_PROGRESSION_STAGE = {
    V2_MCQ_BLOCK, // IN USE // LIVE
    // MCQ_BLOCK,
    // CASE_STUDY_BLOCK,
    // SUBJECTIVE_BLOCK,
    // RE_ORDER_BLOCK,
    // LONG_ANSWER_BLOCK,
    // SHORT_ANSWER_BLOCK,
    V2_STORIES_BLOCK, // IN USE // LIVE
    // TABLE_BLOCK,
    // V2_FILL_BLANK_BLOCK,
    // V2_MIXED_BLOCK,
    CROSSWORD_BLOCK, // IN USE // LIVE
    // DRAG_ARRANGE_LIST_BLOCK, // IN USE
    // COMPARE_ARRANGE_LIST_BLOCK,
    SCRATCH_ARRANGE_LIST_BLOCK, // IN USE // LIVE
    // INPUT_ARRANGE_LIST_BLOCK,
    // DRAG_AND_SWIPE_BLOCK, // IN USE
    // COMPARE_DIGITS_BLOCK, // IN USE
    // SWIPE_AND_FORM_BLOCK, // IN USE
    // INPUT_AND_FORM_BLOCK, // IN USE
    // COLOR_COMBINE_BLOCK,
    // V2_TABLE_BLOCK,
    // V2_FILL_BLANK_BLOCK,
    // V2_VOICE_BLOCK, // IN USE
    // INPUT_EXPAND_NUMBER_BLOCK, // IN USE
    // DRAG_EXPAND_NUMBER_BLOCK, // IN USE

    V2_DRAG_AND_SWIPE_BLOCK, // IN USE // LIVE
    // V2_INPUT_AND_FORM_BLOCK, // IN USE

    // DRAG_AND_TAG_BLOCK, // IN USE
    // DRAG_AND_INPUT_BLOCK, // IN USE

    FIGMA_BLOCK, // IN USE
};

export const renderSettingsShortnLong = (props: any, type: string) => {
    const { block, setBlock, currentSubBlock } = props;

    const {
        data: {
            [type]: { limit, hasLimit, isRequired },
        },
    } = block;

    const isValid = !hasLimit || (hasLimit && limit && limit > 0);

    return (
        <div>
            <TagsAssignComponent
                key={block.id + (currentSubBlock ? `${currentSubBlock}` : ``)}
                handleChange={(data: any) => {
                    let tmpBlock = _.cloneDeep(block);
                    tmpBlock = _.set(tmpBlock, ["tags"], data);
                    setBlock(tmpBlock);
                }}
                selected={
                    block.tags?.map(({ label, value, key, tag }: any) => {
                        return {
                            value: value || JSON.stringify(tag),
                            key: key || tag.id,
                            label: label || `${tag.value} (${tag.name})`,
                        };
                    }) || []
                }
            />

            <br />

            <Form.Item label="Required?">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={isRequired}
                    onChange={(value) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", type, "isRequired"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            </Form.Item>

            <Form.Item label="Has Characters Limit">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={hasLimit}
                    onChange={(value) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", type, "hasLimit"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            </Form.Item>

            {hasLimit && (
                <div
                    style={{
                        border: "1px dashed #808080",
                        margin: "10px auto",
                        padding: "10px 10px",
                    }}
                >
                    <h3>Characters Limit</h3>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        validateStatus={!isValid ? "error" : ""}
                        help={!isValid ? "Field cannot be empty or zero" : ""}
                    >
                        <Input
                            type="number"
                            value={limit || ""}
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", type, "limit"],
                                    e.target.value,
                                );
                                setBlock(tmpBlock);
                            }}
                            required
                        />
                    </Form.Item>
                </div>
            )}
        </div>
    );
};

export const isUrl = (str: string | URL) => {
    try {
        new URL(str);
        return true;
    } catch (e) {
        // captureException(e)
        return false;
    }
};

export const prefillText = (text: string) => [
    {
        children: [{ text }],
        type: "p",
    },
];

export { default as TagsAssignComponent } from "./TagsAssignComponent";
