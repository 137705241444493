import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const renderBlockImage = (block: any) => {
    const {
        data: { image, other },
    } = block;
    return (
        <div style={{ width: "40%" }}>
            {other?.duration ? <span>Duration: {other?.duration}</span> : null}

            <img
                src={convertS3UrlToGcp(image)}
                style={{ width: "-webkit-fill-available" }}
            />
        </div>
    );
};

export default renderBlockImage;
