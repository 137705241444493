import {
    Badge,
    Button,
    Card,
    Col,
    Form,
    Input,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    message,
} from "antd";
import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import ResizableRotatableDraggable from "react-resizable-rotatable-draggable";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import {
    CheckOutlined,
    CloseOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";
import ReactPlayer from "react-player";
import { renderEditorImage } from "../../Image";
import { renderEditorV2Avatar } from "../../V2Avatar";

import { BLOCK_TYPES, DurationPicker } from "../../../common/";
import PlaceholderField, {
    PLACEHOLDER_TYPE_OPTIONS,
} from "../../../common/PlaceholderField";
import { renderEditorButton } from "../../Button";
import { renderEditorV2Text } from "../../V2RichText";
import { renderer } from "../../../helpers";
import EditIcon from "src/components/EditIcon";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { Actions } from "../../../../WorksheetEditor/components/Editor/BlocksColumn/Actions";
import { DragHandle } from "../../V2MCQ/renderEditor";
import { AudioGenerateComponent } from "../../../../WorksheetEditor/components/Modals/AudioGenerateComponent";
import { LogicModal } from "../../AllInOne/renderEditor/ComponentsEditor/common/LogicModal";
import { INPUT_FIELD_VALUE } from "../defaultValue";
import EditorInput from "./InputEditor";
import { MathRender } from "src/components";
import { checkStorySubblockValid } from "../isValid";
import { StoryAudioListModal } from "./StoryAudioListModal";
import renderEditorCountDown from "../../CountDown/renderEditor";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const {
    V2_STORY_BLOCK,
    IMAGE_BLOCK,
    V2_TEXT_BLOCK,
    V2_AVATAR_BLOCK,
    BUTTON_BLOCK,
    V2_COUNTDOWN_BLOCK,
} = BLOCK_TYPES;

const INPUT_BLOCK = "input";
const RICHTEXT_INPUT_BLOCK = "rich_text_input";
const MATH_INPUT_BLOCK = "rich_text_mathquill_input_html";

const EditorImage = renderEditorImage;
const EditorAvatar = renderEditorV2Avatar;
const EditorText = renderEditorV2Text;
const EditorButton = renderEditorButton;
const EditorCountDown = renderEditorCountDown;

const StoryStatusBar: FC<any> = ({
    currentBlock,
    childBlockCount,
    percentage = 0,
}: any) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "8px",
                width: "100%",
            }}
        >
            {[...Array(childBlockCount)].map((_v, i) => (
                <div
                    key={i}
                    style={{
                        width: "100%",
                        height: "4px",
                        borderRadius: "2px",
                        background:
                            currentBlock > i + 1
                                ? "#FFDE48"
                                : "rgba(217, 217, 217, 0.6)",
                        flexShrink: 1,
                    }}
                >
                    {currentBlock === i + 1 && (
                        <div
                            style={{
                                width: `${percentage}%`,
                                height: "4px",
                                borderRadius: "2px",
                                background: "#FFDE48",
                            }}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

const getLabel = (v: any) => {
    switch (v) {
        case IMAGE_BLOCK:
            return "Image";
        case V2_TEXT_BLOCK:
            return "Text";
        case V2_AVATAR_BLOCK:
            return "Avatar";
        case BUTTON_BLOCK:
            return "Button";
        case INPUT_BLOCK:
            return "Input";
        case RICHTEXT_INPUT_BLOCK:
            return "Rich Text Input";
        case MATH_INPUT_BLOCK:
            return "MathQuill Input";
        case V2_COUNTDOWN_BLOCK:
            return "CountDown";
        default:
            return "";
    }
};

const SCREEN_WIDTH = 412;
const SCREEN_HEIGHT = Math.round((SCREEN_WIDTH * 16) / 9);

const EditorStory: React.FC<any> = ({
    block,
    setBlock,
    setIsEditor,
    isEditor,

    currentBlock,
    isNewBlock,
    setIsNewBlock,
    worksheet,

    showDuration = true,
    childBlockCount,
    isPlaying,
    setIsPLaying,

    disableWrite,
    isReadOnlyMode,

    hasMentions = false,
    mentionsList = [],

    renderLinkSelect,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [V2_STORY_BLOCK]: {
                logic,
                name,
                bg_audio_list = [],
                duration_type = null,
                play_multiple_audio,
            },
            children,
        },
    } = block;

    const [loading, setLoading] = useState(false);
    const [currentSubBlock, setCurrentSubBlock] = useState(-1);
    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);
    const [curSubPos, setCurSubPos] = useState(
        children[currentSubBlock]?.other?.position,
    );
    const [curSubAngle, setCurSubAngle] = useState(
        children[currentSubBlock]?.other?.angle,
    );
    const [curSubSize, setCurSubSize] = useState(
        children[currentSubBlock]?.other?.size,
    );

    useEffect(() => {
        if (loading)
            setTimeout(() => {
                setLoading(false);
            }, 100);
    }, [loading]);

    useEffect(() => {
        setCurSubPos(children[currentSubBlock]?.other?.position);
        setCurSubAngle(children[currentSubBlock]?.other?.angle || 0);
        setCurSubSize(
            children[currentSubBlock]?.other?.size || { height: 0, widdth: 0 },
        );
        setLoading(true);
    }, [currentSubBlock, id, tmpId, currentBlock]);

    useEffect(() => {
        setCurrentSubBlock(-1);
        setCurrentEditor(`${id || tmpId}_text`);
        setIsPLaying(false);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor !== "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    // const getDuration = (url: string) => {
    //     return new Promise((resolve) => {
    //         const video = document.createElement("video");

    //         video.addEventListener("loadedmetadata", () => {
    //             const { duration } = video;
    //             resolve(duration + 1);
    //         });

    //         video.addEventListener("error", () => {
    //             resolve(null);
    //         });

    //         video.src = url;
    //     });
    // };

    const childProps = {
        block: children[currentSubBlock],
        setBlock: async (storyChild: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(
                tmpBlock,
                ["data", "children", currentSubBlock],
                storyChild,
            );
            // if (storyChild?.type === "v2_avatar") {
            //     const {
            //         data: {
            //             v2_avatar: { type, value, heygen },
            //         },
            //     } = storyChild;

            //     if (type === "video" && block.data.other?.duration !== -1) {
            //         const duration: any = await getDuration(value);
            //         if (duration) {
            //             tmpBlock = _.set(
            //                 tmpBlock,
            //                 ["data", "other", "duration"],
            //                 Math.ceil(duration),
            //             );
            //         }
            //     } else if (type === "heygen_avatar") {
            //         const duration: any = await getDuration(heygen?.video);
            //         if (duration) {
            //             tmpBlock = _.set(
            //                 tmpBlock,
            //                 ["data", "other", "duration"],
            //                 Math.ceil(duration),
            //             );
            //         }
            //     }
            // }
            setBlock(tmpBlock);
        },
        setIsEditor,
        isEditor,

        currentBlock,
        isNewBlock,
        setIsNewBlock,
        worksheet,

        showDuration: false,
        showBackgroundAudio: true,

        currentEditor,
        setCurrentEditor,
        childBlockCount,
        isReadOnlyMode,
        hasMentions,
        mentionsList,

        renderLinkSelect,
    };

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const handleDragPositions = (data: any) => {
        const tmpChildren = _.cloneDeep(children);

        tmpChildren[currentSubBlock].other = {
            ...tmpChildren[currentSubBlock].other,
            ...data,
        };

        let tmpBlock = _.cloneDeep(block);
        tmpBlock = _.set(tmpBlock, ["data", "children"], tmpChildren);
        setBlock(tmpBlock);
    };

    const handleResize = (style: {
        top: any;
        left: any;
        width: any;
        height: any;
    }) => {
        let { top, left, width, height } = style;

        top = Math.round(top);
        left = Math.round(left);
        width = Math.round(width);
        height = Math.round(height);

        setCurSubPos({ top, left });
        setCurSubSize({ width, height });
    };

    const handleResizeEnd = () =>
        handleDragPositions({
            position: curSubPos,
            size: curSubSize,
        });

    const handleRotate = (rotateAngle: any) => {
        setCurSubAngle(rotateAngle);
    };

    const handleRotateEnd = () =>
        handleDragPositions({
            angle: curSubAngle,
        });

    const handleDrag = ({ top, left }: any) => {
        setCurSubPos({ top, left });
    };

    const handleDragEnd = () =>
        handleDragPositions({
            position: curSubPos,
        });

    const backgroundStyle: any = {};
    const type = get(block, ["data", V2_STORY_BLOCK, "background", "type"]);
    const value = get(block, ["data", V2_STORY_BLOCK, "background", "value"]);

    const [video, setVideo] = useState(value);

    useEffect(() => {
        if (type === PLACEHOLDER_TYPE_OPTIONS[1].value) {
            setVideo(value);
        } else {
            setVideo("");
        }
    }, [value]);

    if (type === PLACEHOLDER_TYPE_OPTIONS[1].value)
        backgroundStyle["background"] = get(block, [
            "data",
            V2_STORY_BLOCK,
            "background",
            "default",
        ]);
    if (type === PLACEHOLDER_TYPE_OPTIONS[0].value) {
        backgroundStyle["backgroundImage"] = `url(${convertS3UrlToGcp(value)})`;
        backgroundStyle["backgroundSize"] = "contain";
        backgroundStyle["backgroundPosition"] = "center center";
    }

    if (
        [
            PLACEHOLDER_TYPE_OPTIONS[2].value,
            "LINEAR_COLOR",
            "RADIAL_COLOR",
        ].indexOf(type) !== -1
    ) {
        backgroundStyle["background"] = value;
    }

    const SortableItem = SortableElement(({ value, child }) => {
        const actionsProps = {
            deleteBlock: () => {
                setCurrentSubBlock(currentSubBlock - 1);
                let tmpBlock = _.cloneDeep(block);
                const tmpOptions = [...children];
                _.pullAt(tmpOptions, currentSubBlock);
                tmpBlock = _.set(tmpBlock, ["data", "children"], tmpOptions);

                setBlock(tmpBlock);
            },
            idx: currentSubBlock,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...children];
                tmpChildren.splice(index, 0, newBlock);
                tmpBlock = _.set(tmpBlock, ["data", "children"], tmpChildren);
                setBlock(tmpBlock);
                setCurrentSubBlock(index);
            },
            block: child,
        };

        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            background:
                                currentSubBlock == value ? "#F1F1F1" : "",
                            borderBottom: !checkStorySubblockValid(child)
                                ?.isValid
                                ? "3px solid red"
                                : currentSubBlock == value
                                ? "3px solid indigo"
                                : "",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                            paddingBottom: 10,
                            paddingTop: 10,
                        }}
                        onClick={() => {
                            setCurrentSubBlock(value);
                        }}
                    >
                        {getLabel(children[value].type)}

                        {currentSubBlock === value && !disableWrite && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "2px",
                                }}
                            >
                                <DragHandle />
                            </div>
                        )}
                    </Card>
                </Badge.Ribbon>

                {currentSubBlock === value && !disableWrite && (
                    <Actions {...actionsProps} />
                )}
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["data", "children"], tmpChildren);
        setBlock(tmpBlock);
        setCurrentSubBlock(newIndex);
    };

    return (
        <div>
            <Button
                disabled={disableWrite}
                type="primary"
                onClick={() => {
                    message.info(`Copied Story to clipboard!`);

                    navigator.clipboard.writeText(JSON.stringify(block));
                }}
            >
                Copy the story to Clipboard
            </Button>
            <Row
                gutter={[10, 10]}
                style={{
                    // maxWidth: "600px",
                    marginTop: "16px",
                }}
            >
                <Col span={14}>
                    <Form.Item label="Duration">
                        <DurationPicker
                            disabled={disableWrite}
                            is_story={true}
                            onChange={onDurationChange}
                            initialValue={block?.data?.other?.duration || 0}
                        />
                    </Form.Item>
                    {block?.data?.other?.duration >= 0 && (
                        <Form.Item label="Duration Type">
                            <Select
                                style={{
                                    minWidth: 100,
                                    maxWidth: 300,
                                }}
                                disabled={disableWrite}
                                placeholder="Duration Type"
                                value={duration_type}
                                options={[
                                    {
                                        label: "Duration or maximum",
                                        value: null,
                                    },
                                    { label: "Duration", value: "duration" },
                                    { label: "Maximum", value: "maximum" },
                                    { label: "Avatar", value: "avatar" },
                                    {
                                        label: "Background Video",
                                        value: "bg_video",
                                    },
                                    {
                                        label: "Background Audio",
                                        value: "bg_audio",
                                    },
                                ]}
                                onChange={(v) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            V2_STORY_BLOCK,
                                            "duration_type",
                                        ],
                                        v,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    )}
                </Col>
                <Col span={10}>
                    <Form.Item label="Update Logic">
                        <LogicModal
                            disableWrite={disableWrite}
                            computeFUnctions={logic?.compute_functions || []}
                            isAllGlobal={true}
                            onSave={(val) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        V2_STORY_BLOCK,
                                        "logic",
                                        "compute_functions",
                                    ],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col
                    span={12}
                    style={{ display: "flex", flexWrap: "wrap", gap: 10 }}
                >
                    <Form.Item
                        style={{
                            flexShrink: 0,
                        }}
                        label="Name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input
                            value={name}
                            disabled={disableWrite}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_STORY_BLOCK, "name"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            flexShrink: 0,
                        }}
                        label="Can Skip?"
                    >
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={block?.data[V2_STORY_BLOCK]?.is_skip}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_STORY_BLOCK, "is_skip"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            flexShrink: 0,
                        }}
                        label="Play multiple audios?"
                    >
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={play_multiple_audio}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        V2_STORY_BLOCK,
                                        "play_multiple_audio",
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            width: "100%",
                            flexShrink: 0,
                        }}
                        label="Background Audio List"
                    >
                        <StoryAudioListModal
                            audioList={bg_audio_list}
                            disableWrite={disableWrite}
                            onSave={(v) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_STORY_BLOCK, "bg_audio_list"],
                                    v,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <div
                        style={{
                            border: "1px dashed #808080",
                            padding: "10px 10px",
                        }}
                    >
                        <h3>Background Audio</h3>
                        <AudioGenerateComponent
                            name={"Background Audio"}
                            onSave={(data: any) => {
                                setBlock({
                                    ...block,
                                    data: {
                                        ...block.data,
                                        other: {
                                            ...block.data.other,
                                            audio: data,
                                        },
                                    },
                                });
                            }}
                            disableWrite={isReadOnlyMode}
                            audioData={block?.data?.other?.audio}
                        />
                    </div>
                </Col>
            </Row>
            <KTWrapper feature="sub_blocks">
                <div
                    style={{
                        border: "1px dashed #808080",
                        margin: "10px auto",
                        padding: "10px 10px",
                    }}
                >
                    {!disableWrite && (
                        <div style={{ padding: "2px" }}>
                            <h3>Sub Blocks</h3>

                            <div style={{ maxWidth: "600px" }}>
                                <SortableList
                                    items={children}
                                    onSortEnd={onSortEnd}
                                    axis="xy"
                                    useDragHandle
                                />
                            </div>

                            <br />

                            <div
                                style={{ margin: "10px 0", maxWidth: "600px" }}
                            >
                                <Space wrap>
                                    <Button
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "children",
                                                    children.length,
                                                ],
                                                {
                                                    type: IMAGE_BLOCK,
                                                    data: {
                                                        [IMAGE_BLOCK]:
                                                            "https://images1.wionews.com/images/wion/900x1600/2023/6/17/1687029535624_luffyonepiece.webp",
                                                    },
                                                    tmpId: uuid(),
                                                    order: children.length,
                                                    other: {
                                                        position: {
                                                            top: 0,
                                                            left: 0,
                                                        },
                                                        size: {
                                                            height: 100,
                                                            width: 100,
                                                        },
                                                        angle: 0,
                                                    },
                                                },
                                            );
                                            setBlock(tmpBlock);
                                            setCurrentSubBlock(children.length);
                                        }}
                                    >
                                        Add Image Block
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "children",
                                                    children.length,
                                                ],
                                                {
                                                    type: V2_TEXT_BLOCK,
                                                    data: {
                                                        [V2_TEXT_BLOCK]: [
                                                            {
                                                                type: "p",
                                                                children: [
                                                                    {
                                                                        text: "This is a TEXT block",
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                        textAlign: "left",
                                                    },
                                                    other: {
                                                        position: {
                                                            top: 50,
                                                            left: 50,
                                                        },
                                                        size: {
                                                            height: 100,
                                                            width: 300,
                                                        },
                                                        // angle: 0,
                                                    },
                                                    tmpId: uuid(),
                                                    order: children.length,
                                                },
                                            );
                                            setBlock(tmpBlock);
                                            setCurrentSubBlock(children.length);
                                        }}
                                    >
                                        Add Text Block
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "children",
                                                    children.length,
                                                ],
                                                {
                                                    type: V2_COUNTDOWN_BLOCK,
                                                    data: {
                                                        [V2_COUNTDOWN_BLOCK]: [
                                                            {
                                                                type: "p",
                                                                children: [
                                                                    {
                                                                        text: "00:00:00",
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                        textAlign: "left",
                                                    },
                                                    other: {
                                                        position: {
                                                            top: 50,
                                                            left: 50,
                                                        },
                                                        size: {
                                                            height: 100,
                                                            width: 300,
                                                        },
                                                        date_value: undefined,
                                                        time_value: undefined,
                                                        // angle: 0,
                                                    },
                                                    tmpId: uuid(),
                                                    order: children.length,
                                                },
                                            );
                                            setBlock(tmpBlock);
                                            setCurrentSubBlock(children.length);
                                        }}
                                    >
                                        Add countDown Block
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "children",
                                                    children.length,
                                                ],
                                                {
                                                    type: V2_AVATAR_BLOCK,
                                                    data: {
                                                        [V2_AVATAR_BLOCK]: {
                                                            type: "image",
                                                            value: "https://images1.wionews.com/images/wion/900x1600/2023/6/17/1687029535624_luffyonepiece.webp",
                                                        },
                                                    },
                                                    tmpId: uuid(),
                                                    order: children.length,
                                                    other: {
                                                        position: {
                                                            top: 53,
                                                            left: 23,
                                                        },
                                                        size: {
                                                            height: 72,
                                                            width: 72,
                                                        },
                                                    },
                                                },
                                            );
                                            setBlock(tmpBlock);
                                            setCurrentSubBlock(children.length);
                                        }}
                                    >
                                        Add Avatar Block
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "children",
                                                    children.length,
                                                ],
                                                {
                                                    type: BUTTON_BLOCK,
                                                    data: {
                                                        [BUTTON_BLOCK]: {
                                                            text: "Text",
                                                            color: "#333333",
                                                            font_size: "18",
                                                            font_weight: "600",
                                                            background_color:
                                                                "#FFDE49",
                                                            action: {
                                                                type: "internal",
                                                                value: "",
                                                            },
                                                        },
                                                    },
                                                    tmpId: uuid(),
                                                    order: children.length,
                                                    other: {
                                                        position: {
                                                            top: 648,
                                                            left: 0,
                                                        },
                                                        size: {
                                                            height: 84,
                                                            width: 412,
                                                        },
                                                    },
                                                },
                                            );

                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["data", "other", "duration"],
                                                -1,
                                            );
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    V2_STORY_BLOCK,
                                                    "is_skip",
                                                ],
                                                false,
                                            );

                                            setBlock(tmpBlock);
                                            setCurrentSubBlock(children.length);
                                        }}
                                    >
                                        Add Button Block
                                    </Button>
                                    {[
                                        INPUT_BLOCK,
                                        RICHTEXT_INPUT_BLOCK,
                                        MATH_INPUT_BLOCK,
                                    ].map((valType) => (
                                        <Button
                                            onClick={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        "children",
                                                        children.length,
                                                    ],
                                                    INPUT_FIELD_VALUE({
                                                        type: valType,
                                                        tmpId: uuid(),
                                                        order: children.length,
                                                        other: {
                                                            position: {
                                                                top: 100,
                                                                left: 100,
                                                            },
                                                            size: {
                                                                height: 56,
                                                                width: 56,
                                                            },
                                                        },
                                                    }),
                                                );
                                                setBlock(tmpBlock);
                                                setCurrentSubBlock(
                                                    children.length,
                                                );
                                            }}
                                        >
                                            Add {getLabel(valType)} Block
                                        </Button>
                                    ))}
                                </Space>
                            </div>
                        </div>
                    )}

                    <div style={{ margin: "30px 0", width: "100%" }}>
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    width: `${SCREEN_WIDTH}px`,
                                    flexShrink: 0,
                                }}
                            >
                                <h3>Preview</h3>
                                <hr />
                                {!loading && (
                                    <div
                                        style={{
                                            padding: "20px 0",
                                            background: video
                                                ? get(block, [
                                                      "data",
                                                      V2_STORY_BLOCK,
                                                      "background",
                                                      "default",
                                                  ])
                                                : "",
                                        }}
                                    >
                                        <div
                                            style={{
                                                ...backgroundStyle,
                                                backgroundRepeat: "no-repeat",
                                                width: `${SCREEN_WIDTH}px`,
                                                height: `${SCREEN_HEIGHT}px`,
                                                position: "relative",
                                                overflow: "hidden",
                                                border: "1px solid #444",
                                                boxShadow:
                                                    "1px 1px 8px rgba(0, 0, 0, 0.16)",
                                            }}
                                        >
                                            {video && (
                                                <ReactPlayer
                                                    url={convertS3UrlToGcp(
                                                        video,
                                                    )}
                                                    width="100%"
                                                    height="732px"
                                                    playing={isPlaying}
                                                    loop
                                                    style={{
                                                        background: get(block, [
                                                            "data",
                                                            V2_STORY_BLOCK,
                                                            "background",
                                                            "default",
                                                        ]),
                                                        pointerEvents: "none",
                                                        userSelect: "none",
                                                    }}
                                                />
                                            )}
                                            <div
                                                style={{
                                                    width: "100%",
                                                    position: "absolute",
                                                    top: 16,
                                                    left: 0,
                                                    right: 0,
                                                    padding: "0 16px",
                                                }}
                                            >
                                                <StoryStatusBar
                                                    childBlockCount={
                                                        childBlockCount
                                                    }
                                                    currentBlock={currentBlock}
                                                    percentage={30}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 16,
                                                    right: 16,
                                                }}
                                            >
                                                <EditIcon
                                                    disabled={disableWrite}
                                                    isButton={true}
                                                    onClick={() =>
                                                        setCurrentSubBlock(
                                                            children.length,
                                                        )
                                                    }
                                                />
                                            </div>
                                            {children?.map(
                                                (
                                                    item: {
                                                        type: string;
                                                        data: any;
                                                        other: {
                                                            position: {
                                                                left: any;
                                                                top: any;
                                                            };
                                                            size: {
                                                                width: any;
                                                                height: any;
                                                            };
                                                            angle: any;
                                                        };
                                                    },
                                                    key: number,
                                                ) => {
                                                    switch (item.type) {
                                                        case IMAGE_BLOCK:
                                                            return (
                                                                <>
                                                                    <img
                                                                        alt=""
                                                                        draggable="false"
                                                                        src={convertS3UrlToGcp(
                                                                            item
                                                                                .data
                                                                                ?.image,
                                                                        )}
                                                                        style={{
                                                                            position:
                                                                                "absolute",
                                                                            top:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.top
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.top,
                                                                            left:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.left
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.left,
                                                                            height:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.height
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          .height,
                                                                            width:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.width
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          .width,
                                                                            transform: `rotate(${
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubAngle
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.angle ||
                                                                                      0
                                                                            }deg)`,
                                                                            userSelect:
                                                                                "none",
                                                                        }}
                                                                        onClick={() => {
                                                                            if (
                                                                                disableWrite
                                                                            )
                                                                                return;
                                                                            setCurrentSubBlock(
                                                                                key,
                                                                            );
                                                                        }}
                                                                    />
                                                                    {currentSubBlock ===
                                                                        key && (
                                                                        <ResizableRotatableDraggable
                                                                            left={
                                                                                curSubPos?.left
                                                                            }
                                                                            top={
                                                                                curSubPos?.top
                                                                            }
                                                                            width={
                                                                                curSubSize?.width
                                                                            }
                                                                            height={
                                                                                curSubSize?.height
                                                                            }
                                                                            rotateAngle={
                                                                                curSubAngle
                                                                            }
                                                                            // aspectRatio={true}
                                                                            minWidth={
                                                                                10
                                                                            }
                                                                            minHeight={
                                                                                10
                                                                            }
                                                                            zoomable="n, w, s, e, nw, ne, se, sw"
                                                                            onRotate={
                                                                                handleRotate
                                                                            }
                                                                            onRotateEnd={
                                                                                handleRotateEnd
                                                                            }
                                                                            onResize={
                                                                                handleResize
                                                                            }
                                                                            onResizeEnd={
                                                                                handleResizeEnd
                                                                            }
                                                                            onDrag={(
                                                                                deltaX: any,
                                                                                deltaY: any,
                                                                            ) =>
                                                                                handleDrag(
                                                                                    {
                                                                                        left:
                                                                                            curSubPos?.left +
                                                                                            deltaX,
                                                                                        top:
                                                                                            curSubPos?.top +
                                                                                            deltaY,
                                                                                    },
                                                                                )
                                                                            }
                                                                            onDragEnd={
                                                                                handleDragEnd
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            );
                                                        case V2_AVATAR_BLOCK:
                                                            return (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            userSelect:
                                                                                "none",
                                                                            background:
                                                                                "transparent",
                                                                            borderRadius:
                                                                                "100%",
                                                                            border:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? "1px solid #444"
                                                                                    : "",
                                                                            position:
                                                                                "absolute",
                                                                            top:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.top
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.top,
                                                                            left:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.left
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.left,
                                                                        }}
                                                                    >
                                                                        {item
                                                                            .data[
                                                                            V2_AVATAR_BLOCK
                                                                        ]
                                                                            ?.type ===
                                                                            "image" && (
                                                                            <img
                                                                                alt=""
                                                                                draggable="false"
                                                                                src={convertS3UrlToGcp(
                                                                                    item
                                                                                        .data[
                                                                                        V2_AVATAR_BLOCK
                                                                                    ]
                                                                                        ?.value,
                                                                                )}
                                                                                style={{
                                                                                    userSelect:
                                                                                        "none",
                                                                                    height:
                                                                                        currentSubBlock ===
                                                                                        key
                                                                                            ? curSubSize?.height
                                                                                            : item
                                                                                                  ?.other
                                                                                                  ?.size
                                                                                                  ?.height ||
                                                                                              72,
                                                                                    width:
                                                                                        currentSubBlock ===
                                                                                        key
                                                                                            ? curSubSize?.width
                                                                                            : item
                                                                                                  ?.other
                                                                                                  ?.size
                                                                                                  ?.width ||
                                                                                              72,
                                                                                    borderRadius:
                                                                                        "100%",
                                                                                }}
                                                                                onClick={() => {
                                                                                    setCurrentSubBlock(
                                                                                        key,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}

                                                                        {item
                                                                            .data[
                                                                            V2_AVATAR_BLOCK
                                                                        ]
                                                                            ?.type ===
                                                                            "video" && (
                                                                            <div
                                                                                style={{
                                                                                    height:
                                                                                        currentSubBlock ===
                                                                                        key
                                                                                            ? curSubSize?.height
                                                                                            : item
                                                                                                  ?.other
                                                                                                  ?.size
                                                                                                  ?.height ||
                                                                                              72,
                                                                                    width:
                                                                                        currentSubBlock ===
                                                                                        key
                                                                                            ? curSubSize?.width
                                                                                            : item
                                                                                                  ?.other
                                                                                                  ?.size
                                                                                                  ?.width ||
                                                                                              72,
                                                                                    overflow:
                                                                                        "hidden",
                                                                                    borderRadius:
                                                                                        "100%",
                                                                                    background:
                                                                                        "white",
                                                                                }}
                                                                                onClick={() => {
                                                                                    setCurrentSubBlock(
                                                                                        key,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ReactPlayer
                                                                                    url={convertS3UrlToGcp(
                                                                                        item
                                                                                            .data[
                                                                                            V2_AVATAR_BLOCK
                                                                                        ]
                                                                                            ?.value,
                                                                                    )}
                                                                                    playing={
                                                                                        isPlaying
                                                                                    }
                                                                                    style={{
                                                                                        height:
                                                                                            currentSubBlock ===
                                                                                            key
                                                                                                ? curSubSize?.height
                                                                                                : item
                                                                                                      ?.other
                                                                                                      ?.size
                                                                                                      ?.height ||
                                                                                                  72,
                                                                                        width:
                                                                                            currentSubBlock ===
                                                                                            key
                                                                                                ? curSubSize?.width
                                                                                                : item
                                                                                                      ?.other
                                                                                                      ?.size
                                                                                                      ?.width ||
                                                                                                  72,
                                                                                        maxHeight:
                                                                                            currentSubBlock ===
                                                                                            key
                                                                                                ? curSubSize?.height
                                                                                                : item
                                                                                                      ?.other
                                                                                                      ?.size
                                                                                                      ?.height ||
                                                                                                  72,
                                                                                        maxWidth:
                                                                                            currentSubBlock ===
                                                                                            key
                                                                                                ? curSubSize?.width
                                                                                                : item
                                                                                                      ?.other
                                                                                                      ?.size
                                                                                                      ?.width ||
                                                                                                  72,
                                                                                        userSelect:
                                                                                            "none",
                                                                                        pointerEvents:
                                                                                            "none",
                                                                                    }}
                                                                                    onError={() => {
                                                                                        message.destroy();
                                                                                        message.error(
                                                                                            "Please select a valid video",
                                                                                        );
                                                                                        setCurrentSubBlock(
                                                                                            key,
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}

                                                                        {item
                                                                            .data[
                                                                            V2_AVATAR_BLOCK
                                                                        ]
                                                                            ?.type ===
                                                                            "heygen_avatar" && (
                                                                            <div
                                                                                style={{
                                                                                    height:
                                                                                        currentSubBlock ===
                                                                                        key
                                                                                            ? curSubSize?.height
                                                                                            : item
                                                                                                  ?.other
                                                                                                  ?.size
                                                                                                  ?.height ||
                                                                                              72,
                                                                                    width:
                                                                                        currentSubBlock ===
                                                                                        key
                                                                                            ? curSubSize?.width
                                                                                            : item
                                                                                                  ?.other
                                                                                                  ?.size
                                                                                                  ?.width ||
                                                                                              72,
                                                                                    overflow:
                                                                                        "hidden",
                                                                                    borderRadius:
                                                                                        "100%",
                                                                                    background:
                                                                                        "white",
                                                                                }}
                                                                                onClick={() => {
                                                                                    setCurrentSubBlock(
                                                                                        key,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ReactPlayer
                                                                                    url={convertS3UrlToGcp(
                                                                                        item
                                                                                            .data[
                                                                                            V2_AVATAR_BLOCK
                                                                                        ]
                                                                                            ?.heygen
                                                                                            ?.video,
                                                                                    )}
                                                                                    playing={
                                                                                        isPlaying
                                                                                    }
                                                                                    className="heygen-avatar"
                                                                                    style={{
                                                                                        height:
                                                                                            currentSubBlock ===
                                                                                            key
                                                                                                ? curSubSize?.height
                                                                                                : item
                                                                                                      ?.other
                                                                                                      ?.size
                                                                                                      ?.height ||
                                                                                                  72,
                                                                                        width:
                                                                                            currentSubBlock ===
                                                                                            key
                                                                                                ? curSubSize?.width
                                                                                                : item
                                                                                                      ?.other
                                                                                                      ?.size
                                                                                                      ?.width ||
                                                                                                  72,
                                                                                        maxHeight:
                                                                                            currentSubBlock ===
                                                                                            key
                                                                                                ? 1.38 *
                                                                                                  curSubSize?.height
                                                                                                : 1.38 *
                                                                                                      item
                                                                                                          ?.other
                                                                                                          ?.size
                                                                                                          ?.height ||
                                                                                                  100,
                                                                                        maxWidth:
                                                                                            currentSubBlock ===
                                                                                            key
                                                                                                ? curSubSize?.width
                                                                                                : item
                                                                                                      ?.other
                                                                                                      ?.size
                                                                                                      ?.width ||
                                                                                                  72,
                                                                                        userSelect:
                                                                                            "none",
                                                                                        pointerEvents:
                                                                                            "none",
                                                                                        objectFit:
                                                                                            "cover",
                                                                                    }}
                                                                                    onError={() => {
                                                                                        message.destroy();
                                                                                        message.error(
                                                                                            "Please select a valid video",
                                                                                        );
                                                                                        setCurrentSubBlock(
                                                                                            key,
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {currentSubBlock ===
                                                                        key &&
                                                                        !disableWrite && (
                                                                            <ResizableRotatableDraggable
                                                                                zoomable="n, w, s, e, nw, ne, se, sw"
                                                                                left={
                                                                                    curSubPos?.left
                                                                                }
                                                                                top={
                                                                                    curSubPos?.top
                                                                                }
                                                                                width={
                                                                                    curSubSize?.width ||
                                                                                    72
                                                                                }
                                                                                height={
                                                                                    curSubSize?.height ||
                                                                                    72
                                                                                }
                                                                                minWidth={
                                                                                    10
                                                                                }
                                                                                minHeight={
                                                                                    10
                                                                                }
                                                                                aspectRatio={
                                                                                    true
                                                                                }
                                                                                onDrag={(
                                                                                    deltaX: any,
                                                                                    deltaY: any,
                                                                                ) =>
                                                                                    handleDrag(
                                                                                        {
                                                                                            left:
                                                                                                curSubPos?.left +
                                                                                                deltaX,
                                                                                            top:
                                                                                                curSubPos?.top +
                                                                                                deltaY,
                                                                                        },
                                                                                    )
                                                                                }
                                                                                onDragEnd={
                                                                                    handleDragEnd
                                                                                }
                                                                                onResize={
                                                                                    handleResize
                                                                                }
                                                                                onResizeEnd={
                                                                                    handleResizeEnd
                                                                                }
                                                                            />
                                                                        )}
                                                                </>
                                                            );
                                                        case BUTTON_BLOCK:
                                                            return (
                                                                <>
                                                                    <div
                                                                        onClick={() => {
                                                                            if (
                                                                                disableWrite
                                                                            )
                                                                                return;
                                                                            setCurrentSubBlock(
                                                                                key,
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            userSelect:
                                                                                "none",
                                                                            border:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? "1px solid #444"
                                                                                    : "",
                                                                            padding:
                                                                                "12px",
                                                                            position:
                                                                                "absolute",
                                                                            top:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.top
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.top,
                                                                            left:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.left
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.left,
                                                                            height:
                                                                                (currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.height
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          ?.height) ||
                                                                                84,
                                                                            width:
                                                                                (currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.width
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          ?.width) ||
                                                                                412,
                                                                            // width: "100%",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                color: get(
                                                                                    item,
                                                                                    [
                                                                                        "data",
                                                                                        BUTTON_BLOCK,
                                                                                        "color",
                                                                                    ],
                                                                                ),
                                                                                fontSize:
                                                                                    get(
                                                                                        item,
                                                                                        [
                                                                                            "data",
                                                                                            BUTTON_BLOCK,
                                                                                            "font_size",
                                                                                        ],
                                                                                    ) +
                                                                                    "px",
                                                                                fontWeight:
                                                                                    get(
                                                                                        item,
                                                                                        [
                                                                                            "data",
                                                                                            BUTTON_BLOCK,
                                                                                            "font_weight",
                                                                                        ],
                                                                                    ),
                                                                                backgroundColor:
                                                                                    get(
                                                                                        item,
                                                                                        [
                                                                                            "data",
                                                                                            BUTTON_BLOCK,
                                                                                            "background_color",
                                                                                        ],
                                                                                    ),

                                                                                height: "100%",
                                                                                borderRadius:
                                                                                    "8px",
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "center",
                                                                                alignItems:
                                                                                    "center",
                                                                                boxShadow:
                                                                                    "2px 2px 16px rgba(0, 0, 0, 0.16)",
                                                                            }}
                                                                        >
                                                                            {
                                                                                item
                                                                                    .data
                                                                                    ?.button
                                                                                    ?.text
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {currentSubBlock ===
                                                                        key && (
                                                                        <ResizableRotatableDraggable
                                                                            left={
                                                                                curSubPos?.left
                                                                            }
                                                                            top={
                                                                                curSubPos?.top
                                                                            }
                                                                            width={
                                                                                curSubSize?.width ||
                                                                                412
                                                                            }
                                                                            height={
                                                                                curSubSize?.height ||
                                                                                84
                                                                            }
                                                                            // aspectRatio={true}
                                                                            minWidth={
                                                                                10
                                                                            }
                                                                            minHeight={
                                                                                10
                                                                            }
                                                                            zoomable="n, w, s, e, nw, ne, se, sw"
                                                                            onResize={
                                                                                handleResize
                                                                            }
                                                                            onResizeEnd={
                                                                                handleResizeEnd
                                                                            }
                                                                            onDrag={(
                                                                                deltaX: any,
                                                                                deltaY: any,
                                                                            ) =>
                                                                                handleDrag(
                                                                                    {
                                                                                        left:
                                                                                            curSubPos?.left +
                                                                                            deltaX,
                                                                                        top:
                                                                                            curSubPos?.top +
                                                                                            deltaY,
                                                                                    },
                                                                                )
                                                                            }
                                                                            onDragEnd={
                                                                                handleDragEnd
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            );

                                                        case INPUT_BLOCK:
                                                        case RICHTEXT_INPUT_BLOCK:
                                                            return (
                                                                <>
                                                                    <div
                                                                        onClick={() => {
                                                                            if (
                                                                                disableWrite
                                                                            )
                                                                                return;
                                                                            setCurrentSubBlock(
                                                                                key,
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            // display:
                                                                            //     "flex",
                                                                            userSelect:
                                                                                "none",
                                                                            // alignItems:
                                                                            // "center",
                                                                            padding:
                                                                                " 16px 8px",
                                                                            position:
                                                                                "absolute",
                                                                            borderRadius:
                                                                                "2px",
                                                                            border: "0.5px solid #4F4F4F",
                                                                            background:
                                                                                "#F9F8F8",
                                                                            top:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.top
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.top,
                                                                            left:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.left
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.left,
                                                                            height:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.height
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          ?.height,
                                                                            width:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.width
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          ?.width,
                                                                            // width: "100%",
                                                                            color: item
                                                                                ?.data
                                                                                ?.input
                                                                                ?.default_value
                                                                                ? "#000"
                                                                                : "#828282",
                                                                            fontSize:
                                                                                "16px",
                                                                            fontStyle:
                                                                                "normal",
                                                                            fontWeight:
                                                                                item
                                                                                    ?.data
                                                                                    ?.input
                                                                                    ?.default_value
                                                                                    ? 400
                                                                                    : 200,
                                                                            minHeight:
                                                                                "56px",
                                                                        }}
                                                                    >
                                                                        {item
                                                                            ?.data
                                                                            ?.input
                                                                            ?.default_value ? (
                                                                            item.type !==
                                                                            INPUT_BLOCK ? (
                                                                                <MathRender
                                                                                    src={`$${item?.data?.input?.default_value}$`}
                                                                                />
                                                                            ) : (
                                                                                item
                                                                                    ?.data
                                                                                    ?.input
                                                                                    ?.default_value
                                                                            )
                                                                        ) : (
                                                                            item
                                                                                ?.data
                                                                                ?.input
                                                                                ?.placeholder
                                                                        )}
                                                                    </div>
                                                                    {currentSubBlock ===
                                                                        key && (
                                                                        <ResizableRotatableDraggable
                                                                            left={
                                                                                curSubPos?.left
                                                                            }
                                                                            top={
                                                                                curSubPos?.top
                                                                            }
                                                                            width={
                                                                                curSubSize?.width
                                                                            }
                                                                            height={
                                                                                curSubSize?.height
                                                                            }
                                                                            // aspectRatio={true}
                                                                            minWidth={
                                                                                56
                                                                            }
                                                                            minHeight={
                                                                                56
                                                                            }
                                                                            zoomable="n, w, s, e, nw, ne, se, sw"
                                                                            onResize={
                                                                                handleResize
                                                                            }
                                                                            onResizeEnd={
                                                                                handleResizeEnd
                                                                            }
                                                                            onDrag={(
                                                                                deltaX: any,
                                                                                deltaY: any,
                                                                            ) =>
                                                                                handleDrag(
                                                                                    {
                                                                                        left:
                                                                                            curSubPos?.left +
                                                                                            deltaX,
                                                                                        top:
                                                                                            curSubPos?.top +
                                                                                            deltaY,
                                                                                    },
                                                                                )
                                                                            }
                                                                            onDragEnd={
                                                                                handleDragEnd
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            );
                                                        case MATH_INPUT_BLOCK:
                                                            return (
                                                                <>
                                                                    <div
                                                                        onClick={() => {
                                                                            if (
                                                                                disableWrite
                                                                            )
                                                                                return;
                                                                            setCurrentSubBlock(
                                                                                key,
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            // display:
                                                                            //     "flex",
                                                                            userSelect:
                                                                                "none",
                                                                            // alignItems:
                                                                            // "center",
                                                                            padding:
                                                                                "16px 8px",
                                                                            position:
                                                                                "absolute",
                                                                            background:
                                                                                "#fff",
                                                                            top:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.top
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.top,
                                                                            left:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.left
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.left,
                                                                            height:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.height
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          ?.height,
                                                                            width:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.width
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          ?.width,
                                                                            // width: "100%",
                                                                            color: "#000",
                                                                            fontSize:
                                                                                "16px",
                                                                            fontStyle:
                                                                                "normal",
                                                                            fontWeight: 400,
                                                                            minHeight:
                                                                                "56px",
                                                                        }}
                                                                    >
                                                                        <MathRender
                                                                            src={
                                                                                item
                                                                                    ?.data
                                                                                    ?.input
                                                                                    ?.default_value
                                                                                    ? `$${item?.data?.input?.default_value}$`
                                                                                    : ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {currentSubBlock ===
                                                                        key && (
                                                                        <ResizableRotatableDraggable
                                                                            left={
                                                                                curSubPos?.left
                                                                            }
                                                                            top={
                                                                                curSubPos?.top
                                                                            }
                                                                            width={
                                                                                curSubSize?.width
                                                                            }
                                                                            height={
                                                                                curSubSize?.height
                                                                            }
                                                                            // aspectRatio={true}
                                                                            minWidth={
                                                                                56
                                                                            }
                                                                            minHeight={
                                                                                56
                                                                            }
                                                                            zoomable="n, w, s, e, nw, ne, se, sw"
                                                                            onResize={
                                                                                handleResize
                                                                            }
                                                                            onResizeEnd={
                                                                                handleResizeEnd
                                                                            }
                                                                            onDrag={(
                                                                                deltaX: any,
                                                                                deltaY: any,
                                                                            ) =>
                                                                                handleDrag(
                                                                                    {
                                                                                        left:
                                                                                            curSubPos?.left +
                                                                                            deltaX,
                                                                                        top:
                                                                                            curSubPos?.top +
                                                                                            deltaY,
                                                                                    },
                                                                                )
                                                                            }
                                                                            onDragEnd={
                                                                                handleDragEnd
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            );
                                                        case V2_COUNTDOWN_BLOCK:
                                                        case V2_TEXT_BLOCK:
                                                            return (
                                                                <>
                                                                    <div
                                                                        onClick={() => {
                                                                            if (
                                                                                disableWrite
                                                                            )
                                                                                return;
                                                                            setCurrentSubBlock(
                                                                                key,
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            padding:
                                                                                "20px",
                                                                            position:
                                                                                "absolute",
                                                                            userSelect:
                                                                                "none",
                                                                            textAlign:
                                                                                item
                                                                                    ?.data
                                                                                    ?.textAlign ||
                                                                                "left",
                                                                            top:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.top
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.top,
                                                                            left:
                                                                                currentSubBlock ===
                                                                                key
                                                                                    ? curSubPos?.left
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.position
                                                                                          ?.left,

                                                                            height:
                                                                                (currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.height
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          ?.height) ||
                                                                                100,
                                                                            width:
                                                                                (currentSubBlock ===
                                                                                key
                                                                                    ? curSubSize?.width
                                                                                    : item
                                                                                          ?.other
                                                                                          ?.size
                                                                                          ?.width) ||
                                                                                300,
                                                                        }}
                                                                    >
                                                                        {renderer(
                                                                            item
                                                                                .data[
                                                                                item
                                                                                    .type
                                                                            ],
                                                                            {},
                                                                        )}
                                                                    </div>
                                                                    {currentSubBlock ===
                                                                        key && (
                                                                        <>
                                                                            <ResizableRotatableDraggable
                                                                                zoomable="n, w, s, e, nw, ne, se, sw"
                                                                                left={
                                                                                    curSubPos?.left
                                                                                }
                                                                                top={
                                                                                    curSubPos?.top
                                                                                }
                                                                                width={
                                                                                    curSubSize?.width ||
                                                                                    300
                                                                                }
                                                                                height={
                                                                                    curSubSize?.height ||
                                                                                    100
                                                                                }
                                                                                onResize={
                                                                                    handleResize
                                                                                }
                                                                                onResizeEnd={
                                                                                    handleResizeEnd
                                                                                }
                                                                                onDragEnd={
                                                                                    handleDragEnd
                                                                                }
                                                                                onDrag={(
                                                                                    deltaX: any,
                                                                                    deltaY: any,
                                                                                ) =>
                                                                                    handleDrag(
                                                                                        {
                                                                                            left:
                                                                                                curSubPos?.left +
                                                                                                deltaX,
                                                                                            top:
                                                                                                curSubPos?.top +
                                                                                                deltaY,
                                                                                        },
                                                                                    )
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </>
                                                            );
                                                        default:
                                                            return <></>;
                                                    }
                                                },
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                <div
                                    style={{
                                        maxWidth: "350px",
                                    }}
                                >
                                    <h3>Sub Block Editor</h3>
                                    <hr />
                                    <div
                                        style={{ display: "flex", gap: "10px" }}
                                    >
                                        <Popconfirm
                                            title={"Delete this?"}
                                            icon={
                                                <QuestionCircleOutlined
                                                    style={{ color: "red" }}
                                                />
                                            }
                                            onConfirm={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                const tmpOptions = [
                                                    ...children,
                                                ];
                                                _.pullAt(
                                                    tmpOptions,
                                                    currentSubBlock,
                                                );
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["data", "children"],
                                                    tmpOptions,
                                                );

                                                setBlock(tmpBlock);
                                                setCurrentSubBlock(-1);
                                            }}
                                            onCancel={() => {}}
                                            okText={"Yes"}
                                            cancelText={"Cancel"}
                                        >
                                            <Button
                                                danger
                                                disabled={disableWrite}
                                            >
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                        {(children?.some(
                                            (item: {
                                                type: string;
                                                data: {
                                                    [x: string]: {
                                                        type: string;
                                                    };
                                                };
                                            }) =>
                                                item?.type ===
                                                    V2_AVATAR_BLOCK &&
                                                item?.data[V2_AVATAR_BLOCK]
                                                    ?.type === "video",
                                        ) ||
                                            video) && (
                                            <Button
                                                type="primary"
                                                onClick={() =>
                                                    setIsPLaying(!isPlaying)
                                                }
                                            >
                                                {isPlaying ? "Pause" : "Play"}{" "}
                                                the Videos
                                            </Button>
                                        )}
                                    </div>
                                    <hr />
                                    {!isReadOnlyMode &&
                                        children[currentSubBlock]?.type ===
                                            V2_AVATAR_BLOCK && (
                                            <EditorAvatar {...childProps} />
                                        )}
                                    {!disableWrite && !loading && (
                                        <>
                                            {children[currentSubBlock]?.type ===
                                                IMAGE_BLOCK && (
                                                <EditorImage {...childProps} />
                                            )}
                                            {children[currentSubBlock]?.type ===
                                                V2_TEXT_BLOCK && (
                                                <EditorText {...childProps} />
                                            )}
                                            {children[currentSubBlock]?.type ===
                                                V2_COUNTDOWN_BLOCK && (
                                                <EditorCountDown
                                                    {...childProps}
                                                />
                                            )}
                                            {children[currentSubBlock]?.type ===
                                                BUTTON_BLOCK && (
                                                <EditorButton {...childProps} />
                                            )}
                                            {[
                                                INPUT_BLOCK,
                                                RICHTEXT_INPUT_BLOCK,
                                                MATH_INPUT_BLOCK,
                                            ].includes(
                                                children[currentSubBlock]?.type,
                                            ) && (
                                                <EditorInput {...childProps} />
                                            )}
                                            {currentSubBlock ===
                                                children.length && (
                                                <PlaceholderField
                                                    label={"Background"}
                                                    typeLabel={"Type"}
                                                    preview={false}
                                                    disabled={isReadOnlyMode}
                                                    typePath={[
                                                        "data",
                                                        V2_STORY_BLOCK,
                                                        "background",
                                                        "type",
                                                    ]}
                                                    path={[
                                                        "data",
                                                        V2_STORY_BLOCK,
                                                        "background",
                                                        "value",
                                                    ]}
                                                    defaultPath={[
                                                        "data",
                                                        V2_STORY_BLOCK,
                                                        "background",
                                                        "default",
                                                    ]}
                                                    loopPath={[
                                                        "data",
                                                        V2_STORY_BLOCK,
                                                        "background",
                                                        "loop",
                                                    ]}
                                                    block={block}
                                                    setBlock={setBlock}
                                                    useGamlet={true}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </KTWrapper>
        </div>
    );
};

export default EditorStory;
