import { Button, Card, message } from "antd";
import React, { useState } from "react";
import Microphone from "./Microphone";
import { captureException } from "@sentry/react";
import axios from "axios";

export const getPresignedURL = async (
    path = "",
    extension = "",
    contentType = "application/octet-stream",
) => {
    const provider = process.env.REACT_APP_BUCKET_PROVIDER || "gcp"; // "aws" or "gcp"
    let url, data, bucket_name;

    if (provider === "aws") {
        bucket_name = process.env.REACT_APP_BUCKET_NAME;
        url = process.env.REACT_APP_S3_UPLOAD_LAMDA_FUNCTION;

        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };
        if (!url || !bucket_name) {
            console.log("url or bucket name missing");
            return;
        }
        const response = await fetch(url, options);
        const resObj = await response.json();
        return resObj;
    } else if (provider === "gcp") {
        bucket_name = process.env.REACT_APP_GCP_BUCKET_NAME;
        url = process.env.REACT_APP_GCP_UPLOAD_LAMDA_FUNCTION;
    } else {
        bucket_name = process.env.REACT_APP_GCP_BUCKET_NAME;
        url = process.env.REACT_APP_GCP_UPLOAD_LAMDA_FUNCTION;
    }

    data = {
        bucket_name: bucket_name,
        key: process.env.REACT_APP_BUCKET_BASE + path + Date.now() + extension,
        contentType: contentType,
    };

    if (!url || !bucket_name) {
        console.log("url or bucket name missing");
        return;
    }

    const response = await axios.post(url, data);
    const resObj = response.data;
    return resObj;
};

export const RenderOnlyRecord = (props: any) => {
    const [fileList, setFileList] = useState<any>(
        props.value
            ? [
                  {
                      type: "record",
                      name:
                          props.uploadType !== "custom" ? "image.png" : "file",
                      status: "done",
                      url: props.value,
                      original: true,
                      selected: false,
                  },
              ]
            : [{ type: "record", url: "" }],
    );

    const pushFile = async (audiofile, fileBlob, index) => {
        const files = [...fileList];
        const file = new File([fileBlob], "audio.mp3", {
            type: "audio/mpeg",
        });
        const url = URL.createObjectURL(fileBlob);
        const newFile = {
            ...audiofile,
            file,
            url,
        };
        files[index] = newFile;
        setFileList(files);
    };
    const handleFinal = async (selectedFile: number) => {
        message.loading("Please wait...");
        const selected = fileList[selectedFile];
        const file = selected.file;
        if (props.value && selectedFile == 0 && selected?.original == true) {
            props.setEditState && props.setEditState(false);
            setFileList([selected]);
            return true;
        }
        let extension = "";

        if (props.addExtension) {
            extension = file.name.split(".").pop();
            if (extension.length > 0) extension = "." + extension;
        }
        try {
            const { url, bucket_url } = await getPresignedURL(
                props.path,
                extension || props.extension || "",
                file?.type || "application/octet-stream",
            );
            const xhr = new XMLHttpRequest();
            const contentType = file?.type || "application/octet-stream";
            xhr.open("PUT", url);
            xhr.setRequestHeader("Content-Type", contentType);

            xhr.onreadystatechange = async () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        updateForm(bucket_url);
                    } else {
                        message.error("Upload failed!");
                    }
                }
            };
            xhr.send(file);
        } catch (error) {
            console.log("Error uploading file:", error);
            message.error("Something went wrong!");
        }

        message.destroy();
        message.success("Success!");
    };

    const updateForm = (bucket_url: string) => {
        if (props.onChangeCustom) {
            props.onChangeCustom({
                target: {
                    value: bucket_url,
                    name: props.name,
                },
            });
        } else {
            props.formik?.handleChange({
                target: {
                    value: bucket_url,
                    name: props.name,
                },
            });
        }
    };
    return (
        <>
            <Card style={{ background: "#e6e6e6", width: "100%" }} hoverable>
                {fileList.map((f, index) => (
                    <Microphone
                        audiofile={f}
                        index={index + 1}
                        pushFile={(fileBlob) => {
                            pushFile(f, fileBlob, index);
                        }}
                        onChangeAudio={async () => {
                            if (fileList[index].selected) return;
                            try {
                                await handleFinal(index);
                                setFileList((prev) => {
                                    prev[index].selected = true;
                                    return prev;
                                });
                            } catch (e) {
                                captureException(e);
                                console.log("Error:", e);
                                message.destroy();
                                message.error("Something went wrong!");
                            }
                        }}
                        isSelected={f.selected}
                        handleDelete={() => {
                            const files = [...fileList];
                            files.splice(index, 1);
                            setFileList(files);
                        }}
                    />
                ))}
                <Button
                    onClick={() =>
                        setFileList((prev) => [
                            ...prev,
                            { type: "record", url: "" },
                        ])
                    }
                >
                    Add
                </Button>
            </Card>
        </>
    );
};
