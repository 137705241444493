import React, { useEffect, useState } from "react";
import Settings from "./Settings";
import {
    Button,
    Card,
    Col,
    Collapse,
    Divider,
    Form,
    Input,
    Row,
    message,
} from "antd";
import _, { cloneDeep, set } from "lodash";
import { DeleteOutlined } from "@ant-design/icons";
import { RenderUpload, SlateEditor } from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";

const MultiplayerButtonEditor = (props) => {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
    } = props;

    const {
        id,
        tmpId,
        data: { text, disabled, hidden, style },
    } = block;
    const [tempConfig, setTempConfig] = useState("");

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px",
            }}
        >
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
            >
                <Row>
                    <Col span={24}>
                        <h3>Button Editor</h3>
                    </Col>
                    <Col span={24}>
                        <h4>Button Settings</h4>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Button text">
                            <Input
                                disabled={disableWrite}
                                value={text}
                                onChange={(e) => {
                                    const newBlock = cloneDeep(block);
                                    set(newBlock, "data.text", e.target.value);
                                    setBlock(newBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Button disabled">
                            <Input
                                type="checkbox"
                                checked={disabled}
                                onChange={(e) => {
                                    const newBlock = cloneDeep(block);
                                    set(
                                        newBlock,
                                        "data.disabled",
                                        e.target.checked,
                                    );
                                    setBlock(newBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Button hidden">
                            <Input
                                type="checkbox"
                                checked={hidden}
                                onChange={(e) => {
                                    const newBlock = cloneDeep(block);
                                    set(
                                        newBlock,
                                        "data.hidden",
                                        e.target.checked,
                                    );
                                    setBlock(newBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Button style">
                            <Input.TextArea
                                disabled={disableWrite}
                                value={
                                    typeof style === "string"
                                        ? style
                                        : JSON.stringify(style || {})
                                }
                                onChange={(e) => {
                                    // value will be object
                                    // convert to object
                                    let value = e.target.value;
                                    if (value) {
                                        try {
                                            value = JSON.parse(value);
                                        } catch (e) {
                                            // message.error("Invalid JSON");
                                            // return;
                                        }
                                    }
                                    const newBlock = cloneDeep(block);
                                    set(newBlock, "data.style", value);
                                    setBlock(newBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item label="Button preview">
                        <button
                            disabled={disabled}
                            style={typeof style === "object" ? style : {}}
                        >
                            {text}
                        </button>
                    </Form.Item>
                </Row>
            </Card>
        </div>
    );
};

export default MultiplayerButtonEditor;
