import {
    AppstoreAddOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    MenuOutlined,
} from "@ant-design/icons";
import { EditIcon } from "src/components";
import DeleteView from "src/modules/document/components/DeleteView";
import { Can } from "src/services/casl";
import { Button, Col, Divider, Image, Row, Space, Spin, Table } from "antd";
import { arrayMoveImmutable } from "array-move";
import { Link } from "src/components";
import { useRouter } from "src/helpers";
import React, { useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const ListView: React.FC = (props: any) => {
    const {
        loading,
        documents,
        group_id,
        group_title,
        redirect_to,
        updateGroupTabMap,
        disableAddButton,
    } = props;
    const [dataSource, setDataSource] = useState([]);
    const { query, isReady } = useRouter();

    // const [totalSetCount, setTotalSetCount] = useState(0)
    useEffect(() => {
        if (documents.length) {
            // console.log(topics.reduce((acc, cur) => acc+cur.collections.length, 0));
            // setTotalSetCount(topics.reduce((acc, cur) => acc+cur.collections.length, 0)) ;
            // console.log(documents);
            const data = documents
                .map((t, index) => {
                    return {
                        ...t,
                        index,
                        order: t.groups[0]?.order || -1,
                        group_document_map_id: t.groups[0]?.id,
                    };
                })
                .sort((a, b) => {
                    if (a.status === b.status) return a.order - b.order;
                    else return a.status > b.status ? 1 : -1;
                });
            setDataSource(data);
        }
    }, [documents]);

    // console.log({loading})

    if (!isReady) return <div>Loading...</div>;

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
    ));

    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(
                [].concat(dataSource),
                oldIndex,
                newIndex,
            ).filter((el) => !!el);

            newData.map(async (d, index) => {
                if (d.order !== index + 1) {
                    console.log(
                        "Updating order: ",
                        d.group_document_map_id,
                        index + 1,
                    );
                    // for topic set_id will actually be topic_id
                    // updateChapterOther({
                    //     other: {
                    //         order: index + 1,
                    //     },
                    //     id: d.id,
                    // });

                    await updateGroupTabMap({
                        id: d.group_document_map_id,
                        _set: { order: index + 1 },
                    });

                    newData[index].order = index;
                }
            });

            setDataSource(newData);
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helper="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"],
        );

        return <SortableItem index={index} {...restProps} />;
    };
    const columns = [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 15,
            className: "drag-visible",
            render: () => <DragHandle />,
        },

        {
            title: "Cover",
            key: "cover",
            render: (text, record) =>
                record?.banner ? (
                    <Image src={convertS3UrlToGcp(record?.banner)} width={70} />
                ) : (
                    "no cover"
                ),
            // fixed: "left",
            width: 200,
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) =>
                record?.status == "active" ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                ),
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => (
                <Space size="small">
                    <Can I="edit" a={"document"} passThrough>
                        {(allowed) => (
                            <Link
                                href={{
                                    pathname: `/document/update/${record?.id}`,
                                    query: {
                                        redirect_to: encodeURI(redirect_to),
                                    },
                                }}
                            >
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    <Divider />
                    <Can I="delete" a={"document"} passThrough>
                        {(allowed) => (
                            <DeleteView
                                id={record?.id}
                                isButton={true}
                                disabled={!allowed}
                            />
                        )}
                    </Can>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>
                        Documents for {group_title} ({dataSource.length})
                    </h1>
                </Col>
                <Col>
                    <Can I="create" a={"document"}>
                        {(allowed) => (
                            <Link
                                href={{
                                    pathname: `/document/create/${group_id}`,
                                    query: {
                                        redirect_to: encodeURI(redirect_to),
                                        order: documents.length + 1,
                                    },
                                }}
                            >
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add Document
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isDocument={true} {...props} /> */}
            {/* <hr /> */}

            <Spin spinning={loading}>
                {Boolean(documents?.length) && (
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={dataSource || []}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                    />
                )}
            </Spin>
        </>
    );
};

export default ListView;
