import React, { useState } from "react";
import { Link } from "src/components";
import { Image, Button, Col, Divider, message, Row, Space, Spin } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/book/components/DeleteView";
import {
    EditIcon,
    ViewIcon,
    CopyIcon,
    RenderTable,
    Layout,
    MetaTag,
} from "src/components";
import { Can } from "src/services/casl";

import { FilterComponent } from "src/modules/book/components";
import { useRouter } from "src/helpers";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";
const ListView: React.FC = (props: any) => {
    const { loading, chapters, chapterAggregate, loadChaptersData } = props;
    const { query, isReady } = useRouter();
    const { book_id } = query;

    if (!isReady) return <div>Loading...</div>;

    const columns = [
        {
            title: "Id",
            key: "id",
            render: (text, record) => record?.id,
            fixed: "left",
        },
        {
            title: "Cover",
            key: "cover",
            render: (text, record) =>
                record?.cover ? (
                    <Image src={convertS3UrlToGcp(record?.cover)} width={50} />
                ) : (
                    "no cover"
                ),
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
            fixed: "left",
            width: 200,
        },
        {
            title: "Set Count",
            key: "set_count",
            render: (text, record) => record?.other?.set_count,
        },
        {
            title: "Chapter Number",
            key: "chapter_number",
            render: (text, record) => record?.other?.chapter_number,
        },

        {
            title: "Status",
            key: "status",
            render: (text, record) => record?.status,
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            fixed: "right",
            width: 200,
            render: (text, record) => (
                <Space size="middle">
                    {/* <CopyIcon
                        isButton={true}
                        onClick={() => {
                            message.info(`Copied chapter link to clipboard!`);
                            navigator.clipboard.writeText(
                                `${process.env.REACT_APP_CLIENT_BASE}/chapter/${record.id}`,
                            );
                        }}
                    />
                    <Divider /> */}
                    <Link href={`/book/topic/${record?.id}`}>
                        <ViewIcon isButton={true} />
                    </Link>
                    <Divider />

                    <Can I="edit" a={"chapter"} passThrough>
                        {(allowed) => (
                            <Link
                                href={`/book/chapter/update/${record?.id}?book_id=${book_id}`}
                            >
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    <Divider />
                    <Can I="delete" a={"chapter"} passThrough>
                        {(allowed) => (
                            <DeleteView
                                id={record?.id}
                                isButton={true}
                                disabled={!allowed}
                                record={record}
                            />
                        )}
                    </Can>
                </Space>
            ),
        },
    ];
    return (
        <Layout titles={[{ name: "Chapters", link: "/chapter" }]}>
            <MetaTag title="Chapters" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Chapters List</h1>
                </Col>
                <Col>
                    <Can I="create" a={"chapter"} passThrough>
                        {(allowed) => (
                            <Link href={`/book/chapter/create/${book_id}`}>
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isChapter={false} {...props} />
            <hr /> */}

            <Spin spinning={loading}>
                {Boolean(chapters?.length) && (
                    <RenderTable
                        loading={loading}
                        columns={columns}
                        data={
                            chapters
                                .slice()
                                .sort(
                                    (a: any, b: any) =>
                                        a.other?.chapter_number -
                                        b.other?.chapter_number,
                                ) || []
                        }
                        loadData={loadChaptersData}
                        aggregate={chapterAggregate?.aggregate?.count}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
