import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row } from "antd";
import EditIcon from "src/components/EditIcon";
import { CheckOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { RenderUpload, RenderUploadYT } from "src/components/form";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const { TextArea } = Input;

const VideoField = (props: any) => {
    const {
        block,
        setBlock,
        path,
        label,
        preview = true,
        isYoutube = false,
        toggleShouldBlockUI,
        isS3 = false,
        disabled,
        useGamlet = false,
    } = props;
    const videoUrl = get(block, path);

    const [isEditState, setEditState] = useState(!get(block, path));
    const [isTextField, setTextField] = useState(true);

    useEffect(() => setTextField(true), []);

    return (
        <Row
            style={{
                marginTop: "20px",
            }}
        >
            <Col span={24}>
                {isEditState || !preview ? (
                    <>
                        <h4>{label}</h4>
                        {(isYoutube || isS3) && (
                            <Button
                                disabled={disabled}
                                type="primary"
                                ghost
                                onClick={() => setTextField(!isTextField)}
                                style={{
                                    marginBottom: "20px",
                                }}
                            >
                                {!isTextField
                                    ? "Add via text field"
                                    : isS3
                                    ? "Add via Upload"
                                    : "Upload via Youtube"}
                            </Button>
                        )}
                        <div style={{ display: "flex" }}>
                            {!isTextField ? (
                                isYoutube ? (
                                    <RenderUploadYT
                                        setVideoUrl={(value: any) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                path,
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        videoUrl={videoUrl}
                                        toggleShouldBlockUI={
                                            toggleShouldBlockUI
                                        }
                                        disabled={disabled}
                                    />
                                ) : (
                                    <RenderUpload
                                        path="home-explore/document/"
                                        onChangeCustom={(e: any) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                path,
                                                e.target.value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        label="upload a video"
                                        value={videoUrl}
                                        required={true}
                                        uploadType="custom"
                                        singleUpload={true}
                                        onRemove={() => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(tmpBlock, path, "");
                                            setBlock(tmpBlock);
                                        }}
                                        meta={{ touched: true }}
                                        disabled={disabled}
                                        useGamlet={useGamlet}
                                    />
                                )
                            ) : (
                                <TextArea
                                    rows={10}
                                    disabled={disabled}
                                    value={get(block, path) || ""}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(tmpBlock, path, value);
                                        setBlock(tmpBlock);
                                    }}
                                />
                            )}
                            {preview && videoUrl && (
                                <Button
                                    icon={
                                        <CheckOutlined
                                            onClick={() => setEditState(false)}
                                        />
                                    }
                                    disabled={disabled}
                                    shape="circle"
                                    type="primary"
                                    style={{ marginLeft: "10px" }}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    preview && (
                        <div style={{ display: "flex" }}>
                            <div>
                                <h4>{label}</h4>
                                <ReactPlayer
                                    url={convertS3UrlToGcp(videoUrl)}
                                    style={{ maxWidth: "100%" }}
                                    controls
                                />
                            </div>
                            {videoUrl && (
                                <EditIcon
                                    disabled={disabled}
                                    isButton={true}
                                    onClick={() => setEditState(true)}
                                    style={{ marginLeft: "10px" }}
                                />
                            )}
                        </div>
                    )
                )}
            </Col>
        </Row>
    );
};

export default VideoField;
