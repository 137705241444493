import { captureException } from "@sentry/react";
import { Button, Input, Popconfirm, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import ReactPlayer from "react-player";

const RenderUploadYT = ({
    setVideoUrl,
    videoUrl,
    toggleShouldBlockUI,
    disabled,
}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [youtubeVideo, setYTVIdeo] = useState(videoUrl);

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            message.warn("Please select a file!");
            return;
        }

        if (toggleShouldBlockUI) toggleShouldBlockUI(true);
        try {
            const formData = new FormData();
            formData.append("file", selectedFile);

            const response = await axios.post(
                `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/uploadYouTubeVideo`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );

            setVideoUrl(response.data.videoUrl);
            setYTVIdeo(response.data.videoUrl);
        } catch (error) {
            captureException(error);
            message.error(
                "Could not upload the file! Please add through text field.",
            );
        } finally {
            if (toggleShouldBlockUI) {
                setTimeout(() => toggleShouldBlockUI(false), 200);
            }
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    marginBottom: "20px",
                    width: "100%",
                }}
            >
                <Input
                    type="file"
                    onChange={handleFileSelect}
                    accept="video/*"
                    disabled={disabled}
                />
                <Button
                    type="primary"
                    disabled={disabled}
                    onClick={handleUpload}
                >
                    Upload Video
                </Button>
            </div>

            {youtubeVideo && (
                <>
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <div
                            style={{
                                fontSize: "16px",
                            }}
                        >
                            {"Preview"}
                        </div>
                        <Popconfirm
                            disabled={disabled}
                            title={"Remove this video?"}
                            onConfirm={() => {
                                setVideoUrl("");
                                setYTVIdeo("");
                            }}
                            okText={"Yes"}
                            cancelText={"Cancel"}
                        >
                            <Button disabled={disabled} danger>
                                Remove
                            </Button>
                        </Popconfirm>
                    </div>

                    <ReactPlayer
                        url={convertS3UrlToGcp(youtubeVideo)}
                        style={{ maxWidth: "100%" }}
                        controls
                    />
                </>
            )}
        </div>
    );
};

export default RenderUploadYT;
