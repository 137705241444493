import React, { useState } from "react";
import { setNodes, unsetNodes } from "@udecode/plate-core";
import { Transforms } from "slate";
import { getRootProps } from "@udecode/plate-styled-components";

import { ReactEditor, useFocused, useSelected } from "slate-react";
import { VideoInsertComponent } from "../VideoInsertComponent";
import MathRender from "src/components/MathRender";
import ReactPlayer from "react-player";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

export const VideoElement = (props: any) => {
    const { attributes, children, element, nodeProps, editor } = props;

    const rootProps = getRootProps(props);

    const { video, config } = element;

    const focused = useFocused();
    const selected = useSelected();

    const onChangeVideo = (val: string) => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        // console.log(path);
        setNodes(editor, val, { at: path });
        // Transforms.insertText(editor, "some words", {
        //   at: { path: path, offset: 3 },
        // });
    };

    const onDeleteVideo = () => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        // console.log(path);
        Transforms.delete(editor, { at: path });
    };

    return (
        <div {...attributes} {...rootProps} {...nodeProps}>
            <div
                contentEditable={false}
                // style={{
                //   userSelect: "none",
                //   border: selected ? "1px solid #ACCEF7" : "none",
                // }}
            >
                {selected || !video ? (
                    <VideoInsertComponent
                        onChange={onChangeVideo}
                        onDelete={onDeleteVideo}
                        value={video}
                        config={config}
                    />
                ) : (
                    <div
                        style={{
                            margin: "10px auto",
                            width: "100%",
                        }}
                    >
                        <ReactPlayer
                            url={convertS3UrlToGcp(video)}
                            controls
                            width={"100%"}
                            height={"100%"}
                        />
                    </div>
                )}
            </div>
            {children}
        </div>
    );
};
