import React, { useEffect, useState } from "react";
import Cell from "./Cell";
import { shapeGenerator } from "./utils/drawing";
import {
    REMOVE_ALL_HIGHLIGHTS,
    TOGGLE_HIGHLIGHT_PATH,
} from "src/helpers/events/table/constants";
import emitter from "src/helpers/emitter";

const DrawingComponent = ({
    tableChildProps,
    puzzleEnabled,
    isPuzzlePiece,
    tagActive,
    mainTableSelectionActive,
}: any) => {
    const [activePathArr, setActivePathArr] = useState([]);
    const { tableData } = tableChildProps;
    const drawing = tableData.drawing;
    const cells = tableData?.cells && tableData.cells[0];

    useEffect(() => {
        const handleToggleHighlightPath = (data: any) => {
            if (data.isHighlighted) {
                setActivePathArr(data.cells);
            } else {
                setActivePathArr([]);
            }
        };

        !isPuzzlePiece &&
            emitter.on(TOGGLE_HIGHLIGHT_PATH, handleToggleHighlightPath);

        return () => {
            !isPuzzlePiece &&
                emitter.off(TOGGLE_HIGHLIGHT_PATH, handleToggleHighlightPath);
        };
    }, [isPuzzlePiece]);

    useEffect(() => {
        const handleRemoveAllHighlights = ({ resolve }: any) => {
            setActivePathArr([]);
            if (resolve) resolve();
        };

        !isPuzzlePiece &&
            emitter.on(REMOVE_ALL_HIGHLIGHTS, handleRemoveAllHighlights);

        return () => {
            !isPuzzlePiece &&
                emitter.off(REMOVE_ALL_HIGHLIGHTS, handleRemoveAllHighlights);
        };
    }, [isPuzzlePiece]);
    if (!cells) {
        return null;
    }
    return (
        <div
            style={{
                width: drawing?.drawingAreaDimensions?.width || "100%",
                height: drawing?.drawingAreaDimensions?.height || "500px",
                position: "relative",
                pointerEvents: isPuzzlePiece ? "none" : "auto",
            }}
        >
            {activePathArr?.length > 0 && (
                <svg
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        zIndex: 1000,
                    }}
                >
                    {activePathArr.map((coord, index) => {
                        if (index === activePathArr.length - 1) return null;

                        const startCell = cells[coord[1]];
                        const endCell = cells[activePathArr[index + 1][1]];
                        if (!startCell || !endCell) return null;

                        const startX =
                            startCell.drawing.position.x +
                            startCell.drawing.dimension.width / 2;
                        const startY =
                            startCell.drawing.position.y +
                            startCell.drawing.dimension.height / 2;
                        const endX =
                            endCell?.drawing?.position?.x +
                            endCell?.drawing?.dimension?.width / 2;
                        const endY =
                            endCell?.drawing?.position?.y +
                            endCell?.drawing?.dimension?.height / 2;

                        // Calculate the direction vector
                        const dx = endX - startX;
                        const dy = endY - startY;
                        const length = Math.sqrt(dx * dx + dy * dy);
                        const unitDx = dx / length;
                        const unitDy = dy / length;

                        // Adjust start and end points
                        const adjustedStartX = startX + unitDx * 10;
                        const adjustedStartY = startY + unitDy * 10;
                        const adjustedEndX = endX - unitDx * 10;
                        const adjustedEndY = endY - unitDy * 10;

                        // Calculate arrow points
                        const arrowSize = 10;
                        const arrowX = adjustedEndX - unitDx * arrowSize;
                        const arrowY = adjustedEndY - unitDy * arrowSize;
                        const arrowLeftX = arrowX + (unitDy * arrowSize) / 2;
                        const arrowLeftY = arrowY - (unitDx * arrowSize) / 2;
                        const arrowRightX = arrowX - (unitDy * arrowSize) / 2;
                        const arrowRightY = arrowY + (unitDx * arrowSize) / 2;

                        return (
                            <g key={index}>
                                <line
                                    x1={adjustedStartX}
                                    y1={adjustedStartY}
                                    x2={adjustedEndX}
                                    y2={adjustedEndY}
                                    stroke="blue"
                                    strokeWidth="2"
                                />
                                <polygon
                                    points={`${adjustedEndX},${adjustedEndY} ${arrowLeftX},${arrowLeftY} ${arrowRightX},${arrowRightY}`}
                                    fill="blue"
                                />
                            </g>
                        );
                    })}
                </svg>
            )}
            {cells.map((cellItem, index) => {
                const cell = isPuzzlePiece ? cellItem.content : cellItem;

                const drawingPosition = cell?.drawing?.position;
                const positionStyles = {
                    position: "absolute",
                    top: drawingPosition?.y || 0,
                    left: drawingPosition?.x || 0,
                };
                const dimension = cell?.drawing?.dimension;
                const customStyles = {
                    shapeStyles: shapeGenerator(
                        cell?.drawing?.shape?.type,
                        cell?.drawing?.shape?.path,
                    ),
                    displayStyles: {
                        display: "grid",
                        placeItems: "center",
                    },
                    positionStyles,
                    sizeStyles: {
                        width: dimension?.width,
                        height: dimension?.height,
                        minWidth: dimension?.width,
                        minHeight: dimension?.height,
                    },
                    rotationStyles: cell?.drawing?.rotation
                        ? {
                              transform: `rotate(${cell?.drawing?.rotation}deg)`,
                          }
                        : {},
                };
                return (
                    <Cell
                        cell={cell}
                        rowIndex={0}
                        cellIndex={index}
                        {...tableChildProps}
                        key={index}
                        reorderedCellIndex={index}
                        reorderedRowIndex={0}
                        isDrawing={true}
                        customStyles={customStyles}
                        puzzleEnabled={puzzleEnabled}
                        isPuzzlePiece={isPuzzlePiece}
                        isPuzzleEnabled={
                            isPuzzlePiece ?? tableData?.puzzle?.enabled
                        }
                        tagActive={tagActive}
                        mainTableSelectionActive={mainTableSelectionActive}
                    />
                );
            })}
        </div>
    );
};

export default DrawingComponent;
