import RenderPart from "src/components/RenderPart";
import TextHtml from "src/components/TextHtml";
import capitalize from "lodash/capitalize";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const parseCMSJson = (obj: any) => {
    if (typeof obj === "string") {
        return obj;
    }

    const returnObj = obj
        ?.map(function (o: any) {
            if (o.type === "p") {
                const elem = o.children
                    .map(function (o: any) {
                        let styleArr = "";
                        if (o.fontSize) {
                            styleArr += `fontSize: ${o.fontSize}`;
                        }
                        if (o.fontWeight) {
                            styleArr += `; fontWeight: ${o.fontWeight}`;
                        }
                        if (o.color) {
                            styleArr += `; color: ${o.color}`;
                        }
                        if (o.filter) {
                            if (styleArr.length) {
                                styleArr += "; ";
                            }
                            styleArr += `filter: ${o.filter}`;
                        }
                        const style = styleArr ? ` style="${styleArr}"` : "";

                        if (o.bold) return `<b${style}>${o.text}</b>`;
                        else if (o.italic) return `<i${style}>${o.text}</i>`;
                        else if (o.underline) return `<u${style}>${o.text}</u>`;
                        else if (o.type === "mention")
                            return o.value && o.value.length > 0
                                ? `@@@@{{${o.value}}}@@@@`
                                : "";

                        return style
                            ? `<span${style}>${o.text}</span>`
                            : o.text;
                    })
                    .join(" ");

                const lineHeight = o.lineHeight;
                const indent = o.indent;
                let styleArr = "";
                if (indent) {
                    styleArr += `paddingLeft: ${indent * 25}px`;
                }
                if (lineHeight) {
                    if (indent) {
                        styleArr += `; `;
                    }
                    styleArr += `lineHeight: ${lineHeight}`;
                }
                const style = styleArr ? ` style="${styleArr}"` : "";
                return style ? `<div${style}>${elem}</div>` : elem;
            } else if (o.type === "img") {
                return `<image=\"${convertS3UrlToGcp(o.url)}\">`;
            } else if (o.type === "latex") {
                return o.latex;
            } else if (o.type === "video") {
                return `<video=\"${convertS3UrlToGcp(o.video)}\">`;
            }
        })
        .join("\n");

    return returnObj;
};

const replaceAll = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, "g"), replace);
};

const cleanLatex = (text: string, delimiter = "$") => {
    const split = text.split(delimiter);
    let returnString = "";
    split.map((item, index) => {
        if (index % 2 === 0) {
            returnString += item;
        } else {
            returnString += replaceAll(
                `${delimiter}${item}${delimiter}`,
                "\n",
                " ",
            );
        }
    });
    return returnString;
};

const getParts = (text: any, displayType: string) => {
    const parts = [];
    if (!text) {
        return parts;
    }
    let txt = text.trim();
    txt = cleanLatex(txt);
    txt = cleanLatex(txt, "$$");
    txt = txt.replace("< image", "<image");
    txt = txt.replace("<image =", "<image=");
    txt = txt.replace("<image = ", "<image=");
    txt = txt.replace("<image= ", "<image=");
    txt = txt.replace('" >', '">');
    const splitted = txt.split("\n");

    for (let splt of splitted) {
        splt = splt.trim();
        if (splt.includes("<image")) {
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<image=");

                if (imgIndex === -1) {
                    prts.push({
                        type: "latex_span",
                        val: currentPart,
                    });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                displayType === "card" &&
                    prts.push({
                        type: "img",
                        val: currentPart.substr(
                            imgIndex + 8,
                            endIndex - imgIndex - 8,
                        ),
                    });

                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        } else if (splt.includes("<video")) {
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<video=");

                if (imgIndex === -1) {
                    prts.push({
                        type: "latex_span",
                        val: currentPart,
                    });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                prts.push({
                    type: "video",
                    val: currentPart.substr(
                        imgIndex + 8,
                        endIndex - imgIndex - 8,
                    ),
                });

                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        } else {
            const splitArr = splt.split("");
            const isLatex =
                splitArr[0] === "$" && splitArr[splitArr.length - 1] === "$";

            if (isLatex) {
                parts.push({
                    type: "latex_div",
                    val: splt,
                });
            } else {
                if (!splt)
                    parts.push({
                        type: "break",
                        val: splt,
                    });
                else if (!splt.includes("@@@@{{")) {
                    parts.push({
                        type: "latex_div",
                        val: splt,
                    });
                } else {
                    const nextSplitArr = splt.split("@@@@");
                    if (nextSplitArr.length > 1) {
                        for (const mentionSplit of nextSplitArr) {
                            if (/^\{\{.*\}\}$/.test(mentionSplit)) {
                                parts.push({
                                    type: "mention_span",
                                    val: mentionSplit.replace(
                                        /^\{\{|\}\}$/g,
                                        "",
                                    ),
                                });
                            } else {
                                parts.push({
                                    type: "latex_span",
                                    val: mentionSplit,
                                });
                            }
                        }
                    } else {
                        parts.push({
                            type: "latex_div",
                            val: splt,
                        });
                    }
                }
            }
        }
    }
    return parts;
};

// HANDLE variable here
export const renderer = (json: any, mentionStyle = {}) => {
    const text = parseCMSJson(json);

    return (
        text &&
        // (isHTML(text) ? (
        // TODO: handle this
        (false ? (
            <TextHtml html={text} />
        ) : (
            getParts(text, "card").map((part, pIndex) => (
                <RenderPart
                    part={part}
                    key={"_" + pIndex}
                    mentionStyle={mentionStyle}
                />
            ))
        ))
    );
};

export const miniarseCMSJson = (obj: any) => {
    if (typeof obj === "string") {
        return obj;
    }

    let returnObj = "";

    for (let i = 0; i < obj.length; i++) {
        const o = obj[i];
        if (o.type === "p") {
            let textLen = 100;
            const arrMap = o.children.map(function (o: any) {
                const newText =
                    o.text?.substring(0, textLen) ||
                    o.value?.substring(0, textLen) ||
                    "";
                if (newText.length <= 0) return "";

                if (o.bold) return `<b>${newText}</b>`;
                else if (o.italic) return `<i>${newText}</i>`;
                else if (o.underline) return `<u>${newText}</u>`;
                else if (o.type === "mention") return `@@@@{{${newText}}}@@@@`;
                textLen -= newText.length;

                return newText;
            });

            returnObj = arrMap.join(" ").trim();
        } else if (o.type === "img") {
            returnObj = `<image=\"${convertS3UrlToGcp(o.url)}\">`;
        } else if (o.type === "latex") {
            returnObj = o.latex;
        }

        if (returnObj.length > 0) break;
    }

    return returnObj;
};

export const miniRenderer = (json: any, latexStyle = "") => {
    const text = miniarseCMSJson(json);

    return (
        text &&
        // (isHTML(text) ? (
        // TODO: handle this
        (false ? (
            <TextHtml html={text} />
        ) : (
            getParts(text, "card").map((part, pIndex) => (
                <RenderPart
                    part={part}
                    key={"_" + pIndex}
                    imgStyle={{
                        height: "50px",
                        width: "50px",
                    }}
                    latexStyle={latexStyle}
                />
            ))
        ))
    );
};

export const NO_CHECK_LATEX = ["image", "text"];

export const miniRenderTxtImg = ({ value, type }) => {
    switch (type) {
        case "image":
            return (
                <div style={{ maxWidth: "55vw" }}>
                    <img
                        style={{
                            height: "50px",
                            width: "50px",
                        }}
                        alt="Question"
                        src={convertS3UrlToGcp(value)}
                    />
                </div>
            );
        case "text":
            return (
                <div className={"overflow-x-hidden"}>
                    {value?.substring(0, 100)}
                </div>
            );
        default:
            return <></>;
    }
};

export const renderBlockAsOptions = (blocks: any = []): any =>
    blocks.map((v, i) => ({
        value: i,
        label: (
            <div
                style={{
                    display: "flex",
                    gap: "20px",
                }}
            >
                <strong>{`Block ${i + 1} - ${capitalize(v.type)}`}</strong>
                <div>
                    {NO_CHECK_LATEX.includes(v.type)
                        ? miniRenderTxtImg({
                              type: v.type,
                              value: v?.data?.text || v?.data?.image,
                          })
                        : miniRenderer(
                              v?.data[v.type]?.title ||
                                  v?.data[v.type]?.text ||
                                  v?.data.text ||
                                  [],
                          )}
                </div>
            </div>
        ),
        type: v.type,
    }));

export const replaceCDNUrl = (url: string, bucket: string) => {
    if (!bucket) return url;
    const provider = process.env.REACT_APP_BUCKET_PROVIDER || "gcp";
    let CDN_URL, BUCKET_URL;
    if (provider === "aws") {
        CDN_URL = `https://cdn.homeworkapp.ai/${bucket}`;
        BUCKET_URL = `https://${bucket}.s3.ap-south-1.amazonaws.com`;
    } else {
        CDN_URL = `https://cdn.mathai.ai/${bucket}`;
        BUCKET_URL = `https://storage.googleapis.com/${bucket}`;
    }

    return url?.replace(CDN_URL, BUCKET_URL);
};

export const getAudioDuration = async (url: string) => {
    return new Promise((resolve) => {
        const video = document.createElement("audio");

        video.addEventListener("loadedmetadata", () => {
            const { duration } = video;
            resolve(duration);
        });

        video.addEventListener("error", () => {
            resolve(-1);
        });

        video.src = convertS3UrlToGcp(url);
    });
};
