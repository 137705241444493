import { RenderUpload } from "src/components/form";
import { Input, Row } from "antd";
import { useEffect, useState } from "react";
import { DurationPicker } from "../../common/index";
import { BLOCK_TYPES } from "../../common/index";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";
const { V2_AVATAR_BLOCK } = BLOCK_TYPES;

const renderEditorImage = ({ block, setBlock, worksheet, type }: any) => {
    const imageUrl = block?.data?.image;
    const [isEditState, setEditState] = useState(!imageUrl);
    const onChange = (e: any) => {
        if (type === V2_AVATAR_BLOCK) {
            setBlock(e.target.value);
        } else {
            setBlock({
                ...block,
                data: { ...block.data, image: e.target.value },
            });
        }
        setEditState(false);
    };

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    useEffect(() => {
        setEditState(!imageUrl);
    }, [imageUrl]);

    const isValid = Boolean(imageUrl?.trim());
    return (
        <div>
            {isEditState ? (
                <div>
                    <RenderUpload
                        path="home-explore/document/"
                        onChangeCustom={onChange}
                        label="upload an image"
                        value={imageUrl}
                        required={true}
                        meta={{
                            touched: true,
                            error: !isValid ? "Field cannot be Empty" : "",
                        }}
                    />
                </div>
            ) : (
                <Row>
                    <img
                        src={convertS3UrlToGcp(imageUrl)}
                        alt=""
                        style={{ width: "-webkit-fill-available" }}
                    />
                    <div style={{ paddingTop: "10px" }}>
                        {imageUrl && (
                            <RenderUpload
                                path="home-explore/document/"
                                onChangeCustom={onChange}
                                label="upload an image"
                                value={imageUrl}
                                editButton={true}
                            />
                        )}
                    </div>
                </Row>
            )}
            {["personalized_learning_v3", "personalized_learning_v4"].includes(
                worksheet?.type,
            ) && (
                <Input
                    placeholder="Add value here"
                    value={imageUrl}
                    onChange={onChange}
                />
            )}
            {worksheet?.type === "timed" && (
                <div style={{ marginTop: "10px" }}>
                    <DurationPicker
                        onChange={onDurationChange}
                        initialValue={block?.data?.other?.duration || 0}
                    />
                </div>
            )}
        </div>
    );
};

export default renderEditorImage;
