import { COMPONENT_TYPES } from "../../defaultValue";
import BalanceGameEditor from "./BalanceGame";
import ButtonEditor from "./Button";
import ConditionComponentEditor from "./Condition";
import DragNDropEditor from "./DragNDrop";
import GenericRendererEditor from "./GenericRenderer";
import GenericTable from "./GenericTable";
import GeogebraEditor from "./Geogebra";
import MultiInputEditor from "./MultiInput";
import MultiplayerButtonEditor from "./MultiplayerButton";
import MultiSelectInputEditor from "./MultiSelectInput";
import CustomRichTextEditor from "./RichText";
import SurferGameEditor from "./SurferGame";
import TapSelectEditor from "./TapSelect";
import TimerEditor from "./Timer";
import VoiceInputEditor from "./VoiceInput";

const ComponentsEditor = (props: any) => {
    const { block } = props;
    const { type } = block;

    switch (type) {
        case COMPONENT_TYPES.MULTI_INPUT:
            return <MultiInputEditor {...props} />;
        case COMPONENT_TYPES.GENERIC_TABLE:
            return <GenericTable {...props} />;
        case COMPONENT_TYPES.DRAG_DROP:
            return <DragNDropEditor {...props} />;
        case COMPONENT_TYPES.TIMER:
            return <TimerEditor {...props} />;
        case COMPONENT_TYPES.RICH_TEXT:
            return <CustomRichTextEditor {...props} />;
        case COMPONENT_TYPES.TAP_AND_SELECT:
            return <TapSelectEditor {...props} />;
        case COMPONENT_TYPES.MULTI_SELECT_INPUT:
            return <MultiSelectInputEditor {...props} />;
        case COMPONENT_TYPES.GEOGEBRA:
            return <GeogebraEditor {...props} />;
        case COMPONENT_TYPES.CONDITION:
            return <ConditionComponentEditor {...props} />;
        case COMPONENT_TYPES.VOICE_INPUT:
            return <VoiceInputEditor {...props} />;
        case COMPONENT_TYPES.GENERIC_RENDERER:
            return <GenericRendererEditor {...props} />;
        case COMPONENT_TYPES.SURFER_GAME:
            return <SurferGameEditor {...props} />;
        case COMPONENT_TYPES.BALANCE_GAME:
            return <BalanceGameEditor {...props} />;
        case COMPONENT_TYPES.BUTTON:
            return <ButtonEditor {...props} />;
        case COMPONENT_TYPES.MULTIPLAYER_BUTTON:
            return <MultiplayerButtonEditor {...props} />;
        default:
            break;
    }
    return <></>;
};

export default ComponentsEditor;
