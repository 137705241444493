import {
    Button,
    Card,
    Collapse,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    message,
    Select,
    Switch,
} from "antd";
import React, { useState } from "react";
import CollapseBtn from "src/modules/worksheet/components/WorksheetEditor/helpers/CollapseBtn";
import { Can } from "src/services/casl";
import { SortableContainerCustom, SortableItemCustom } from "./BlockColumn";
import { COMPONENT_SCHEMA, COMPONENT_TYPES, COMPONENTS } from "../defaultValue";
import _, { cloneDeep, set } from "lodash";
import { arrayMoveImmutable } from "array-move";
import { PlusOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { v4 as uuid } from "uuid";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import { getFigmaData } from "./helpers";
import { Panel } from "rc-time-picker";
import { LogicModal } from "./ComponentsEditor/common/LogicModal";

type Props = {};

function RenderArea(props: any) {
    const {
        block,
        setBlock,
        setCurrentRoundIndex,
        currentRoundIndex,
        label,
        disableWrite,
        setCurrentComponentIndex,
        setCurrentArea,
        area,
    } = props;

    // console.log("area", label, block);

    const { components = [] } = block;

    const [tempChildren, setTempChildren] = useState("");

    const onSortEndComponent = ({ oldIndex, newIndex, idx }: any) => {
        let tmpBlock = cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            components,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = set(tmpBlock, ["components"], tmpChildren);
        setBlock(tmpBlock);
        setCurrentComponentIndex(newIndex);
        setCurrentArea(area);
    };

    const addComponent = async (
        component: {
            figma_url: string;
            value: any;
            label: any;
            component_type: any;
            data: any;
        },
        index: number,
    ) => {
        let retVal;
        let newComp: any = {
            ...COMPONENT_SCHEMA,
            tmpId: uuid(),
            id: uuid(),
            type: component.value,
            label: component.label,
            component_type: component.component_type,
            show_in_preview: false,
        };
        if (component?.figma_url) {
            retVal = await getFigmaData(component.figma_url, {});
            if (!retVal) return;
            const { name, compute_functions, variables, figma_data } = retVal;
            newComp.figma = {
                ...component.data,
                name,
                variables,
                compute_functions,
                figma_data,
            };
        } else {
            newComp.data = component.data;
        }
        switch (component.value) {
            case COMPONENT_TYPES.RICH_TEXT:
            case COMPONENT_TYPES.TIMER:
                newComp.skip_evaluation = true;
                newComp.disable_interaction = true;
                break;
            case COMPONENT_TYPES.CONDITION:
                newComp.disable_interaction = true;
                newComp.show_in_preview = true;
                break;
            default:
                break;
        }
        let tmpBlock = cloneDeep(block);
        tmpBlock = set(tmpBlock, ["components", index], newComp);
        setBlock(tmpBlock);
        setCurrentComponentIndex(index);
        setCurrentArea(area);
    };

    const componentsMenu = (
        <Menu
            items={COMPONENTS.map((c: any, idx: number) => ({
                key: idx,
                label: <span>{c.label}</span>,
                onClick: () => addComponent(c, components.length),
            }))}
        />
    );

    // console.log("area", area, block.same_player_1, block.hidden);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <h2
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        {label}
                    </h2>

                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                JSON.stringify({
                                    components,
                                    type: "multiplayer_round",
                                }),
                            );
                        }}
                    >
                        Copy
                    </Button>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            navigator.clipboard
                                .readText()
                                .then((text) => {
                                    const parsedData = JSON.parse(text);
                                    if (
                                        parsedData.type === "multiplayer_round"
                                    ) {
                                        const tmpBlock = _.cloneDeep(block);
                                        _.set(
                                            tmpBlock,
                                            ["components"],
                                            parsedData.components,
                                        );
                                        setBlock(tmpBlock);
                                    } else if (
                                        parsedData.type == "all_in_one" &&
                                        parsedData?.data?.["all_in_one"]
                                            ?.components
                                    ) {
                                        const tmpBlock = _.cloneDeep(block);
                                        _.set(
                                            tmpBlock,
                                            ["components"],
                                            parsedData?.data?.["all_in_one"]
                                                ?.components,
                                        );
                                        setBlock(tmpBlock);
                                    }
                                })
                                .catch((err) => {
                                    console.error(
                                        "Failed to read clipboard contents: ",
                                        err,
                                    );
                                });
                        }}
                    >
                        Paste
                    </Button>
                    {area === "common_arena" && (
                        <Form.Item
                            label={"Hidden"}
                            style={{
                                marginTop: "4px",
                            }}
                        >
                            <Switch
                                disabled={disableWrite}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={block.hidden}
                                onChange={(val: any) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(tmpBlock, ["hidden"], val);
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    )}
                    {area === "player_1" && (
                        <Form.Item
                            label={"Same as player 2"}
                            style={{
                                marginTop: "4px",
                            }}
                        >
                            <Switch
                                disabled={disableWrite}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={block.same_player_1}
                                onChange={(val: any) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["same_player_1"],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    )}
                </div>
            </div>
            {(area === "player_2" ||
                (area === "common_arena" && !block.hidden) ||
                (area === "player_1" && !block.same_player_1)) && (
                <Can I="edit" a={"worksheet_block"} passThrough>
                    {(allowed: boolean) => (
                        <>
                            <h4
                                style={{
                                    fontWeight: "bold",
                                    margin: "10px 0",
                                }}
                            >
                                Components
                            </h4>
                            <SortableContainerCustom
                                onSortEnd={onSortEndComponent}
                                useDragHandle
                            >
                                {components.map(
                                    (component: any, idx: number) => {
                                        return (
                                            <SortableItemCustom
                                                {...props}
                                                idx={idx}
                                                componentOwners={
                                                    COMPONENTS.find(
                                                        (c) =>
                                                            c.value ===
                                                            component.type,
                                                    )?.owners
                                                }
                                                disabled={disableWrite}
                                                type={component.type}
                                                key={`item-${
                                                    component.id ||
                                                    component.tmpId
                                                }`}
                                                index={idx}
                                                block={component}
                                                onClick={() => {
                                                    setCurrentComponentIndex(
                                                        idx,
                                                    );
                                                    setCurrentRoundIndex(
                                                        currentRoundIndex,
                                                    );
                                                    setCurrentArea(area);
                                                }}
                                                setBlock={(v: any) => {
                                                    let tmpBlock =
                                                        cloneDeep(block);
                                                    tmpBlock = set(
                                                        tmpBlock,
                                                        ["components", idx],
                                                        v,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                                addBlock={(
                                                    type: string,
                                                    index: number,
                                                    newBlock: any,
                                                ) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    const tmpChildren = [
                                                        ...components,
                                                    ];
                                                    tmpChildren.splice(
                                                        index,
                                                        0,
                                                        newBlock,
                                                    );
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        ["components"],
                                                        tmpChildren,
                                                    );
                                                    setBlock(tmpBlock);
                                                    setCurrentComponentIndex(
                                                        index,
                                                    );
                                                }}
                                                deleteBlock={(
                                                    index: number,
                                                ) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    const tmpChildren = [
                                                        ...components,
                                                    ];
                                                    _.pullAt(tmpChildren, idx);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        ["components"],
                                                        tmpChildren,
                                                    );
                                                    setBlock(tmpBlock);
                                                    setCurrentComponentIndex(
                                                        idx - 1,
                                                    );
                                                }}
                                                area={area}
                                            />
                                        );
                                    },
                                )}
                            </SortableContainerCustom>
                            {!components?.length && (
                                <div
                                    style={{
                                        fontSize: "16px",
                                        color: "red",
                                    }}
                                >
                                    No components added
                                </div>
                            )}
                            {!disableWrite && (
                                <div
                                    style={{
                                        minWidth: "208px",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "10px",
                                    }}
                                >
                                    <Dropdown
                                        overlay={componentsMenu}
                                        trigger={["click"]}
                                        // overlayStyle={{ width: "100px" }}
                                        disabled={!allowed}
                                    >
                                        <Button type="link" disabled={!allowed}>
                                            Add new component
                                            <PlusOutlined
                                                style={{
                                                    fontSize: "15px",
                                                }}
                                            />
                                        </Button>
                                    </Dropdown>
                                    <Form.Item>
                                        <Input.TextArea
                                            disabled={disableWrite}
                                            value={tempChildren}
                                            placeholder="Paste Master Component here"
                                            onChange={(e) =>
                                                setTempChildren(e.target.value)
                                            }
                                            rows={2}
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                        <Button
                                            disabled={
                                                disableWrite || !tempChildren
                                            }
                                            style={{ marginTop: "10px" }}
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                try {
                                                    const parsedJson =
                                                        JSON.parse(
                                                            tempChildren,
                                                        );
                                                    if (
                                                        !Object.values(
                                                            COMPONENT_TYPES,
                                                        ).includes(
                                                            parsedJson?.type,
                                                        )
                                                    ) {
                                                        message.warn(
                                                            "Invalid JSON",
                                                        );
                                                        return;
                                                    }
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "components",
                                                            components?.length,
                                                        ],
                                                        updateKeysAndCopy(
                                                            parsedJson,
                                                            false,
                                                        ),
                                                    );
                                                    setBlock(tmpBlock);
                                                    setCurrentComponentIndex(
                                                        components?.length,
                                                    );
                                                    setTempChildren("");
                                                    message.info("Updated!");
                                                } catch (e) {
                                                    // captureException(e)
                                                    message.error(
                                                        "Error in JSON!",
                                                    );
                                                }
                                            }}
                                        >
                                            Add New Component
                                        </Button>
                                    </Form.Item>
                                </div>
                            )}
                        </>
                    )}
                </Can>
            )}
        </div>
    );
}

function RoundColumn(props: any) {
    const { block, setBlock } = props;

    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    function setBlockOfArea(updatedBlock, area) {
        let tmpBlock = _.cloneDeep(block);
        console.log("updatedBlock", updatedBlock);
        tmpBlock = _.set(tmpBlock, [area], updatedBlock);
        setBlock(tmpBlock);
    }

    const functions = [block?.logic?.compute_functions?.["on_change"]];

    if (!block) return <></>;

    return (
        <Card
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "86vh",
                overflowY: "auto",
            }}
            bodyStyle={{
                padding: "0px",
                paddingTop: collapsed ? "10px" : "15px",
            }}
        >
            <CollapseBtn
                collapsed={collapsed}
                toggleCollapsed={toggleCollapsed}
                style={{
                    marginBottom: 10,
                    marginLeft: collapsed ? 10 : 20,
                    marginRight: collapsed ? 10 : 0,
                }}
            />
            {!collapsed && (
                <>
                    <Collapse>
                        <Collapse.Panel header="Settings" key="1">
                            <div style={{ marginLeft: "-4px" }}>
                                <LogicModal
                                    isAllGlobal={true}
                                    disableWrite={false}
                                    computeFUnctions={functions}
                                    onSave={(val: any) => {
                                        let tmpBlock = cloneDeep(block);
                                        const on_change = val?.find(
                                            (v) => v.name === "on_change",
                                        );
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "logic",
                                                "compute_functions",
                                                "on_change",
                                            ],
                                            on_change,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </div>

                            <Form.Item
                                label={"Round start audio"}
                                style={{
                                    marginTop: "4px",
                                }}
                            >
                                <Input
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    value={block?.logic?.start_state?.audio}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["logic", "start_state", "audio"],
                                            e.target.value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label={"Round start feedback"}>
                                <Input
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    value={block?.logic?.start_state?.feedback}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "logic",
                                                "start_state",
                                                "feedback",
                                            ],
                                            e.target.value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label={"Round end audio"}>
                                <Input
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    value={block?.logic?.end_state?.audio}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["logic", "end_state", "audio"],
                                            e.target.value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label={"Round end feedback"}>
                                <Input
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    value={block?.logic?.end_state?.feedback}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["logic", "end_state", "feedback"],
                                            e.target.value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label={"Round end story"}>
                                <Input
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    value={block?.logic?.end_state?.story}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["logic", "end_state", "feedback"],
                                            e.target.value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Collapse.Panel>
                    </Collapse>
                    <Divider />
                    <RenderArea
                        key="player_2"
                        {...props}
                        label="Player 2"
                        block={block?.player_2}
                        setBlock={(v: any) => {
                            setBlockOfArea(v, "player_2");
                        }}
                        area="player_2"
                    />
                    <Divider />
                    <RenderArea
                        key="common_arena"
                        {...props}
                        label="Common arena"
                        block={block?.common_arena}
                        setBlock={(v: any) => {
                            setBlockOfArea(v, "common_arena");
                        }}
                        area="common_arena"
                    />
                    <Divider />
                    <RenderArea
                        key="player_1"
                        {...props}
                        label="Player 1"
                        block={block?.player_1}
                        setBlock={(v: any) => {
                            setBlockOfArea(v, "player_1");
                        }}
                        area="player_1"
                    />
                </>
            )}
        </Card>
    );
}

export default RoundColumn;
