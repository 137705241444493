import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Row, Switch } from "antd";
import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

export default function Microphone(props) {
    const {
        onChangeAudio,
        index,
        pushFile,
        isSelected,
        audiofile,
        handleDelete,
    } = props;
    const [tempFile, setTempFile] = React.useState(null);
    const [isSave, setIsSave] = useState(false);
    useEffect(() => {
        if (audiofile && audiofile.url && !isSave) {
            setTempFile(null);
            setIsSave(true);
        }
    }, [audiofile]);

    const recorderControls = useAudioRecorder();
    const addAudioElement = (blob) => {
        pushFile(blob);
    };
    return (
        <>
            <Row>
                <Col span={1}>{index}</Col>
                <Col
                    style={{
                        backgroundColor: "#e6e6e6",
                        padding: "12px",
                    }}
                    span={21}
                >
                    {!isSave ? (
                        <AudioRecorder
                            onRecordingComplete={(blob) =>
                                addAudioElement(blob)
                            }
                            recorderControls={recorderControls}
                            classes={{
                                AudioRecorderPauseResumeClass:
                                    "pause-resume-class",
                                AudioRecorderDiscardClass: "discard-class",
                            }}
                        />
                    ) : (
                        <ReactPlayer
                            url={convertS3UrlToGcp(audiofile.url)}
                            controls
                            file={{ forceAudio: true }}
                            height={"60px"}
                            width="100%"
                        />
                    )}
                </Col>
                <Col
                    span={2}
                    style={{
                        paddingLeft: "12px",
                    }}
                >
                    <Switch
                        onChange={onChangeAudio}
                        checked={isSelected}
                        size="small"
                        disabled={!tempFile && !audiofile.url}
                    />
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        shape="circle"
                        size="small"
                        style={{ marginTop: "5px" }}
                        onClick={handleDelete}
                    ></Button>
                </Col>
            </Row>
        </>
    );
}
