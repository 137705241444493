import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";
import { convertS3UrlToGcp } from "src/modules/worksheet/views/edit_enhanced/helpers";

const renderCardImage = (props: any) => {
    const {
        block: { data },
    } = props;
    return (
        <BlockCard {...props}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                    src={convertS3UrlToGcp(data.image)}
                    style={{ height: "30px" }}
                />
            </div>
        </BlockCard>
    );
};
export default renderCardImage;
